import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import AccountIcon from 'img/account-icon.svg';
import EnvIcon from 'img/env-icon.svg';
import SatIcon from 'img/satellite.svg';
import AdminDbIcon from 'img/admin_db.png';
import Select from 'react-select';


import RightArrowLogout from 'img/right-arrow-logout.svg';
import style from './Header.module.css';

import { useAuthContext } from 'components/ui-core/auth/AuthContext';
import { DataBaseContext, SpaceCraftContext } from 'components/ui-core/metadata/SpaceCraftContext';
import Modal from 'components/ui-core/modal/Modal/Modal';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';
import { useASTData } from 'context/ASTContext';
import { useMetadata, useSCVersioning } from 'data/queryHooks';
import SnapshotTable from './SnapShotForm';
import { useBranchData } from './BranchContext';




export const Header = ({ title, ...props }) => {
	
	const {user, logoutUser} = useAuthContext();


	
	const {headerTitle} = useASTData();
	const {sc_options, db_options, setSpacecraftOptions, setDbOptions,  setCurrentSpaceCraft, setCurrentDatabase, totalDbList, setTotalDbList, metadataDb, setMetadataDb} = useBranchData();
	//const envName = process.env.REACT_APP_ENV_NAME ? process.env.REACT_APP_ENV_NAME : "Unknown";
	const envType = process.env.REACT_APP_ENV_TYPE;

	const envTextStyleObj = {};
	if (envType === "FSW_PROD") {
		envTextStyleObj["background"] =  "#ffc8c8";
	}



	//Sets the a key value pair cookie in the user's browser
	const setCookieFunction = (key, value) => {
    
		localStorage.setItem(key,value)
	  
		
	}

	const handleInitSC =() =>{
		localStorage.setItem('spacecraft', 'BB1')
		window.location.reload();

	}

	const handleInitDB =() =>{
		localStorage.setItem('database', 'FLIGHT_DEV')
		window.location.reload();

	}


	//Assignes default Spacecraft and Database
	const currentSpacecraft = localStorage.getItem('spacecraft') === null ? handleInitSC : localStorage.getItem('spacecraft');
	const currentDatabase = localStorage.getItem('database') === null ? handleInitDB : localStorage.getItem('database');

	const [SpacecraftContext, setSpacecraftContext] = useState(currentSpacecraft);
	const [DatabaseContext, setDatabaseContext] = useState(currentDatabase);
	const [isLoading, setIsLoading] = useState(false);
	const [createVisible, setCreateVisible] = useState(false);
	setCurrentSpaceCraft(currentSpacecraft)
	setCurrentDatabase(currentDatabase)



	//MSD 2.0.0 logic.
	//Handles a DB change
	const handleSCChange = (e) => {
		setSpacecraftContext(e.value);
		setCookieFunction('spacecraft',e.value);

		if (currentDatabase !== "FLIGHT_DEV" && currentDatabase !== "FLIGHT_STAGING" ){
			setCookieFunction('database','FLIGHT_DEV');
		}

		setIsLoading((state) => !state)

		window.location.reload();
		
	}

	const handleDBChange = (e) => {
		console.log(e.value)
		setDatabaseContext(e.value);
		setCookieFunction('database',e.value);
		setIsLoading((state) => !state)
		window.location.reload();
		
	}

	//const [my_data, setData] = useState();
	//{metadataQuery.data[0]}

	const versioning_metadata = useSCVersioning();
	const metadataQuery = useMetadata();

	// //versioning_metadata.data

	// //versioning_metadata.data[0]?.spacecraft_name

	// //let sc_options = [{label: "BW3", value: "BW3"}, {label: "BB1", value: "BB1"}, {label: "BB2", value: "BB2"}]

	// const [sc_options, setSpacecraftOptions]=useState([{label: "BW3", value: "BW3"}])
	// const [db_options, setDbOptions]= useState([{label: "FLIGHT_DEV", value: "FLIGHT_DEV"}])
	// const [db_list,setDbList] = useState([]);
	// //let sc_options = versioning_metadata.data.map((item) => ({label: item.spacecraft_name, value: item.spacecraft_name}));

	// const setDbValue = (value) => {
	// 	setDbList(value);
	// }

	React.useEffect(() => {
		if (versioning_metadata.data) {
			//console.log(currentDatabase)
			const ScArray = versioning_metadata.data.map((item) => ({label: item.spacecraft_name.toUpperCase(), value: item.spacecraft_name.toUpperCase()}));
			//{label: item.spacecraft_name, value: item.spacecraft_name}

			//let DbArray = versioning_metadata.data.map((item) => ({label: item.databases.toUpperCase(), value: item.databases.toUpperCase()}));

			setSpacecraftOptions(ScArray)
			//setDbOptions(DbArray)
			//console.log(ScArray)

			console.log(versioning_metadata.data);
			const spaceCraft_obj = versioning_metadata.data.find((item) => item.spacecraft_name === currentSpacecraft);
			console.log(spaceCraft_obj);
			
			if (spaceCraft_obj){
				console.log(spaceCraft_obj.databases)

				const DbArray = [];
				spaceCraft_obj.databases?.map((row) => {
					DbArray.push({ label: row.version, value: row.version });
                    return row
                });
                if (totalDbList.length === 0) {
                    setTotalDbList(spaceCraft_obj.databases);
                }

				setDbOptions(DbArray);
			};
			
		}
		if(metadataQuery.data){
			setMetadataDb(metadataQuery.data[0])
		}
		
	}, [currentSpacecraft, currentDatabase,  versioning_metadata.data, totalDbList, metadataQuery.data]);


	//Script for Version Data Structure Change - Useful Comments

	/*const {mutateAsync: updateVersionDb} = useUpdateVersionMetadata();
		const changeSnapShotStructure = () => {
			if(versioning_metadata.data){
				versioning_metadata.data.map((item) => {
					console.log(item.databases)
					const clonedb = [];
					item.databases.forEach(function (item) {
						if(typeof item === "object") {
							clonedb.push(item);
						}
						else if(typeof item === "string"){
							clonedb.push(
							{"version": item , 
							"test_status": "completed",
							"created_date": "",
							"created_by": "",
							"last_updated_date": "",
							"last_updated_by": ""})
						}
						
					});
					item.databases = clonedb;
					updateVersions(item);
			});
		}
	}

	const updateVersions = async (updatedVersion)  => {
		return await updateVersionDb(updatedVersion)
		.then(refreshedVersions => {
			console.log(refreshedVersions)
			toast.success(`'${refreshedVersions}' updated`);
			return refreshedVersions;
		})
		.catch(error => {
			toast.error("Versions update failed");
			return Promise.reject(error)
		});	
	} */
	//useEffect()
	// useEffect(() => {
	// 	if (versioning_metadata.data[0]?.spacecraft_name !== undefined){
	// 	setData(versioning_metadata.data[0]?.spacecraft_name)
	// 	}
	// 	else{
	// 		setData("None")
	// 	}
	// });


	

	
	
	return (
		<>
			<section className={style.headerContainer} {...props}>
				
				<h1 className={style.pageHeading}>{headerTitle}</h1>
				{createVisible && 
				<LoadingWrapper 
					queries={[versioning_metadata]}
					onLoad={() => (
						<Modal>
							<div className={style.snapShotContainer}>
							<SnapshotTable setCreateVisible={setCreateVisible} userName={user.getUsername()}/>
							</div>
						</Modal> 
					)}				
				/>
				}


				<div className={style.container}>
					<SpaceCraftContext.Provider value={[SpacecraftContext, setSpacecraftContext]}>

					<div className={style.envWrapper}>
					<img src={SatIcon} alt="SatIcon" height={25} width={25}/>

					<p style={envTextStyleObj} className={style.dropdownText} >{SpacecraftContext}</p>
					{/* <SpaceCraftData>
					</SpaceCraftData> */}
					<Select isLoading={isLoading} className={style.dropdown} defaultValue={currentSpacecraft} options={sc_options} onChange={handleSCChange}
					/>
						
					
					

					{/* <select style={envTextStyleObj} className={style.envText} onChange={(e) => {
						const newFilterValue = e.target.value || undefined
						setCookieFunction(newFilterValue);
						setContext(newFilterValue);

					}} >
						
					
					{spacecraft.map((option) => (
				
						
					
					<option style={envTextStyleObj}>
						{option}
					</option>
					))}
					</select> */}
					</div>
					</SpaceCraftContext.Provider>

					<DataBaseContext.Provider value={[DatabaseContext, setDatabaseContext]}>
					<div className={style.envWrapper}>
					{currentDatabase ==="FLIGHT_DEV" && <img src={AdminDbIcon} alt="AdminDbIcon" 
					height={30} width={30}					
					onClick={() => {
						setCreateVisible(true);
					}}/> }
					{currentDatabase !=="FLIGHT_DEV" && <img src={EnvIcon} alt="EnvIcon" height={30} width={30}/> }
					<p style={envTextStyleObj} className={style.dropdownText} >{DatabaseContext}</p>
					
					{/* <div  className={style.envWrapper}>
						<img src={EnvIcon} alt="EnvIcon" />
						<p style={envTextStyleObj} className={style.envText} >{DatabaseContext}</p>
					<select style={envTextStyleObj} className={style.envText} onChange={(e) => {
						const newFilterValue = e.target.value || undefined
						setDatabaseContext(newFilterValue);
					}}>

					{databases.map((option) => (
                    <option style={envTextStyleObj}>
                        {option}
                    </option>
                	))}

				
					</select>
					</div> */}
					<Select isLoading={isLoading} className={style.dropdown} defaultValue={currentDatabase} options={db_options} onChange={handleDBChange}/>
					</div>

					</DataBaseContext.Provider>



					<div className={style.userAccount}>
						<img src={AccountIcon} alt="AccountIcon" />
						{user && <p>{user.getUsername()}</p>}
					</div>
					<div className={style.logOut} onClick={logoutUser}>
						<Link to="/login">Logout</Link>
						<img src={RightArrowLogout} alt="Right Arrow Logout" />
					</div>
				</div>
			</section>
		</>
	);
};

Header.propTypes = {
	title: PropTypes.string.isRequired,
};

Header.defaultProps = {
	title: 'Page Title',
};

export default Header;