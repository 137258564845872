import {getBlockArrayTypeOptions} from 'components/ui-core/utils/MetadataHelper'
import {FormRow, Input, Select} from 'components/ui-core/form/FormControls';

export const getBlockArrayRowIfRequired = (row_num, controlProps, useFormObj, isWizard) => {

    const {getValues} = useFormObj;
    const isTypeBlockArray = () => getValues("type") === "block_array" || getValues("type") === "var_block_array";

    if (getValues("type") === "block_array" || getValues("type") === "var_block_array") {
        return (
            <FormRow rowNum={row_num}>
                <Select title="Block Array Element Type" attr="array_element_type" 
                    {...controlProps}  valueOptions={getBlockArrayTypeOptions()} 
                    includeBlank={isWizard} revalidateOnChange={true} isRequired={isTypeBlockArray}/>
                <Input title="Block Array Element Byte Size" attr="array_element_size" 
                    {...controlProps} isRequired={isTypeBlockArray}/>
            </FormRow>	

        )
    } 
    
    return <></>
} 