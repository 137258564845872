import React from 'react';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';

import OptionFormContent from './OptionFormContent'


export const EditOptionValueModal = ({ createEditOptionPromise, 
		editOptionValue, setEditOptionValue, schema, entityType }) => {

	const {useFormObj, controlProps} = useWizardForm(editOptionValue, schema, 'onChange');


	const closeWizard = (wizardVisible) => {
		if (!wizardVisible) {
			setEditOptionValue(null);
		}
	}

	return (
		<Wizard width='20rem'>
			<WizardForm title="Edit Enumeration" 
				setWizardVisible={closeWizard} useFormObj={useFormObj} 
				createSubmitPromise={createEditOptionPromise}
				submitButtonTitle="Edit"
			>
				<OptionFormContent controlProps={controlProps} entityType={entityType}/>
			</WizardForm>
		</Wizard>		
	);
};

export default EditOptionValueModal;
