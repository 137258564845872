import React, {useState} from 'react';

import 'animate.css';

import {getOptionsSchema} from 'model/form-schema/ObjectSchemas'
import Modal from 'components/ui-core/modal/Modal/Modal';
import CreateOptionWizard from './CreateOptionWizard'
import EditOptionValueModal from './EditOptionValueModal';

import { useASTData } from 'context/ASTContext';
import ConfirmationRequest from 'model/ConfirmationRequest';

import OptionsTable from './OptionsTable';
import { transformOption } from './OptionHelper';

export const OptionsTab = ({updateEntityPromise, underlyingEntity, entityType}) => {
	const [createVisible, setCreateVisible] = useState(false);
	const [editOptionValue, setEditOptionValue] = useState(null);	

	const {setConfirmationRequest} = useASTData();
	const optionsAttrName = entityType.optionsAttrName

	const performTabDeletionHandler = async (optionToDelete) => {
		underlyingEntity[optionsAttrName] = 
			underlyingEntity[optionsAttrName]
				.filter(option => !(option.name === optionToDelete.name && option.value === optionToDelete.value));
		await updateEntityPromise(underlyingEntity);
	};
	
	const requestDelConfirmationHandler = (optionToDelete) => {
		let deleteRequest =  new ConfirmationRequest(
			optionToDelete,
			performTabDeletionHandler,
			`Delete Enumeration '${optionToDelete.name}'`, 
			"Delete",
			"Are you sure you want to delete this enumeration?",
			null,
			`Enumeration '${optionToDelete.name}' deleted`, 
		);
	
		setConfirmationRequest(deleteRequest);
	}


	let optionsData = 
		underlyingEntity[optionsAttrName] 
		? [...underlyingEntity[optionsAttrName]]
		: [];

	const createAddOptionPromise = async (formData) => {

		if (!underlyingEntity[optionsAttrName]) {
			underlyingEntity[optionsAttrName]=[];
		}

		const adjustedFormData = transformOption(formData);
		underlyingEntity[optionsAttrName].push({...adjustedFormData});

		return updateEntityPromise(underlyingEntity);
	};
		
	const createEditOptionPromise = async (formData) => {
		const adjustedFormData = transformOption(formData);

		editOptionValue.name = adjustedFormData.name;
		editOptionValue.value = adjustedFormData.value;
		editOptionValue.severity = adjustedFormData.severity;
		
		return updateEntityPromise(underlyingEntity);
	};

	const createOptionSchema = () => getOptionsSchema(
		optionsData, createVisible ? null : editOptionValue.name);

	return (
		<>
			{createVisible && 
				<Modal>
					<CreateOptionWizard
						createAddOptionPromise={createAddOptionPromise}
						schema={createOptionSchema()}
						setCreateVisible={setCreateVisible}
						entityType={entityType}
						optionsData={optionsData}
					/>
				</Modal> 
			}

			{editOptionValue && 
				<Modal>
					<EditOptionValueModal
						createEditOptionPromise={createEditOptionPromise}
						schema={createOptionSchema()}
						editOptionValue={editOptionValue}
						setEditOptionValue={setEditOptionValue}
						entityType={entityType}
					/>
				</Modal> 
			}		

			<OptionsTable 
				optionsData={optionsData}
				setCreateVisible={setCreateVisible}
				setEditOptionValue={setEditOptionValue}
				handleDelete={requestDelConfirmationHandler}
				entityType={entityType}
			/>			
		</>

	);
};

export default OptionsTab;
