import React from 'react';
import { toast } from 'react-toastify';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import {getTagSchema} from 'model/form-schema/ObjectSchemas'
import TagFormContent from './TagFormContent'
import {useCreateTag} from 'data/queryHooks';

export const CreateTagWizard = ({ setCreateVisible,  allTags}) => {

	const schema = getTagSchema(allTags, null);
	const {useFormObj, controlProps} = useWizardForm({}, schema);
	const  {mutateAsync: addTag} = useCreateTag();

	const createSubmitPromise = async (formData) => {
		
		let newTag = {
			name: formData.name,
			description: formData.description,
			satellite_tags: ["BW3"]
		}

		return await addTag(newTag)
			.then(response => {
				toast.success(`Tag '${newTag.name}' created`);
				return response;
			})
			.catch(error => {
				toast.error(`Tag creation failed: ${error.message}`);
				return Promise.reject(error)
			});

	};


	return (
		<Wizard width='40rem'>
			<WizardForm title="Create Tag" 
				setWizardVisible={setCreateVisible} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
				inhibitProdWarning={true}
			>
				<TagFormContent controlProps={controlProps} />
			</WizardForm>
		</Wizard>		
	);
};


export default CreateTagWizard;
