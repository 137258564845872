import {FormRow,Input, TextArea} from 'components/ui-core/form/FormControls';

export const BitwisePointFormContent = ({controlProps}) => {

	return (
		<>			
			<FormRow rowNum="1">
				<Input style={{width: "30rem"}} title="Bitwise Point Name" attr="tlm_bitwise_name" {...controlProps}/>
				<Input title="Number of Bits" attr="size_bits" {...controlProps}/>
			</FormRow>

			<FormRow rowNum="2">
				<TextArea title="Description" attr="description" {...controlProps}/>
			</FormRow>

				
		</>
	);
}

export default BitwisePointFormContent;