import React from 'react';

import Table from 'components/ui-core/table/Table/Table';
import TableHeaderBar from 'components/ui-core/table/TableHeaderBar/TableHeaderBar';
import TableFilters from 'components/ui-core/table/TableFilters/TableFilters';

import style from './MsdTable.module.css'

export const MsdTable = ({ useMsdTableObj, ...props }) => {
    
    const {columns, includedHooks: {selection}, toggleRowSelected} = useMsdTableObj;
    
    const getCustomRowProps = selection
        ?   (row) => ({
                onClick: (event) => {
                    console.log("Row clicked: ", row);
                    // row might be undefined if its a blank dnd placeholder
                    if (row) {
                        toggleRowSelected(row.id);
                    }
                }
            })
        : undefined

    const shouldShowTableHeader = () => {
        return shouldFilter() || props.children;
    }

    const shouldFilter = () => {
        return columns.filter(column => column.Filter).length > 0;
    }
 
    const getFilterJsx = () => {
        return shouldFilter() 
            ?  <TableFilters useMsdTableObj={useMsdTableObj} />
            : <div></div>
    }

    const headerBarJsx = () => {
        return shouldShowTableHeader() 
            ? 
                <div className={style.msdTableHeader}>
                    <TableHeaderBar
                        shouldFilter = {shouldFilter} 
                        getFilterJsx = {getFilterJsx}
                        toolbarControls = {props.children}
                    />
                </div>
            : <></>
    }

	return (
		<div className={style.msdTableContainer}>
            {headerBarJsx()}
            <div className={style.msdTable}>
                <Table useMsdTableObj={useMsdTableObj}  
                    getCustomRowProps={getCustomRowProps} 
                    {...props} 
                />
            </div>
		</div>
	);
};

export default MsdTable;
