import 'animate.css';

import SelectInputColumnFilter from 'components/ui-core/table/filter/SelectInputColumnFilter';

import {
    subsytemColumn, 
    nameColumn, 
    descriptionColumn, 
    cspPortColumn, 
    viewButtonColumn,
    createdByUserNameColumn, 
    creationDateColumn, 
    lastModifiedUserNameColumn, 
    lastModifiedDateColumn,
    getTagColumn,
    nodeTlmColumnFilter
} from 'components/ui-core/table/TableHelper';


const isResponseColumn = () => {
    return {
        Header: 'Response',
        accessor: (packet) => {
            return packet.response ? "Yes": "No" 
        },
        Filter: SelectInputColumnFilter,
        filter: 'includes',
    }
}

export const getTelemetryPacketColumns = (viewHandler, header_name="Name") => [
    subsytemColumn(),
    nameColumn("tlm_packet_name", header_name),
    descriptionColumn(),
    nodeTlmColumnFilter(),
    cspPortColumn(),
    isResponseColumn(),
    getTagColumn(),
    viewButtonColumn(viewHandler),
]


export const getPendingBaseColumns = () => [
    subsytemColumn(),
    nameColumn("tlm_packet_name"),
    createdByUserNameColumn(),
    creationDateColumn(),
    lastModifiedUserNameColumn(),
    lastModifiedDateColumn()
]

export const getStagedBaseColumns = () => [
    subsytemColumn(),
    nameColumn("tlm_packet_name")
]
