import React, {useState} from 'react';

import {getBitwiseSchema, getBitwiseOptionsSchema} from 'model/form-schema/ObjectSchemas'
import FormTab from 'components/ui-core/form/FormTab';
import useFormTab from 'components/ui-core/form/useFormTab';
import cloneDeep from 'lodash/cloneDeep';

import {FormRow} from 'components/ui-core/form/FormControls';

import Modal from 'components/ui-core/modal/Modal/Modal';
import CreateOptionWizard from 'components/options/CreateOptionWizard';
import EditOptionValueModal from 'components/options/EditOptionValueModal';

import formControlsStyle from 'components/ui-core/form/FormControls.module.css';

import BitwisePointFormContent from '../BitwisePointFormContent';
import OptionsTable from 'components/options/OptionsTable';
import {areArrayEqualsAndInOrder} from 'components/ui-core/utils/ArrayUtils';
import EntityType from 'model/EntityType';

export const BitwiseEditForm = ({ setEditBitwise, bitwiseEdited, initialBitwisePoint, allBitwiseForTlm }) => {

	const initialOptionsData = React.useMemo(
		() => {
			let optionsData = 
				initialBitwisePoint["representation_list"] 
				? initialBitwisePoint["representation_list"].map(opt => cloneDeep(opt))
				: [];
	
			return optionsData;
		},
		[]
	);

	const [createVisible, setCreateVisible] = useState(false);
	const [editOptionValue, setEditOptionValue] = useState(null);	
	const [optionsData, setOptionsData] = useState(initialOptionsData);	

	const schema = getBitwiseSchema(allBitwiseForTlm, initialBitwisePoint.tlm_bitwise_name);
	const {useFormObj, controlProps} = useFormTab(initialBitwisePoint, schema, true);

	const  createSubmitPromise = async (formData) => {
		let updatedBitwisePoint = {
			...formData
		};

		updatedBitwisePoint["representation_list"] = optionsData;

		return bitwiseEdited(updatedBitwisePoint);
	};

    const editModeTransitionHandler = () => {
        setEditBitwise(null);
    }

	const handleOptionDelete = (optionToDelete) => {
		setOptionsData(oldOptions => {
			const newOptions = [...oldOptions].filter(option => option.name !== optionToDelete.name);
			return newOptions; 
		})

		return Promise.resolve();

	}

	const createAddOptionPromise = async (optionFormToAdd) => {
		setOptionsData(oldOptions => {
			const newOptions = [...oldOptions];
			newOptions.push({...optionFormToAdd});
			return newOptions; 
		})

		return Promise.resolve();
	};
		
	const createEditOptionPromise = async (updatedBitwise) => {
		console.log({updatedBitwise});

		setOptionsData(oldOptions => {

			const newOptions =  [...oldOptions];
            const index = newOptions.findIndex(opt => opt.name === editOptionValue.name);
			newOptions.splice(index, 1, {...updatedBitwise});
			return newOptions;
		})

		return Promise.resolve();
	};

	const {watch, getValues, formState: { isDirty, isValid}} = useFormObj;
	watch("size_bits");

	const hasUserMadeChanges = () => {
		console.log("hasUserMadeChanges", isBitwiseValid(), isBitwiseDirty())
		return isBitwiseValid() && isBitwiseDirty();
	}

	const isBitwiseValid = () => {
		return isValid && getTableErrors().length === 0;
	}

	const isBitwiseDirty = () => {		
		const arrEqual = areArrayEqualsAndInOrder(
			initialOptionsData, optionsData, 
			(optA, optB) => optA.name === optB.name && optA.value === optB.value);


		// true if either form or table is dirty 
		return isDirty || !arrEqual;
	}

	const sizeBits = getValues("size_bits");
	const maxOptionValue = Math.pow(2, sizeBits) - 1;
	const getTableErrors = () => {
		const result = [];

		// check duplicate values
		optionsData.forEach(thisOpt => {
			const otherWithSameValue = optionsData.find(otherOpt => 
				otherOpt.value === thisOpt.value && otherOpt.name !== thisOpt.name)
		
			if (otherWithSameValue) {
				result.push(`Options ${thisOpt.name} and ${otherWithSameValue.name} have the same value`)
			}
		});

		// check with in range of size_bits
		optionsData.forEach(opt => {

			if (opt.value > maxOptionValue) {
				result.push(`Options ${opt.name} must be less than or equal to ${maxOptionValue}`)		
			}
			if (opt.value < 0) {
				result.push(`Options ${opt.name} must be not be negative`)		
			}
		});

		return result;
	}

	const createOptionSchema = () => getBitwiseOptionsSchema(
		optionsData, editOptionValue ? editOptionValue.name : null, maxOptionValue);

	const tableError = getTableErrors();

	return (
		<>	
			{/* Note: The wizard needs to be outside the tab to avoid nested forms */}
			{createVisible && 
				<Modal>
					<CreateOptionWizard
						createAddOptionPromise={createAddOptionPromise}
						schema={createOptionSchema()}
						setCreateVisible={setCreateVisible}
						entityType={EntityType.BitwisePoint}
						optionsData={optionsData}
						maxOptionValue={maxOptionValue}
					/>
				</Modal> 
			}

			{editOptionValue && 
				<Modal>
					<EditOptionValueModal
						createEditOptionPromise={createEditOptionPromise}
						schema={createOptionSchema()}
						editOptionValue={editOptionValue}
						setEditOptionValue={setEditOptionValue}
						entityType={EntityType.BitwisePoint}
					/>
				</Modal> 
			}		

			<FormTab editModeActive={true}
					editModeTransitionHandler={editModeTransitionHandler}
					createSubmitPromise={createSubmitPromise}
					useFormObj={useFormObj}
                    saveButtonTitle="Update Locally"
                    saveButtonWidth = '8.5rem'
					customSubmitEnabledFn={hasUserMadeChanges}
			>

				<div >
					<BitwisePointFormContent 
						controlProps={controlProps} 
						useFormObj={useFormObj}
						isWizard={false}/>

					<FormRow rowNum="3">
						<label>Enumerations Menu</label>
					</FormRow>
						
					<OptionsTable 
						optionsData={optionsData}
						setCreateVisible={setCreateVisible}
						setEditOptionValue={setEditOptionValue}
						handleDelete={handleOptionDelete}
						entityType={EntityType.BitwisePoint}
					/>

					{tableError.length > 0 &&
						<p className={formControlsStyle.formErrorMessage}>
							{tableError[0]}
						</p>
					}	        

				</div>
			</FormTab>

			
		</>
	);
}

export default BitwiseEditForm;