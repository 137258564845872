import React from 'react';
import style from './EditorPage.module.css'
import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';

import EditorToolBar from 'components/ui-core/toolbar/EditorToolBar/EditorToolBar';
import TabMenu from 'components/ui-core/page/TabMenu/TabMenu';

const EditorPage = ({toolBarDef, currentEditOption, editModeActive, 
        tabDefs, selectedTabId, tabSelectedHandler, underlyingEntityQuery, tabControls, ...props}) => {
	
	return (
		<MsdMainPage>
            <div className={style.editorPageContainer}>
                <div className={style.editorToolBarContainer}>
                    <EditorToolBar 
                        toolBarDef={toolBarDef} 
                        currentEditOption={currentEditOption}
                        selectedTabId={selectedTabId} 
                        editModeActive={editModeActive}
                        underlyingEntityQuery={underlyingEntityQuery} />
                </div>

                <div className={style.editorPageBody}>
                    <div className={style.tabMenu}>
                        <TabMenu 
                            tabDefs={tabDefs} 
                            selectedTabId={selectedTabId}  
                            tabSelectedHandler={tabSelectedHandler} 
                            editModeActive={editModeActive} 
                            underlyingEntityQuery={underlyingEntityQuery}
                            tabControls={tabControls} /> 
                    </div>

                    <div className={style.tabsContainer}>
                        {props.children}
                    </div>
                </div>
            </div>		
		</MsdMainPage>		
	);
};

export default EditorPage;
