// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableDeleteButton_tableDeleteButton__bZpfA {
	background: none;
	border: none;
	cursor: pointer;
	margin: 0px;
	padding: 1px 6px;
}

.TableDeleteButton_tableDeleteButton__bZpfA:hover {
	opacity: 0.8;
}

/* Responsive website scaling for 1920 x 1080 screens */

@media screen and (max-width: 1920px) {
	.TableDeleteButton_tableDeleteButton__bZpfA img {
		width: 1.5rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/buttons/TableDeleteButton/TableDeleteButton.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,YAAY;CACZ,eAAe;CACf,WAAW;CACX,gBAAgB;AACjB;;AAEA;CACC,YAAY;AACb;;AAEA,uDAAuD;;AAEvD;CACC;EACC,aAAa;CACd;AACD","sourcesContent":[".tableDeleteButton {\n\tbackground: none;\n\tborder: none;\n\tcursor: pointer;\n\tmargin: 0px;\n\tpadding: 1px 6px;\n}\n\n.tableDeleteButton:hover {\n\topacity: 0.8;\n}\n\n/* Responsive website scaling for 1920 x 1080 screens */\n\n@media screen and (max-width: 1920px) {\n\t.tableDeleteButton img {\n\t\twidth: 1.5rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableDeleteButton": `TableDeleteButton_tableDeleteButton__bZpfA`
};
export default ___CSS_LOADER_EXPORT___;
