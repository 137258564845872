import React from 'react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import {getBoardSchema} from 'model/form-schema/ObjectSchemas'
import {useSaveMetadata} from 'data/queryHooks';
import BoardFormContent from './BoardFormContent';

export const EditBoardModal = ({ setEditBoard, editBoard, metadata, userName }) => {
	const metadataClone = cloneDeep(metadata)

	const schema = getBoardSchema(metadata.boards, editBoard.name);
	const {useFormObj, controlProps} = useWizardForm(editBoard, schema, 'onChange');
	const  {mutateAsync: updateMetadata} = useSaveMetadata();

	const createSubmitPromise = async (formData) => {
		
		const boardInClone = metadataClone.boards.find(board => board.name === editBoard.name);
		boardInClone.name = formData.name;
		boardInClone.version = formData.version;
		boardInClone.modified_by = userName;
		boardInClone.modified_date = new Date().toISOString();

		return await updateMetadata(metadataClone).then((response) => {
			toast.success(`Board Version '${boardInClone.name}' updated`);

			return response;
		})			
	};

	const closeWizard = (wizardVisible) => {
		if (!wizardVisible) {
			setEditBoard(null);
		}
	}

	return (
		<Wizard width='40rem'>
			<WizardForm title="Edit Board Version" 
				setWizardVisible={closeWizard} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
				submitButtonTitle="Edit"
			>
				<BoardFormContent controlProps={controlProps} metadata={metadata}/>
			</WizardForm>
		</Wizard>		
	);
};


export default EditBoardModal;
