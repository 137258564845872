
import React from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from "@hookform/resolvers/yup";

import {getEditClass} from 'components/ui-core/form/FormUtils';

export const useFormTab = (defaultValues, schema, editModeActive, useFormMode='onChange') => {
    
	
	const useFormObj = useForm({
		mode: useFormMode,
		reValidateMode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: defaultValues
	});

	const controlProps = {
		className: getEditClass(editModeActive),
		disabled: !editModeActive,
		editModeActive,
		useFormObj,
		schema,
		useFormMode
	}

	const {trigger, formState: {errors}} = useFormObj;
	React.useEffect(() => {
		if (useFormMode === 'onChange') {
			trigger().then(() => {
				console.log("Trigger errors", errors);
			});		
		}
	}, [useFormMode]);

    const result = {useFormObj, controlProps};
	return result;
}

export default useFormTab;