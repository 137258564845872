import React from 'react';
import style from './Modal.module.css';

export const Modal = (props) => {
	return (
		<div className={style.modalContainer}>
			<div className={style.modalContent}>
				{props.children}
			</div>				
		</div>
	);
};

export default Modal;
