import React, {useState} from 'react';
import {DndProvider} from 'react-dnd'


// Use MouseBackEnd because HTML5Backend does not allow props/state 
// (and hence css feedback) to be set no hovering a mouse inside a drop target.
// We need this to show the drop position based on whether the mouse is in the
// top half or bottom half of drop target
import MouseBackEnd from "react-dnd-mouse-backend";

import style from 'App.module.css';

import MainButton from 'components/ui-core/buttons/MainButton/MainButton';

import SaveableTab from 'components/ui-core/page/SaveableTab';
import useMsdTable from 'components/ui-core/table/msdTable/useMsdTable'
import MsdTable from 'components/ui-core/table/msdTable/MsdTable'
import MoveToPos from 'components/ui-core/table/Table/MoveToPos';

import { getPacketSummaryItems } from 'components/ui-core/common/PacketSummaryHelper';
import { useSummmaryContext } from 'components/ui-core/common/SummaryContext';

import {areArrayEqualsAndInOrder} from 'components/ui-core/utils/ArrayUtils'

import { doesEntityContainTag } from 'components/tags/TagHelper';
import ManagedTag from 'model/ManagedTag';
import EntityType from 'model/EntityType';


export const EditAssociations = ({allObjects, packetEntityType, lhsColumns, rhsColumns, 
    editModeTransitionHandler, underlyingEntity, updateEntityPromise}) => {
        
    const childPacketType = packetEntityType.child_entity_type;

    const assocAttr = packetEntityType.childAssocListName
    const nameAttr = childPacketType.nameAttr
    const childTypeName = childPacketType.description

    //DnD
    const DndItemTypes = {
        LHS_ROW: "lhs_row",
        RHS_ROW: "rhs_row"
    }

    const initialRhsNames = underlyingEntity[assocAttr];

    const assignSeqNos = (objArr) => {
        let sequenceNo = 1;
        objArr.forEach(obj => {
            if (doesEntityContainTag(obj, ManagedTag.DELETED.name)) {
                obj.seq_no = null;    
            } else {
                obj.seq_no = sequenceNo++
            }
        });
    }

    // LHS
    const initialLhsObjArr = React.useMemo(
        () => {
            const allObjectsCopy = [...allObjects];  
            return allObjectsCopy.filter(obj => !initialRhsNames.includes(obj[nameAttr]))
        },
        [allObjects, initialRhsNames, nameAttr]
    )

    const [lhsTableData, setLhsTableData] = useState(initialLhsObjArr);
    const [preselectedLhsRowIdsObj, setPreselectedLhsRowIdsObj] = useState({});
    const lhsTableObject = useMsdTable(
		{
            tableName: "EditAssociations-LHS",
			columns: lhsColumns, 
			data: lhsTableData, 
			sortBy: null, 
			addSort: true, 
			addSelection: true,
			addPagination: true,
			dndSpec: {
                tableId: "LHS",
                nameAttr,
                dragSpec: {
                    type: DndItemTypes.LHS_ROW,
                },
                dropSpec: {
                    accept: [DndItemTypes.RHS_ROW],
                    performDrop: (dropRequest) => performDrop(dropRequest)
                }
            },  
            options: {
                initialState: {
                    selectedRowIds: preselectedLhsRowIdsObj,
                },
                getRowId: (row) => {
                    return row[nameAttr];
                }
            }
		}
	);
    const {selectedFlatRows: selectedLhs} = lhsTableObject;


    //RHS
    const initialRhsObjArr = React.useMemo(
        () => {
            const rhsArr = initialRhsNames.map(
                assignedName => allObjects.find(obj => obj[nameAttr] === assignedName));            
            
            assignSeqNos(rhsArr);
            return rhsArr;
        },
        [initialRhsNames, allObjects, nameAttr]
    )
    const [rhsTableData, setRhsTableData] = useState(initialRhsObjArr);
    const [preselectedRhsRowIdsObj, setPreselectedRhsRowIdsObj] = useState({});
    const rhsTableObject = useMsdTable(
		{
            tableName: "EditAssociations-RHS",
			columns: rhsColumns, 
			data: rhsTableData, 
			sortBy: null, 
			addSort: false, 
			addSelection: true,
			addPagination: false, 
			dndSpec: {
                tableId: "RHS",
                nameAttr,
                dragSpec: {
                    type: DndItemTypes.RHS_ROW,
                },
                dropSpec: {
                    accept: [DndItemTypes.LHS_ROW, DndItemTypes.RHS_ROW],
                    performDrop: (dropRequest) => performDrop(dropRequest)
                }
            },
            options: {
                initialState: {
                    selectedRowIds: preselectedRhsRowIdsObj,
                },
                getRowId: (row) => {
                    return row[nameAttr];
                }
            }
        }
	);
    const {selectedFlatRows: selectedRhs} = rhsTableObject;

    // Keep a track on the number of RHS selections, and if changed revalidate the move to seq form 
    const [noSelectedRhs, setNoSelectedRhs] = useState(0);

    const performDrop = (dropRequest) => {
        console.log(`DROP into ${dropRequest.dropTableId}: `, dropRequest);
        
        if (dropRequest.dragDetails.tableId === dropRequest.dropTableId) {
            dragWithinTable(dropRequest);
        } else {
            dragBetweenTables(dropRequest)
        }
    } 

    const getTableData = (tableId) => {
        return tableId === "LHS" ?  lhsTableData : rhsTableData;
    };

    const getSetTableDataFn = (tableId) => {
        return tableId === "LHS" ?  setLhsTableData : setRhsTableData;
    };

    const getSetPreselectedIdsObjFn = (tableId) => {
        return tableId === "LHS" ?  setPreselectedLhsRowIdsObj : setPreselectedRhsRowIdsObj;
    };


    const getPivotElementFromDrag = ({mousePositionAtDropIndex, dropAtIndex, 
        dragDetails: {selectedTableData}}, origTableData) => {
    
        let pivotIndex = dropAtIndex;
        // Adjust the mouse being at the top of the index
        if (mousePositionAtDropIndex === "top-half") {
            pivotIndex--;
        }

        if (pivotIndex >= 0) {
            // Search for the first element above this that is NOT in the selection 
            // (that's about to move)
            for (let i = pivotIndex; i >= 0; i--) {
                if (!selectedTableData.find(row => origTableData[i][nameAttr] === row[nameAttr])) {
                    return origTableData[i];
                }
            }  
        } else {
            return null;
        }
    }    


    const dragWithinTable = (dropRequest) => {
        console.log("dragWithinTable", dropRequest);

        const origTableData = getTableData(dropRequest.dropTableId);

        // Take the selections as they stand from the source table to ensure they are
        // correctly ordered
        const selectedTableData = dropRequest.dragDetails.selectedTableData;
        
        // Find the actual element that we should stack up all the selections behind 
        // (null for start of table)
        const pivotElement = getPivotElementFromDrag(dropRequest, origTableData);

        reorderWithinTable(dropRequest.dropTableId, selectedTableData, pivotElement) 
    }

    const reorderWithinTable = (tableId, selectedTableData, pivotElement) => {
        console.log("reorderWithinTable", tableId);

        const origTableData = getTableData(tableId);

        // Remove all selected items from the source table
        const newTableData = origTableData
            .filter(tableRow => 
                !selectedTableData.find(row => row[nameAttr] === tableRow[nameAttr]));
        
        // Now find the new index of the pivot element
        let insertAtIndex = 0;
        if (pivotElement) {
            insertAtIndex = newTableData.findIndex(
                element => element[nameAttr] === pivotElement[nameAttr]) + 1;
        }
        
        addSelectionsAtIndex(tableId, newTableData, insertAtIndex, selectedTableData);
    }

    const addSelectionsAtIndex = (tableId, newTableData, insertAtIndex, selectedTableData) => {
        newTableData.splice(insertAtIndex, 0, ...selectedTableData);
        if (tableId === "RHS") {
            assignSeqNos(newTableData);
        }

        console.log("New Reordered Data", newTableData);

        // Lose the selection
        setSelectedIdsObj(tableId, {});

        // set the new data 
        getSetTableDataFn(tableId)(newTableData);        
    }

    const dragBetweenTables = (dropRequest) => {
        console.log("dragBetweenTables", dropRequest)

        const sourceTableId = dropRequest.dragDetails.tableId;
        const selectedTableData = dropRequest.dragDetails.selectedTableData;

        // Source table
        const newSourceData = getTableData(sourceTableId)
            .filter(tableRow => 
                !selectedTableData.find(row => row[nameAttr] === tableRow[nameAttr]));
        
        if (sourceTableId === "RHS") {
            assignSeqNos(newSourceData);
        }
        
        setSelectedIdsObj(sourceTableId, {});
        getSetTableDataFn(sourceTableId)(newSourceData);    
        
        // Target table
        const targetTableId = dropRequest.dropTableId;
        const newTargetData = [...getTableData(targetTableId)];

        // Now find the new index of the pivot element
        const pivotElement = getPivotElementFromDrag(dropRequest, newTargetData);
        let insertAtIndex = 0;
        if (pivotElement) {
            insertAtIndex = newTargetData.findIndex(
                element => element[nameAttr] === pivotElement[nameAttr]) + 1;
        }
        
        newTargetData.splice(insertAtIndex, 0, ...selectedTableData);
        if (targetTableId === "RHS") {
            assignSeqNos(newTargetData);
        }

        // Maintain selections in target
        let selectedIdsObj = {};
        selectedTableData.forEach(row => {
            selectedIdsObj[row[nameAttr]] = true;
        });	
        setSelectedIdsObj(targetTableId, selectedIdsObj);
        
        // set the new data 
        getSetTableDataFn(targetTableId)(newTargetData);        
    }

    const setSelectedIdsObj = (tableId, idsObj) => {
        getSetPreselectedIdsObjFn(tableId)(idsObj);
        if (tableId === "RHS" && idsObj.length !== noSelectedRhs) {
            setNoSelectedRhs(idsObj.length);
        } 
    }

    //Move methods
    const moveSelectedLeftToRight = () => {
        //Add to RHS
        const newRhsArr = [...rhsTableData];
        selectedLhs.forEach(selection => {
            newRhsArr.push(selection.original);
        });
        assignSeqNos(newRhsArr)
        setRhsTableData(newRhsArr);

        //Remove from LHS
        const newLhsArr = lhsTableData
            .filter(lhsRow => !newRhsArr.find(rhsRow => rhsRow[nameAttr] === lhsRow[nameAttr]))
        setLhsTableData(newLhsArr);
    }

    const moveSelectedRightToLeft = () => {
        //Add to LHS
        const newLhsArr = [...lhsTableData];
        selectedRhs.forEach(selection => {
            newLhsArr.push(selection.original);
        });
        setLhsTableData(newLhsArr);

        //Remove from RHS
        let newRhsArr = [...rhsTableData];
        newRhsArr = newRhsArr
            .filter(rhsRow => !newLhsArr.find(lhsRow => lhsRow[nameAttr] === rhsRow[nameAttr]))
        assignSeqNos(newRhsArr)
        setRhsTableData(newRhsArr);        
    }


    const hasUserMadeChanges = () => {
        const currentRhsNames = rhsTableData.map(rhsObj => rhsObj[nameAttr]);
        return !areArrayEqualsAndInOrder(currentRhsNames, initialRhsNames);
    };

    const createSubmitPromise = async (event) => {
		let updatedEntity = {
			...underlyingEntity,
		}
        updatedEntity[assocAttr] =  rhsTableData.map(rhsObj => rhsObj[nameAttr])
        
        await updateEntityPromise(updatedEntity);
    }

    const {setSummaryItems} =  useSummmaryContext();
	React.useEffect(() => {
			setSummaryItems(getPacketSummaryItems(rhsTableData, childTypeName));
		}, 
		[rhsTableData, childTypeName]
	);

    const ensureDerivedAtEnd = () =>  {
        if (childPacketType === EntityType.TlmPoint) {
            const nonDerived = rhsTableData.filter(row => row["type"] !== "derived");
            const derived = rhsTableData.filter(row => row["type"] === "derived");
            const reordered = nonDerived.concat(derived);
        
            const reorderedNames = reordered.map(row => row[childPacketType.nameAttr]);
            const currentRhsNames = rhsTableData.map(row => row[childPacketType.nameAttr]);
            
            if (!areArrayEqualsAndInOrder(reorderedNames, currentRhsNames)) {
                assignSeqNos(reordered)
                setRhsTableData(reordered);
            }
        }
	}
	ensureDerivedAtEnd();

    return ( 
        <>
            <SaveableTab editModeActive={true}
                editModeTransitionHandler={editModeTransitionHandler}
                createSubmitPromise={createSubmitPromise}
                saveEnabled={hasUserMadeChanges()}>            

                <DndProvider backend={MouseBackEnd}>

                    <div className={style.splitPageContainer}>
                        <div className={style.splitPageTableContainer}>
                            <h2>Available {childTypeName+"s"}</h2>
                            <MsdTable useMsdTableObj={lhsTableObject} />		
                        </div>
                        
                        <div className={style.splitPageButtonsContainer}>
                            <MainButton title="Add >" width="6rem" disabled={selectedLhs.length === 0}
                                onClick={moveSelectedLeftToRight} />
                            <br />
                            <MainButton title="< Remove" width="6rem" disabled={selectedRhs.length === 0}
                                onClick={moveSelectedRightToLeft} />
                        </div>

                        <div className={style.splitPageTableContainer}>
                            <h2>Assigned {childTypeName+"s"}</h2>
                            <MsdTable useMsdTableObj={rhsTableObject}>
                                <MoveToPos
                                    tableData={rhsTableData}
                                    msdTableObject={rhsTableObject}
                                    nameAttr={nameAttr}
                                    arrayFnOnAdjustedArr={newTableData => assignSeqNos(newTableData)}
                                    setTableDataFn={setRhsTableData}
                                    setSelectedIdsObjFn={(idsObj) => setSelectedIdsObj("RHS", idsObj)}
                                />

                            </MsdTable>		
                        </div>
                    </div>
                </DndProvider>
            </SaveableTab>
        </>
	);
};

export default EditAssociations;
