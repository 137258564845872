import BitwiseTableTabViewMode from "./BitwiseTableTabViewMode";
import BitwiseTableTabBuildMode from "./BitwiseTableTabBuildMode";

export const BitwiseTableTab = ({editModeActive, editModeTransitionHandler, tlmPoint, allTlmBitwise }) => {
	
	return (
		<>
			{editModeActive
				? 	
					<BitwiseTableTabBuildMode 
						editModeTransitionHandler={editModeTransitionHandler}
						initialTlmPoint={tlmPoint}
						initialBitwise={allTlmBitwise}/>
				: 
					<BitwiseTableTabViewMode tlmPoint={tlmPoint} allTlmBitwise={allTlmBitwise}/>
			}
		</>
	);
};

export default BitwiseTableTab;

