import React from 'react';
import ASTLogoMain from 'img/ast-logo-main.png';
import style from './LoginForm.module.css';
import SubmittablePage from 'components/ui-core/page/SubmittablePage';
import ASTLoginButton from '../ASTLoginButton/ASTLoginButton';

export const LoginForm = () => {

	const envVersion = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "Unknown Version";


	// const version_updates = JSON.parse(process.env.REACT_APP_RELEASE_NOTES);
	//const version_updates = process.env.REACT_APP_RELEASE_NOTES.split(",");

	// const version_updates = [
	// 	'Add system tag "PAYLOAD."', 
	// 	'Added system tag to frontend with unique color', 
	// 	'System tag gets populated if packet is micron (HSL or LSL)', 
	// 	'Remove export options for other tags within S3. This was causing confusion.', 
	// 	'Make user tag page scrollable',
	// ];

	return (
		<>
			<SubmittablePage>

				<div className={style.login}>
					<section>
						<div className={style.astDatabaseHeader}>
							<img src={ASTLogoMain} alt="AST SpaceMobile" />
							<h1>Master Satellite Database {"(" + envVersion + ")"}</h1>
						</div>
						<div className={style.formWrapper}>

							<ASTLoginButton />

						</div>
					</section>

				</div>

			</SubmittablePage>
		</>


	);
};

export default LoginForm;






