import style from './TableHeaderBar.module.css';
import TableBarControls from 'components/ui-core/table/TableBarControls/TableBarControl';

export const TableHeaderBar = ({shouldFilter, getFilterJsx, toolbarControls}) => {
	
	const additionalBarStyle = shouldFilter() 
		? {
			background: "#eae7e7",
			padding: "0.2rem 0.5rem",
			border: "0.15rem solid #A0A0A0",
			marginBottom: "6px" 
		}
		: {
			padding: "0.5rem 0.2rem 0.0rem 0",	
		};

	return (
		<div style={additionalBarStyle} className={style.tableHeaderBarContainer}>
			{getFilterJsx()}
			<TableBarControls>
				{toolbarControls}                    
			</TableBarControls>                    
		</div>
	);
};

export default TableHeaderBar;

