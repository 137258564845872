import React from 'react';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable'
import Tag from 'components/tags/Tag';

export const TagsTabViewMode = ({underlyingEntity, allTags}) => {

	const tableData = underlyingEntity.environment_tags
		.filter(entry => entry.value)
		.map(entry => ({
			name: entry.name,
			description: allTags.find(tag => tag.name === entry.name)?.description
		}));

	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				Cell: (tableInstance) => {
					const tagRow = tableInstance.row.original;
					return (
						<Tag key={tagRow.name} title={tagRow.name} />
					);
				},
			},
			{
				Header: 'Description',
				accessor: 'description',
			},
		],
		[]
	);

	return (
		<ManagedMsdTable tableName="TagsTabViewMode" columns={columns} data={tableData} />
	);
};

export default TagsTabViewMode;
