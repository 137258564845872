
import React from 'react';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable'
import EntityType from 'model/EntityType';

import style from 'components/ui-core/modal/ConfirmationModal/ConfirmationModal.module.css';
import Modal from 'components/ui-core/modal/Modal/Modal';
import CloseButton from 'components/ui-core/buttons/CloseButton/CloseButton';
import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import SelectInputColumnFilter from 'components/ui-core/table/filter/SelectInputColumnFilter';

const pushEnvName = process.env.REACT_APP_PUSH_ENV_NAME;
const UPSERTED_ACTION = "Created/Updated";
const DELETED_ACTION = "Deleted";


const TableSection = ({title, descJsx, tableData, columnArr, addPagination}) => {

	const columns = React.useMemo(
		() => columnArr,
		[]
	);

    return( 
        <>
            <h2>{title}</h2>
            {descJsx}
    		<ManagedMsdTable tableName={title} columns={columns} data={tableData} addPagination={addPagination} />            
            <br/>
        </>
    )
}


export const PushReportModal = ({pushJob, setPushJob}) => {
    const report = pushJob.result

    const blockedRows = getBlockedRows(report);
    const failureRows = getRowsForResult(report, "failed_list", create_failure_row);
    const successRows = getRowsForResult(report, "success_list", create_success_row);

    const title = `Push to ${pushEnvName}`

    return (
        <Modal>
            <div style={{width: "100rem"}} className={style.notificationModal}>
                {/* Header */}
                <div className={style.notificationModalRowHeading}>
                    <div>
                        <h1>{title}</h1>
                    </div>
                    <div>
                        <div onClick={() => setPushJob(null)}>
                            <CloseButton />
                        </div>
                    </div>
                </div>

                <div className={style.notificationModalRow}>
                    <div className={style.notificationBodyContent}>

                        {failureRows.length > 0 && 
                            <TableSection {...getEntityByResultProps(failureRows, "Failed Actions",
                                `The following items failed when being applied to ${pushEnvName}`,
                                failure_columns)} />}
                        {report["errors"].length > 0 && 
                            <TableSection {...getErrorProps(report["errors"])}/>}

                        {blockedRows.length > 0 && 
                            <TableSection {...getBlockedPacketProps(blockedRows)} />}

                        {successRows.length > 0 && 
                            <TableSection {...getEntityByResultProps(successRows, "Successful Actions",
                                `The following items have been successfully applied to ${pushEnvName}`,
                                success_columns)} />}

                        <h2>Raw Report</h2>
                        <p>The raw report can be copied for future analysis</p>
                        <textarea style={{height: '400px', width: '100%'}} 
                            value={JSON.stringify(report, null, 4)} readOnly/>
                    </div>
                </div>        
            </div>                
        </Modal>
    )
}

const create_success_row = (typeName, actionName, tracked_entry) => {
    return {
        type_name: typeName,
        pushed_item: tracked_entry,
        action: actionName 
    }
}

const create_failure_row = (typeName, actionName, tracked_entry) => {
    return {
        type_name: typeName,
        pushed_item: tracked_entry.tracked_name,
        action: actionName,
        failure_reason: tracked_entry.failure_reason
    }
}

const getRowsForResult = (report, resultListName, createRowFromTrackerEntry) => {
    const cmdRows = getTrackerRows(
        report.cmd_tracker, EntityType.CmdPacket, EntityType.CmdParam, resultListName, createRowFromTrackerEntry)

    const tlmRows = getTrackerRows(
        report.tlm_tracker, EntityType.TlmPacket, EntityType.TlmPoint, resultListName, createRowFromTrackerEntry)

    const bitwiseRows = getOperationTrackerRows(
        report.tlm_tracker.bitwise, "Bitwise List", UPSERTED_ACTION, resultListName, createRowFromTrackerEntry);

    const metadataRows = getOperationTrackerRows(
        report.metadata_tracker.metadata, "Metadata (Units, Nodes, etc..)", "Updated", resultListName, createRowFromTrackerEntry);

    return metadataRows.concat(cmdRows.concat(tlmRows.concat(bitwiseRows)))
}

const getTrackerRows = (entityTracker, packetType, childType, resultListName, createRowFromTrackerEntry) => {
    // Packets
    const upsertedPacketRows = getOperationTrackerRows(
        entityTracker.pushed_packets, packetType.description, 
        UPSERTED_ACTION, resultListName, createRowFromTrackerEntry);
    
    const deletedPacketRows = getOperationTrackerRows(
        entityTracker.deleted_packets, packetType.description, 
        DELETED_ACTION, resultListName, createRowFromTrackerEntry);

    const packetRows = upsertedPacketRows.concat(deletedPacketRows)

    // Children
    const upsertedChildRows = getOperationTrackerRows(
        entityTracker.pushed_children, childType.description, 
        UPSERTED_ACTION, resultListName, createRowFromTrackerEntry);

    const deletedChildRows = getOperationTrackerRows(
        entityTracker.deleted_children, childType.description, 
        DELETED_ACTION, resultListName, createRowFromTrackerEntry);

    const childRows = upsertedChildRows.concat(deletedChildRows)

    return packetRows.concat(childRows)
}

const getOperationTrackerRows = (operationTracker, typeName, actionName, resultListName, createRowFromTrackerEntry) => {
    const tracked_list = operationTracker[resultListName]
    return tracked_list.map(tracked_entry => createRowFromTrackerEntry(typeName, actionName, tracked_entry))
}


const getBlockedRows = (report) => {
    const blockedCmd = report["blocked_cmd_packet_details"];
    const blockedTlm = report["blocked_tlm_packet_details"];

    const getRows = (packetDetails, entityType) => {
        return packetDetails
            .map(details => ({
                type_name: entityType.description,
                packet: details.packet,
                blocked_by: details.blocked_by.join(", ")
            }));
    }

    const cmdRow = getRows(blockedCmd, EntityType.CmdPacket);
    const tlmRow = getRows(blockedTlm, EntityType.TlmPacket);
    return cmdRow.concat(tlmRow);
}

const getBlockedPacketProps = (blockedRows) => {

    return {
        title: "Blocked Packets",
        descJsx:
            <p>
                The following packets were unable to be pushed because they are blocked by other unstaged packets that share 
                child parameters/points that have changed. Pushing them would inadvertently change these unstaged packets in the production 
                environment. Packets that share child parameters/points in this way must themselves be staged so that
                they are pushed at the same time. 
            </p>,
        tableData: blockedRows,
        columnArr: [
            {
                Header: 'Packet Type',
                accessor: 'type_name'
            },
            {
                Header: 'Staged Packet',
                accessor: 'packet'
            },
            {
                Header: 'Unstaged Blocking Packets',
                accessor: 'blocked_by',
                Filter: TextInputColumnFilter
            }
        ],
        addPagination: false    
    }
}

const getEntityByResultProps = (rows, title, descText, columnArr) => {
    return {
        title: title,
        descJsx:
            <p>
                {descText}
            </p>,
        tableData: rows,
        columnArr: columnArr,
        addPagination: true    
    }
}

const success_columns = [
    {
        Header: 'Item Type',
        accessor: 'type_name',
        Filter: SelectInputColumnFilter
    },
    {
        Header: 'Pushed Item',
        accessor: 'pushed_item',
        Filter: TextInputColumnFilter
    },
    {
        Header: 'Action Performed',
        accessor: 'action',
        Filter: SelectInputColumnFilter
    }
]

const failure_columns = [
    ...success_columns,
    {
        Header: 'Failure Reason',
        accessor: 'failure_reason',
    }
]

const getErrorProps = (errors) => {
    return {
        title: "Errors",
        descJsx:
            <p>
                The following errors occured. Please see you system administrator for more details 
            </p>,
        tableData: errors.map((error) => ({
            message: error
        })),
        columnArr: [
            {
                Header: 'Message',
                accessor: 'message',
            }
        ],
        addPagination: true    
    }
}