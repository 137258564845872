import ConfirmationRequest from "model/ConfirmationRequest";
import { useASTData } from "context/ASTContext";

export const useSubmitWithWarnings = (submitFn, inhibitWarning = false) => {

	const {setConfirmationRequest} = useASTData();

	const envName = process.env.REACT_APP_ENV_NAME ? process.env.REACT_APP_ENV_NAME : "Unknown";
	const envType = process.env.REACT_APP_ENV_TYPE;

    let displayWarning = false;
    if (envType === "FSW_PROD") {
        const propValue = process.env.REACT_APP_WARN_PROD_EDIT; 
        displayWarning = propValue === "false" ? false : true; 
    }
    
    const message = 
        <div>
            You are about to update data on a production instance of the MSD ({envName}) 
            <br/>
            <br/>
            Most MSD changes should be made in an "FSW_DEV" instance of the database and then 
            pushed to production after review. This requires coordination with releases of flight
            software.
            <br/>
            <br/>
            Changes made directly to production instances will be 
            subsequently <b>OVERWRITTEN</b> whenever associated packets/points/params are later 
            changed and pushed here from the FSW_DEV instance
            <br/>
            <br/>
            You <b>MUST</b> manually duplicate your changes made here into the FSW_DEV instance to avoid this
        </div>



    const submitWithAppropriateWarnings = (submitFnParams) => {
        if (displayWarning && !inhibitWarning) {
            const confirmationRequest =  new ConfirmationRequest(
                null,
                async () => submitFn(submitFnParams),
                `Update '${envName}'`, 
                "Confirm Changes",
                message,
                null,
                null
            )
        
            setConfirmationRequest(confirmationRequest);
        } else {
            submitFn(submitFnParams)
        }
    }

    return {submitWithAppropriateWarnings}
}


export default useSubmitWithWarnings