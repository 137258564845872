// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertFormContent_tableTextButton__hc16v {
	display: flex;
	flex-flow: row nowrap;
    align-items: center;
    justify-content: center;	
	color: black;
	background: var(--ast-primary-orange-1-color);
	border: 0.1rem solid #c4b9ae;
	font-size: 1.25rem;
	font-weight: 500;
	cursor: pointer;
	border-radius: 0.4rem;
}

.AlertFormContent_limitsRow__OKyxJ {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
}

.AlertFormContent_lowLimitsGroup__zJ6h5 {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
	flex: 1 1;
}

.AlertFormContent_highLimitsGroup__kB1lT {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
	flex: 1 1;
}

.AlertFormContent_lowLimitsGroup__zJ6h5 > label {
	margin-right: 1rem;
}

.AlertFormContent_highLimitsGroup__kB1lT > label {
	margin-left: 1rem;
}

.AlertFormContent_spacer__ao49N {
	flex: 3 0;

}

.AlertFormContent_limitsRow__OKyxJ label {
	font-weight: 500;
}

.AlertFormContent_limitsRow__OKyxJ input[type='text'] {
	width: 10rem;
	border-radius: 0rem;
	margin: 1rem 0 2rem 0;
}



.AlertFormContent_redLabel__jrZi4 {
	color: var(--ast-primary-red-color);
}

.AlertFormContent_yellowLabel__gjwNi {
	color: var(--ast-primary-orange-1-color);
}

.AlertFormContent_greenLabel__hdufR {
	color: #82dd55;
}
`, "",{"version":3,"sources":["webpack://./src/components/alerts/alert-page/AlertFormContent.module.css"],"names":[],"mappings":"AAEA;CACC,aAAa;CACb,qBAAqB;IAClB,mBAAmB;IACnB,uBAAuB;CAC1B,YAAY;CACZ,6CAA6C;CAC7C,4BAA4B;CAC5B,kBAAkB;CAClB,gBAAgB;CAChB,eAAe;CACf,qBAAqB;AACtB;;AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,8BAA8B;CAC9B,uBAAuB;AACxB;;AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,8BAA8B;CAC9B,uBAAuB;CACvB,SAAS;AACV;;AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,8BAA8B;CAC9B,uBAAuB;CACvB,SAAS;AACV;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,SAAS;;AAEV;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,YAAY;CACZ,mBAAmB;CACnB,qBAAqB;AACtB;;;;AAIA;CACC,mCAAmC;AACpC;;AAEA;CACC,wCAAwC;AACzC;;AAEA;CACC,cAAc;AACf","sourcesContent":["@import 'App.module.css';\n\n.tableTextButton {\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n    align-items: center;\n    justify-content: center;\t\n\tcolor: black;\n\tbackground: var(--ast-primary-orange-1-color);\n\tborder: 0.1rem solid #c4b9ae;\n\tfont-size: 1.25rem;\n\tfont-weight: 500;\n\tcursor: pointer;\n\tborder-radius: 0.4rem;\n}\n\n.limitsRow {\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n\tjustify-content: space-between;\n\talign-items: flex-start;\n}\n\n.lowLimitsGroup {\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n\tjustify-content: space-between;\n\talign-items: flex-start;\n\tflex: 1 1;\n}\n\n.highLimitsGroup {\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n\tjustify-content: space-between;\n\talign-items: flex-start;\n\tflex: 1 1;\n}\n\n.lowLimitsGroup > label {\n\tmargin-right: 1rem;\n}\n\n.highLimitsGroup > label {\n\tmargin-left: 1rem;\n}\n\n.spacer {\n\tflex: 3 0;\n\n}\n\n.limitsRow label {\n\tfont-weight: 500;\n}\n\n.limitsRow input[type='text'] {\n\twidth: 10rem;\n\tborder-radius: 0rem;\n\tmargin: 1rem 0 2rem 0;\n}\n\n\n\n.redLabel {\n\tcolor: var(--ast-primary-red-color);\n}\n\n.yellowLabel {\n\tcolor: var(--ast-primary-orange-1-color);\n}\n\n.greenLabel {\n\tcolor: #82dd55;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableTextButton": `AlertFormContent_tableTextButton__hc16v`,
	"limitsRow": `AlertFormContent_limitsRow__OKyxJ`,
	"lowLimitsGroup": `AlertFormContent_lowLimitsGroup__zJ6h5`,
	"highLimitsGroup": `AlertFormContent_highLimitsGroup__kB1lT`,
	"spacer": `AlertFormContent_spacer__ao49N`,
	"redLabel": `AlertFormContent_redLabel__jrZi4`,
	"yellowLabel": `AlertFormContent_yellowLabel__gjwNi`,
	"greenLabel": `AlertFormContent_greenLabel__hdufR`
};
export default ___CSS_LOADER_EXPORT___;
