// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MsdTable_msdTableContainer__65XZt {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    overflow: hidden;
    
}

.MsdTable_msdTableHeader__cPrcR {
    flex-shrink: 0;
    flex-grow: 0;
}

.MsdTable_msdTable__2Po7W {    
    min-height: 0;
    flex-shrink: 1;
    flex-grow: 1;

    flex-flow: column nowrap;
    height: 100%;
    display: flex;
    background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/ui-core/table/msdTable/MsdTable.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wBAAwB;IACxB,YAAY;IACZ,gBAAgB;;AAEpB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,YAAY;;IAEZ,wBAAwB;IACxB,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".msdTableContainer {\n    display: flex;\n    flex-flow: column nowrap;\n    height: 100%;\n    overflow: hidden;\n    \n}\n\n.msdTableHeader {\n    flex-shrink: 0;\n    flex-grow: 0;\n}\n\n.msdTable {    \n    min-height: 0;\n    flex-shrink: 1;\n    flex-grow: 1;\n\n    flex-flow: column nowrap;\n    height: 100%;\n    display: flex;\n    background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msdTableContainer": `MsdTable_msdTableContainer__65XZt`,
	"msdTableHeader": `MsdTable_msdTableHeader__cPrcR`,
	"msdTable": `MsdTable_msdTable__2Po7W`
};
export default ___CSS_LOADER_EXPORT___;
