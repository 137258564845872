import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import CommandParameterFormTab, { AlertFormTab } from './AlertFormTab';

import { useASTData } from 'context/ASTContext';

import {useEditorPage} from 'components/ui-core/page/useEditorPage'
import EditorPage from 'components/ui-core/layout/EditorPage/EditorPage';
import ConfirmationRequest from 'model/ConfirmationRequest';
import SideBarOption from 'model/SideBarOption';

import EntityType from 'model/EntityType';

import { useMetadata, useAlerts, useAlertById, useSaveAlert, useDeleteAlert, useTlmPointsLite, useTelemetryPackets}  from 'data/queryHooks';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';
import LimitOptionsTab from '../limitOptions/LimitOptionsTab';
import DiscreteOptionsTab from '../limitOptions/DiscreteOptionstab';



const AlertPage = () => {
	// Ensure each Page has it's instance (fresh component tree) otherwise history.push()
	// to the same route will reuse components for other object navigate away from
	let {alertId} = useParams();

	return <AlertPageBody keyProp={alertId}/>
}

const AlertPageBody = ({keyProp}) => {

	const {setSideBarOption, setHeaderTitle, setConfirmationRequest} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.Alerts);
		setHeaderTitle(null);
	}, []);
	
	console.log(keyProp);
	let {alertId} = useParams();

	// Data queries
	const allTlmPacketsQuery = useTelemetryPackets();
	const tlmPointLiteQuery = useTlmPointsLite();
	const alertByIdQuery = useAlertById(alertId);
	const allAlertsQuery = useAlerts();
	const metadataQuery = useMetadata();
	const [thresholdType, setThresholdType] = useState('');
	const [alertName , setAlertName] = useState("");
	const [alert , setAlert] = useState(alertByIdQuery.data);
	React.useEffect(() => {
		if (alertByIdQuery.data) {
			setHeaderTitle(alertByIdQuery.data?.alert_name);
			setAlertName(alertByIdQuery.data?.alert_name);
			setAlert(alertByIdQuery.data);
			if(alertByIdQuery.data?.thresholds == null){
				setThresholdType("discrete");
			}
			else{
				setThresholdType("non-discrete")
			}
		}
	}, [alertByIdQuery.data]);	


	const  {mutateAsync: deleteAlert} = useDeleteAlert();

	const createDeleteRequest = () => {

		return new ConfirmationRequest(
			alertName,
			deleteAlert,
			`Delete '${alertByIdQuery.data?.alert_name}'`, 
			"Delete",
			"Are you sure you want to delete this Alert?",
			'/msd-alerts',
			`Alert '${alertByIdQuery.data?.alert_name}' "deleted"}`
		)
	}

	const tabDefs = React.useMemo(() =>  [
		{
			id: "thresholds",
			buttonText: "Thresholds",
			buttonType: "Options",
			editMode: "none",
		},
		{
			id: "form_tab",
			buttonText: "Alert Config",
			buttonType: "CommandParam",
			editMode: "formEditMode"
		},
	] , []);



	const {
        currentEditOption,
        selectedTabId,
        editModeActive,
        tabSelectedHandler,
        editModeTransitionHandler,
		formKey
    } = useEditorPage(tabDefs);


	React.useEffect(() => {
		if(thresholdType == 'discrete' && tabDefs[1]['id'] != 'Discrete') {
			tabDefs[0] = {
				id: "Discrete",
				buttonText: "Discrete",
				buttonType: "TelemetryPoint",
				editMode: "none",
			};
			tabSelectedHandler('Discrete');
		}
		if(thresholdType == 'non-discrete'){
			tabSelectedHandler('thresholds');
		}
		
	}, [thresholdType]);


	let toolBarDef = 	{
		navButtons: [
			{
				title: "< View All Alerts",
				width: "12.5rem",
				route: "/msd-alerts"
			}
		],
		manipulationButtons: {
			mutationButtons: {
				includeEdit: true,
				includeBuildMode: false,
				editModeTransitionHandler: editModeTransitionHandler
			},
			deleteButton: {
				createDeleteRequest: createDeleteRequest
			}
		}
	}


	const {mutateAsync: UseUpdateAlert}  = useSaveAlert();
	const updateEntityPromise = async (updatedAlert) => {
			updateAlerts(updatedAlert);
	}

	const updateAlerts = async (updatedAlert)  => {
		console.log(updatedAlert);
		return await UseUpdateAlert(updatedAlert)
		.then(refreshedAlert => {
			toast.success(`'${refreshedAlert["alert_name"]}' updated`);
			console.log(refreshedAlert.data)
			setAlert(refreshedAlert);
			return refreshedAlert;
		})
		.catch(error => {
			toast.error("Alerts update failed");
			return Promise.reject(error)
		});	
	}

	return (
		<EditorPage 

			toolBarDef={toolBarDef} currentEditOption={currentEditOption} 
			editModeActive={editModeActive} tabDefs={tabDefs} 
			selectedTabId={selectedTabId} tabSelectedHandler={tabSelectedHandler}
			underlyingEntityQuery = {alertByIdQuery}
		>	

			{selectedTabId === "form_tab" && 
				<LoadingWrapper 
					queries={[alertByIdQuery, allAlertsQuery, tlmPointLiteQuery, allTlmPacketsQuery]}
					onLoad={() => (
						<AlertFormTab 
							key={formKey}
							updateEntityPromise={updateEntityPromise}
							editModeActive={editModeActive} 
							editModeTransitionHandler={editModeTransitionHandler}
							alert={alert}
							allAlerts={allAlertsQuery}
							metadata={metadataQuery.data[0]}
							tlmPointLite = {tlmPointLiteQuery.data}
							tlmPackets = {allTlmPacketsQuery.data}
							/>
					)}
				/>
			}
			{selectedTabId === "thresholds" && thresholdType == 'non-discrete' &&
				<LoadingWrapper 
					queries={[alertByIdQuery]}
					onLoad={() => (
						<LimitOptionsTab
							key={formKey}
							updateEntityPromise={updateEntityPromise}
							underlyingEntity={alert}
							entityType={EntityType.CmdParam}/>
					)}
				/>
			}
			{selectedTabId === "Discrete" && thresholdType == 'discrete' &&
				<LoadingWrapper 
					queries={[alertByIdQuery]}
					onLoad={() => (
						<DiscreteOptionsTab
							key={formKey}
							updateEntityPromise={updateEntityPromise}
							underlyingEntity={alert}
							entityType={EntityType.CmdParam}/>
					)}
				/>
			}


		</EditorPage>
	);
};

export default AlertPage;
