import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';

import {getBitwiseSchema} from 'model/form-schema/ObjectSchemas';
import BitwisePointFormContent from './BitwisePointFormContent';

export const CreateBitwisePointWizard = ({setCreateVisible, addBitwise, tlmPoint, allTlmBitwise}) => {

	const schema = getBitwiseSchema(allTlmBitwise, null);
	const {useFormObj, controlProps} = useWizardForm({}, schema);

	const createSubmitPromise = async (formData) => {

		let newBitwisePoint = {
			...formData,
			parent_tlm_point: tlmPoint.tlm_point_name,
			depreciated: {
				depreciated_status: false,
				notes: null
			},
			representation_list: [],
			localId: uuidv4()
		};

		addBitwise(newBitwisePoint);

		return newBitwisePoint;
	};

	return (
		<Wizard width='50rem'>
			<WizardForm title="Add Bitwise Point" 
				submitButtonTitle="Add"
				setWizardVisible={setCreateVisible} 
				useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
			>
				<BitwisePointFormContent controlProps={controlProps} />
			</WizardForm>
		</Wizard>
	);
};

export default CreateBitwisePointWizard;
