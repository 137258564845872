import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import style from './ConfirmationModal.module.css';
import MainButton from "components/ui-core/buttons/MainButton/MainButton";
import CloseButton from "components/ui-core/buttons/CloseButton/CloseButton";
import { useASTData } from 'context/ASTContext';
import Modal from 'components/ui-core/modal/Modal/Modal';


export const ConfirmationModal = (props) => {
	const {
		handleSubmit
	} = useForm();

	const { confirmationRequest, setConfirmationRequest, setSubmitInProgress } = useASTData();
	const history = useHistory();	

	if (confirmationRequest) {
		const {
			item, 
			asyncCallbackFn,
			title, 
			actionButtonTitle,
			bodyContent, 
			navPathOnConfirm, 
			successMessage,
			cancelButtonTitle,
			asyncCancelFn
		} = confirmationRequest;

		const onSubmit = async () => {

			setSubmitInProgress(true);
			// Set the confirmation request to null here BEFORE the "work" callback function is
			// run in case it in turn raises new confirmation requests
			setConfirmationRequest(null);

			await asyncCallbackFn(item)
				.then((data) => {
					setSubmitInProgress(false);
					successMessage && toast.success(successMessage);			
					if (navPathOnConfirm) { 
						history.push(navPathOnConfirm);
					}

					return data;
				})
				.catch(() => {
					setSubmitInProgress(false);
				});
		};

		const onCancel = async () => {
			if(asyncCancelFn !== null) {
				await asyncCancelFn().then(() => {
					setConfirmationRequest(null);
				})
			}
			else {
				setConfirmationRequest(null);
			}
		}
			
		return (
			<Modal>
				<form onSubmit={handleSubmit(onSubmit)} {...props} className={style.notificationModal}>
					{/* Header */}
					<div className={style.notificationModalRowHeading}>
						<div>
							<h1>{title}</h1>
						</div>
						<div>
							<div onClick={() => setConfirmationRequest(null)}>
								<CloseButton />
							</div>
						</div>
					</div>
	
					{/* Message */}
					<div className={style.notificationModalRow}>
						<div>
							{typeof bodyContent === "string" && <p>{bodyContent}</p>}
							{typeof bodyContent !== "string" && <>{bodyContent}</>}
						</div>
					</div>
	
					{/* Button Panel */}
					<div className={style.notificationModalSubmit}>
						<MainButton title={cancelButtonTitle} width={'10.5rem'} height={'3rem'} 
							onClick={() => onCancel()} />
						<MainButton title={actionButtonTitle} width={'10.5rem'} height={'3rem'} 
							onSubmit={handleSubmit(onSubmit)} />
					</div>
				</form>				
			</Modal>
		);	
	} else {
		return null;
	}
};

export default ConfirmationModal;
