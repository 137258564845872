import React from 'react';
import style from './Option.module.css';

import { option_styles } from './OptionHelper';
export const Severity = ({ severity }) => {
	
	return (
		<>
			<div className={style.optionContainer}>
				<div style={option_styles[severity]} className={style.severity}>
					{severity}
				</div>
			</div>
		</>
	);
};

export default Severity;
