import {useState} from 'react'
import EditModeTransition from 'model/EditModeTransition';
import { usePageState } from 'components/ui-core/page/usePageState';

export const useEditorPage = (tabDefs) => {
	const {getTabId, setTabId} = usePageState();

	if (!getTabId()) {
		setTabId(tabDefs[0].id);
	}
	
	const [currentEditOption, setCurrentEditOption] = useState(tabDefs[0]['editMode']);
	const [selectedTabId, setSelectedTabId] = useState(getTabId());
	
	const [editModeActive, setEditModeActive] = useState(false);
	const [formKey, setFormKey] = useState(1);

	const tabSelectedHandler = (newTabId) => {
		if (selectedTabId !== newTabId) {
			console.log(`-- SETTING TAB: ${newTabId}`)
			
			setTabId(newTabId)
			setSelectedTabId(newTabId);
			setEditModeActive(false);
			setCurrentEditOption(tabDefs.find(tabDef => tabDef.id === newTabId).editMode);
		}
	}

	const editModeTransitionHandler = (editModeTransition) => {
		console.log(`Setting Edit or Build Mode Active (editModeTransitionHandler) ${editModeTransition.name}`)

		setEditModeActive(editModeTransition.resultantEditMode);
		
		// used On cancel - change the form key to for a remount of a fresh form component 
		if(editModeTransition === EditModeTransition.Cancel) {
			setFormKey(formKey + 1);
		}
	}

    return {
        currentEditOption,
        selectedTabId,
        editModeActive,
        tabSelectedHandler,
        editModeTransitionHandler,
		formKey
    };
}

export default useEditorPage;