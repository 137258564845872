
import {FormRow, Input, Select} from 'components/ui-core/form/FormControls';
import { getSnapShotTestStatusOptions } from 'components/ui-core/utils/MetadataHelper';
import style from "../../../ui-core/form/Form.module.css"



export const SnapShotFormContent = ({controlProps}) => {

	return (
		<>
			<FormRow rowNum="1">
				<Input style={{background: 'rgba(241, 235, 235, 0.5)'}} readOnly={true} title="Version Name" attr="version" {...controlProps}/>
			</FormRow>
			<FormRow rowNum="2">
			<Select title="Test Status" attr="test_status" {...controlProps}
					valueOptions={getSnapShotTestStatusOptions()} />
			</FormRow>
			<FormRow rowNum="3">
				<Input title="Comments" attr="comments" {...controlProps}/>
			</FormRow>
			<FormRow rowNum="3">
				<Input style={{background: 'rgba(241, 235, 235, 0.5)'}} readOnly={true} title="Created Date" attr="created_date" {...controlProps}/>
			</FormRow>
		</>

	);
};

export default SnapShotFormContent;
