import React from 'react';
import PropTypes from 'prop-types';
import style from './TableBarButton.module.css';

export const TableBarButton = ({ title, width, ...props }) => {
	return (
		<>
			<button {...props} style={{ width: width }} className={style.tableBarButton}>
				{title}
			</button>
		</>
	);
};

TableBarButton.propTypes = {
	title: PropTypes.string.isRequired,
	width: PropTypes.string.isRequired,
};

TableBarButton.defaultProps = {
	width: '11.5rem',
};

export default TableBarButton;
