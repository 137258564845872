// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modalContainer__UjmPp {
	background: rgba(101, 101, 101, 0.9);
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	overflow: auto; /* Enable scroll if needed */
	z-index: 2;
}

.Modal_modalContent__71vQB {
	padding-bottom: 9.5rem;
	padding-right: 9.5rem;

}`, "",{"version":3,"sources":["webpack://./src/components/ui-core/modal/Modal/Modal.module.css"],"names":[],"mappings":"AAAA;CACC,oCAAoC;CACpC,eAAe;CACf,MAAM;CACN,OAAO;CACP,YAAY;CACZ,WAAW;CACX,aAAa;CACb,qBAAqB;CACrB,mBAAmB;CACnB,uBAAuB;CACvB,cAAc,EAAE,4BAA4B;CAC5C,UAAU;AACX;;AAEA;CACC,sBAAsB;CACtB,qBAAqB;;AAEtB","sourcesContent":[".modalContainer {\n\tbackground: rgba(101, 101, 101, 0.9);\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\theight: 100%;\n\twidth: 100%;\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n\talign-items: center;\n\tjustify-content: center;\n\toverflow: auto; /* Enable scroll if needed */\n\tz-index: 2;\n}\n\n.modalContent {\n\tpadding-bottom: 9.5rem;\n\tpadding-right: 9.5rem;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `Modal_modalContainer__UjmPp`,
	"modalContent": `Modal_modalContent__71vQB`
};
export default ___CSS_LOADER_EXPORT___;
