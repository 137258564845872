import React from 'react';
import SideBar from 'components/ui-core/layout/SideBar/SideBar';
import Header from '../components/ui-core/layout/Header/Header';
import style from './NotFound.module.css';

const NotFound = () => {
	return (
		<>
			<div className={style.container}>
				<SideBar/>
				<main>
					<Header title={'404 Page Not Found :('} />
					<div className={style.pageNotFound}>
						<h1>404 Page Not Found :(</h1>
					</div>
				</main>
			</div>
		</>
	);
};

export default NotFound;
