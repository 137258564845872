import React from 'react';
import style from './Tag.module.css';

import { getColorStyleObject, getTagIconColor } from 'components/tags/TagHelper';

export const RemoveTagButton = ({ title, onClick, disabled=false,...props }) => {
	const tagStyle = getColorStyleObject(title, "background");
	const tagIconColor = getTagIconColor(disabled);
	
	return (
		<button onClick={onClick} className={style.addTagButton} disabled={disabled}>
	 			<div style={tagStyle}  {...props} className={style.tag}>

					<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
						<path 
							d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8z" 
							fill={tagIconColor} />
					</svg>
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<path
							id="MdPricetag"
							d="M91.485,64H79.111L64.963,78.66a3.318,3.318,0,0,0,0,4.7l9.664,9.659a3.586,3.586,0,0,0,2.25.977,3.917,3.917,0,0,0,2.453-.977L93.985,79V66.5Zm-2.4,8.026a2.5,2.5,0,1,1,1.672-1.672A2.433,2.433,0,0,1,89.087,72.026Z"
							transform="translate(-63.985 -64)"
							fill={tagIconColor} />
					</svg>
					<p>{title}</p>
	 			</div>
		</button>
	);
};

export default RemoveTagButton;
