


export const assignOrderAndStartNumbers = (arr) => {
    let order = 1;
    let start_bit = 0;
    
    arr.forEach(entry => {
        entry["sequence_num"] = order++
        entry["start_bit"] = start_bit;
        entry["endBoundary"] = ((entry["start_bit"] + entry["size_bits"]) % 8 === 0);
        
        //set up the next start bit number
        start_bit += entry.size_bits; 
    });

}

export const getExpectedBits = (initialTlmPoint) => initialTlmPoint.size_bytes * 8  

export const calculateBitTotals = (initialTlmPoint, bitwiseTableData) => {
    const expectedBits = getExpectedBits(initialTlmPoint);
    const actualBits =  bitwiseTableData.reduce(
        (accumulator, bitwiseRow) => accumulator + bitwiseRow.size_bits,
        0
    );

    const userDefinedBits =  bitwiseTableData
        .filter(bitwiseRow => !isSystemManagedFiller(bitwiseRow))
        .reduce(
            (accumulator, bitwiseRow) => accumulator + bitwiseRow.size_bits,
            0
        );

    const systemFillerBits = actualBits - userDefinedBits;
    return {expectedBits, actualBits, userDefinedBits, systemFillerBits};
}



export const getBitwiseSummaryItems = (initialTlmPoint, bitwiseTableData) => {
    const {userDefinedBits, systemFillerBits}  = calculateBitTotals(initialTlmPoint, bitwiseTableData);

    return [
        {
            title: "User-Defined Bits",
            value: userDefinedBits
        }, 
        {
            title: "System Filler Bits",
            value: systemFillerBits
        }
    ]
}

export const isSystemManagedFiller = (bitwise) => {
    return bitwise.tlm_bitwise_name.startsWith("BIT_FILLER_")
}