import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from 'img/add-icon.svg';
import style from './CreateButton.module.css';

export const CreateButton = ({ title, width, additionalStyles = {}, ...props }) => {
	const styles = { width: width, ...additionalStyles}

	return (
		<>
			<button {...props} style={styles} className={style.createButton}>
				<img className={style.createIcon} src={AddIcon} alt="Create icon" />
				{title}
			</button>
		</>
	);
};

CreateButton.propTypes = {
	title: PropTypes.string.isRequired,
	width: PropTypes.string.isRequired,
};

CreateButton.defaultProps = {
	title: 'Tag',
	width: '11.5rem',
};

export default CreateButton;
