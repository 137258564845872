import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';

import {getTlmPointSchema} from 'model/form-schema/ObjectSchemas'
import TelemetryPointFormContent from 'components/tlm-point/point-page/TelemetryPointFormContent'
import {useCreateTelemetryPoint} from 'data/queryHooks';
import createTlmPointFromForm from '../TelemetryPointFormHelper';

export const CreateTelemetryPointWizard = ({setCreateVisible, allPoints, metadata}) => {

	const schema = getTlmPointSchema(allPoints, null);
	const {useFormObj, controlProps} = useWizardForm({limit: false}, schema);
	const  {mutateAsync: addTelemetryPoint} = useCreateTelemetryPoint();
	
	const createSubmitPromise = async (formData) => {
		
		const newTlmPoint = {
			...createTlmPointFromForm(formData, useFormObj, metadata),
			time_created: dayjs(new Date()).toISOString(),
			bitwise_list: [],
			environment_tags: [],
			depreciated: {
				depreciated_status: false,
				notes: null
			}			
		}

		return await addTelemetryPoint(newTlmPoint)
			.then(response => {
				toast.success(`Telemetry Point '${newTlmPoint.tlm_point_name}' created`);				
				return response;
			})
			.catch(error => {
				toast.error(`Telemetry Point creation failed: ${error.message}`);
				return Promise.reject(error)
			});
	};



	return (
		<Wizard width='75rem'>
			<WizardForm title="Create Telemetry Point" getObjectbyIdRoutePrefix='/telemetry-point'
				setWizardVisible={setCreateVisible} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
			>
				<TelemetryPointFormContent 
					controlProps={controlProps} 
					metadata={metadata}
          			useFormObj={useFormObj}
					isWizard={true}
					allPoints={allPoints}
					isByteReadOnly={false}
				/>
			</WizardForm>
		</Wizard>
	);
};

export default CreateTelemetryPointWizard;
