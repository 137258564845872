
import {FormRow, Input, Select} from 'components/ui-core/form/FormControls';
import { option_severities, option_styles } from './OptionHelper';
import { TLM_RELATED_TYPES } from 'model/EntityType';

export const OptionFormContent = ({controlProps, entityType}) => {

	return (
		<>
			<FormRow rowNum="1">
				<Input title="Name" attr="name" {...controlProps}/>
			</FormRow>
			
			<FormRow rowNum="2">
				<Input title="Value" attr="value" {...controlProps}/>
			</FormRow>
			
			{TLM_RELATED_TYPES.includes(entityType) &&
				<FormRow rowNum="3">
					<Select title="Severity" attr="severity" {...controlProps}
						valueOptions={option_severities}
						valueStyles={option_styles}
						includeBlank={true} />
				</FormRow>
			}
		</>

	);
};

export default OptionFormContent;
