// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.SubmittablePage_submittingWrapper__6HCgb {
	/* pointer-events: none; */
	opacity: 0.7;
	cursor: progress !important;
	background: gray;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 5;
}

.SubmittablePage_submittingWrapper__6HCgb:hover {
	cursor: progress !important;
}

.SubmittablePage_submitting__6M3FV {
	pointer-events: none;
}

.SubmittablePage_submitableContainer__lgWPb {
	position: relative;
}

.SubmittablePage_childLayer__94mbE {
	position: fixed;
    width: 100%;
    height: 100%;
}




`, "",{"version":3,"sources":["webpack://./src/components/ui-core/page/SubmittablePage.module.css"],"names":[],"mappings":";;AAEA;CACC,0BAA0B;CAC1B,YAAY;CACZ,2BAA2B;CAC3B,gBAAgB;CAChB,eAAe;CACf,WAAW;CACX,YAAY;CACZ,UAAU;AACX;;AAEA;CACC,2BAA2B;AAC5B;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,eAAe;IACZ,WAAW;IACX,YAAY;AAChB","sourcesContent":["\n\n.submittingWrapper {\n\t/* pointer-events: none; */\n\topacity: 0.7;\n\tcursor: progress !important;\n\tbackground: gray;\n\tposition: fixed;\n\twidth: 100%;\n\theight: 100%;\n\tz-index: 5;\n}\n\n.submittingWrapper:hover {\n\tcursor: progress !important;\n}\n\n.submitting {\n\tpointer-events: none;\n}\n\n.submitableContainer {\n\tposition: relative;\n}\n\n.childLayer {\n\tposition: fixed;\n    width: 100%;\n    height: 100%;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submittingWrapper": `SubmittablePage_submittingWrapper__6HCgb`,
	"submitting": `SubmittablePage_submitting__6M3FV`,
	"submitableContainer": `SubmittablePage_submitableContainer__lgWPb`,
	"childLayer": `SubmittablePage_childLayer__94mbE`
};
export default ___CSS_LOADER_EXPORT___;
