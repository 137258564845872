import React, {useState} from 'react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import CreateUnitWizard from 'components/units/CreateUnitWizard'
import EditUnitModal from 'components/units/EditUnitModal'

import Modal from 'components/ui-core/modal/Modal/Modal';

import TableEditButton from 'components/ui-core/buttons/TableEditButton/TableEditButton';
import TableDeleteButton from 'components/ui-core/buttons/TableDeleteButton/TableDeleteButton';

import { useASTData } from 'context/ASTContext';
import { useMetadata, useSaveMetadata} from 'data/queryHooks';

import 'animate.css';
import style from 'App.module.css';

import SideBarOption from 'model/SideBarOption';
import ConfirmationRequest from 'model/ConfirmationRequest';

import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import MsdQueryTable from 'components/ui-core/table/msdTable/MsdQueryTable'
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';
import useSubmitWithWarnings from 'components/ui-core/auth/ProdWriteWarning'

const UnitsTablePage = () => {
	const [createVisible, setCreateVisible] = useState(false);
	const [editUnit, setEditUnit] = useState(null);	
	const {setConfirmationRequest} = useASTData();

	const {setSideBarOption, setHeaderTitle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.Units);
		setHeaderTitle("Units");		
		localStorage.setItem('lastPage',"/units");
	}, []);

	
	const metadataQuery = useMetadata();
	const extractMetadataFromQuery = () => {
		return metadataQuery.data[0].units;
	}

	const requestDelConfirmationHandler = (unitRow) => {
		let deleteRequest =  new ConfirmationRequest(
			unitRow,
			async () => performUnitDeletionHandler(unitRow),
			`Delete '${unitRow.units_long}'`, 
			"Delete",
			"Are you sure you would like continue?",
			null,
			`Unit '${unitRow.units_long}' deleted`,
		);
	
		setConfirmationRequest(deleteRequest);
	}
    const {submitWithAppropriateWarnings} = useSubmitWithWarnings(requestDelConfirmationHandler)

	const columns = React.useMemo(
		() => [
			{
				Header: 'Short Name',
				accessor: 'units_short',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Long Name',
				accessor: 'units_long',
				Filter: TextInputColumnFilter
			},
			{
				Header: ' ',
				accessor: (unitRow) => {
					return (
						<div className={style.tableButtonContainer}>
							<TableEditButton editHandler={() => setEditUnit(unitRow)}/>
							<TableDeleteButton deletionHandler={() => submitWithAppropriateWarnings(unitRow)}/>
						</div>
					);
				},
				columnType: "buttons"
			},
		],
		[submitWithAppropriateWarnings]
	);

	const  {mutateAsync: updateMetadata} = useSaveMetadata();
	const performUnitDeletionHandler = async (unitToDelete) => {
		const metadataClone = cloneDeep(metadataQuery.data[0])

		metadataClone.units = metadataClone.units.filter(unit => unit.units_short !== unitToDelete.units_short);

		return await updateMetadata(metadataClone).then((response) => {
			toast.success(`Unit '${unitToDelete.units_long}' deleted`);

			return response;
		})
	};


	return (
		<MsdMainPage>
			{createVisible && 
				<LoadingWrapper 
					queries={[metadataQuery]}
					onLoad={() => (
						<Modal>
							<CreateUnitWizard 
								setCreateVisible={setCreateVisible}
								metadata={metadataQuery.data[0]}
							/>
						</Modal> 
					)}				
				/>
			}

			{editUnit && 
				<LoadingWrapper 
					queries={[metadataQuery]}
					onLoad={() => (
						<Modal>
							<EditUnitModal 
								setEditUnit={setEditUnit}
								editUnit={editUnit}
								metadata={metadataQuery.data[0]}
							/>
						</Modal> 
					)}				
				/>
			}

			<MsdQueryTable tableName="UnitsTablePage"
					columns={columns} query={metadataQuery} dataExtractFn={extractMetadataFromQuery}
			>
				<CreateButton
					width="6rem"
					title="Unit"
					onClick={() => {
						setCreateVisible(true);
					}}
				/>		
			</MsdQueryTable>
		</MsdMainPage>
	);
}; 

export default UnitsTablePage;
