
import {FormRow, Input, TextArea} from 'components/ui-core/form/FormControls';
import { getAdjustedDisabledControlProps } from 'components/ui-core/form/FormUtils';

export const TagFormContent = ({controlProps, protectedTag = false}) => {

	return (
		<>
			<FormRow rowNum="1">
				<Input title="Tag Name" attr="name" 
					{...getAdjustedDisabledControlProps(controlProps, protectedTag)}/>
			</FormRow>
			<FormRow rowNum="2">
				<TextArea title="Description" attr="description" {...controlProps}/>
			</FormRow>
		</>

	);
};

export default TagFormContent;
