import React, {useState} from 'react';

import 'animate.css';

import {getCreateLimitOptionsSchema} from 'model/form-schema/ObjectSchemas'
import Modal from 'components/ui-core/modal/Modal/Modal';
import EditOptionValueModal from './EditOptionValueModal';

import { useASTData } from 'context/ASTContext';
import ConfirmationRequest from 'model/ConfirmationRequest';

import { transformOption } from './OptionHelper';
import CreateLimitOptionWizard from './CreateLimitOptionWizard';
import LimitOptionsTable from './LimitOptionsTable';

export const LimitOptionsTab = ({updateEntityPromise, underlyingEntity, entityType}) => {
	const [createVisible, setCreateVisible] = useState(false);
	const [editOptionValue, setEditOptionValue] = useState(null);	

	const {setConfirmationRequest} = useASTData();
	const optionsAttrName = entityType.optionsAttrName
	const [optionsData, setOptionsData] = useState([]);
	const performThresholdDeletionHandler = async (rowToDelete) => {
		underlyingEntity['thresholds'] = 
			underlyingEntity['thresholds']
				.filter(ele => ele.sc_mode != rowToDelete.sc_mode);
		await updateEntityPromise(underlyingEntity);
	};
	
	const requestDelConfirmationHandler = (optionToDelete) => {
		let deleteRequest =  new ConfirmationRequest(
			optionToDelete,
			performThresholdDeletionHandler,
			`Delete Threshold '${optionToDelete.spacecraft_mode}'`, 
			"Delete",
			"Are you sure you want to delete this Threshold?",
			null,
			`Threshold '${optionToDelete.spacecraft_mode}' deleted`, 
		);
	
		setConfirmationRequest(deleteRequest);
	}

	React.useEffect(() => {
		if(underlyingEntity["thresholds"] != null){
		setOptionsData(underlyingEntity["thresholds"]);
		}
	}, [underlyingEntity["thresholds"]]);
	
	
	const createAddOptionPromise = async (formData) => {
		const adjustedFormData = transformOption(formData);
		const newAlertDiscreteThreshold = {
			  sc_mode: adjustedFormData.sc_mode,
			  red_low: adjustedFormData.red_low,
			  yellow_low: adjustedFormData.yellow_low,
			  yellow_high: adjustedFormData.yellow_high,
			  red_high: adjustedFormData.red_high,
		  };		
		underlyingEntity['thresholds'].push(newAlertDiscreteThreshold);
		return updateEntityPromise(underlyingEntity);
	};
		
	const createEditOptionPromise = async (formData) => {
		console.log(formData, editOptionValue.sc_mode);
		const adjustedFormData = transformOption(formData);
		let editDiscrete = 
		underlyingEntity['thresholds']
		.find(ele => ele['sc_mode'] == editOptionValue.sc_mode);
		editOptionValue.red_low = adjustedFormData.red_low;
		editOptionValue.yellow_low = adjustedFormData.yellow_low;
		editOptionValue.red_high = adjustedFormData.red_high;
		editOptionValue.yellow_high = adjustedFormData.yellow_high;
		editDiscrete = editOptionValue;
		return updateEntityPromise(underlyingEntity);
	};

	const createOptionSchema = () => getCreateLimitOptionsSchema();

	return (
		<>
			{createVisible && 
				<Modal>
					<CreateLimitOptionWizard
						createAddOptionPromise={createAddOptionPromise}
						schema={createOptionSchema()}
						setCreateVisible={setCreateVisible}
						entityType={entityType}
						optionsData={optionsData}
						optionsType={"NonDiscrete"}
					/>
				</Modal> 
			}

			{editOptionValue && 
				<Modal>
					<EditOptionValueModal
						createEditOptionPromise={createEditOptionPromise}
						editOptionValue={editOptionValue}
						setEditOptionValue={setEditOptionValue}
						entityType={entityType}
						optionsData={optionsData}
						optionsType={"NonDiscrete"}
					/>
				</Modal> 
			}		

			<LimitOptionsTable 
				optionsData={optionsData}
				setCreateVisible={setCreateVisible}
				setEditOptionValue={setEditOptionValue}
				underlyingEntity={underlyingEntity}
				handleDelete={requestDelConfirmationHandler}
				optionsType={"NonDiscrete"}
			/>			
		</>

	);
};

export default LimitOptionsTab;
