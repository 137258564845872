// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ASTLoginButton_container__zs73k {
    text-align: center;
}

.ASTLoginButton_authText__1faDo {
    font-size: 1.25rem;
    color: var(--ast-light-color);
    margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/login/ASTLoginButton/ASTLoginButton.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,mBAAmB;AACvB","sourcesContent":[".container {\n    text-align: center;\n}\n\n.authText {\n    font-size: 1.25rem;\n    color: var(--ast-light-color);\n    margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ASTLoginButton_container__zs73k`,
	"authText": `ASTLoginButton_authText__1faDo`
};
export default ___CSS_LOADER_EXPORT___;
