import React  from 'react';
import { useHistory } from 'react-router-dom';

import { useASTData } from 'context/ASTContext';
import {useBitwise, useTelemetryPackets, useTelemetryPoints, useTelemetryPointsOptimized} from 'data/queryHooks';

import 'animate.css';
import style from 'App.module.css';

import SideBarOption from 'model/SideBarOption';

import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import { getSubsystemArrColumn, decorateWithSubsystems, compareSubsystemThenName } from 'components/ui-core/utils/SubsystemUtils';

import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable';
import EntityType from 'model/EntityType';
import { getOptionsColumn } from 'components/options/OptionHelper';
import { PageState } from 'components/ui-core/page/usePageState';

const AllBitwiseTablePage = () => {
	const {setSideBarOption, setHeaderTitle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.Bitwise);
		setHeaderTitle("Bitwise Points");		
		localStorage.setItem('lastPage',"/bitwise-points");
	}, []);	

	const history = useHistory();
	const viewTlmPointHandler = (bitwise) => {
		const parentTlmPoint = getParentTlmPoint(bitwise)
		history.push(`/telemetry-point/${parentTlmPoint._id}`, new PageState("bitwise_tlm_points"));
	};

	const allBitwiseQuery = useBitwise();
	const tlmPacketsQuery = useTelemetryPackets();
	const tlmPointsQuery = useTelemetryPointsOptimized();


	const columns = React.useMemo(
		() => [
			getSubsystemArrColumn(),
			{
				Header: "Telemetry Point",
				accessor: "tlm_point_display_name",
				Filter: TextInputColumnFilter
		  	},
			{
				Header: "Sequence No.",
				accessor: "sequence_num"
		  	},
			{
				Header: "Bitwise Point Name",
				accessor: "tlm_bitwise_name",
				Filter: TextInputColumnFilter
			},
		  	{
				Header: "Description",
				accessor: "description",
				Filter: TextInputColumnFilter
		  	},
		  	{
				Header: "Bit Size",
				accessor: "size_bits"
		  	},
			{
				Header: "Start bit",
				accessor: "absolute_bit_location"
		  	},
			getOptionsColumn(EntityType.BitwisePoint),
			{
				Header: ' ',
				accessor: (bitwiseRow) => {
					return !bitwiseRow.orphaned
						? (
							<div>
								<button className={style.tableTextButton} 
									onClick={() => viewTlmPointHandler(bitwiseRow)}
								>
									View
								</button>
							</div>
						) 
						: <></>;
				},
				Filter: false,
			},
		],
		[tlmPointsQuery.data]
	);

	const getParentTlmPoint = (bitwise) => {
		return tlmPointsQuery.data.find(
			tlmPoint => tlmPoint.tlm_point_name === bitwise.parent_tlm_point
		)
	}


	const createRows = () => {
		return allBitwiseQuery.data
			.map(bitwise => {
				const parentTlmPoint = getParentTlmPoint(bitwise)
				
				// If orpaned flag this to the user and include state to this effect so that the 
				// View button can be hidden
				const bitwiseRow = {
					...bitwise,
					tlm_point_display_name: bitwise.parent_tlm_point + (!parentTlmPoint ? " (Missing/Orphaned)" : ""),
					orphaned: !parentTlmPoint ,
				} 				

				return decorateWithSubsystems(
					bitwiseRow, parentTlmPoint, tlmPacketsQuery.data, EntityType.TlmPacket)
			})
			.sort((a, b) => {
				const subsystemThenNameCompare = compareSubsystemThenName(a, b, "parent_tlm_point");
				if (subsystemThenNameCompare !== 0) {
					return subsystemThenNameCompare;
				}

				return a["sequence_num"] - b["sequence_num"]; 
			})
	}

	return (
		<MsdMainPage>
			
			<LoadingWrapper 
				queries={[allBitwiseQuery, tlmPacketsQuery, tlmPointsQuery]}
				onLoad={() => (
					<ManagedMsdTable tableName="AllBitwiseTablePage" columns={columns} data={createRows()} />
				)}
	        />
		</MsdMainPage>
	);	

};

export default AllBitwiseTablePage;
