import React from 'react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import {getBoardSchema, getUnitSchema} from 'model/form-schema/ObjectSchemas'
import {useSaveMetadata} from 'data/queryHooks';
import BoardFormContent from './BoardFormContent';

export const CreateBoardWizard = ({ setCreateVisible,  metadata, userName }) => {
	const metadataClone = cloneDeep(metadata)

	const schema = getBoardSchema(metadata.boards? metadata.boards : [], null);
	const {useFormObj, controlProps} = useWizardForm({}, schema);
	const  {mutateAsync: updateMetadata} = useSaveMetadata();

	const createSubmitPromise = async (formData) => {
		
		let newBoard = {
			...formData, 
			modified_by: userName,
			modified_date: new Date().toISOString(),
		}

		metadataClone["boards"]? metadataClone["boards"].push(newBoard): metadataClone["boards"] = [newBoard];

		return await updateMetadata(metadataClone).then((response) => {
			toast.success(`Board '${newBoard.name}' created`);

			return response;
		})			
	};


	return (
		<Wizard width='40rem'>
			<WizardForm title="Add New Board" 
				setWizardVisible={setCreateVisible} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
			>
				<BoardFormContent controlProps={controlProps} metadata={metadata} />
			</WizardForm>
		</Wizard>		
	);
};


export default CreateBoardWizard;
