
import React from 'react';
import { useHistory } from 'react-router-dom';

import style from './EditorToolBar.module.css';

import MainButton from "components/ui-core/buttons/MainButton/MainButton";
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import EditorActionButtons from './EditorActionButtons';

export const EditorToolBar = ({ toolBarDef, currentEditOption, selectedTabId, editModeActive, underlyingEntityQuery}) => {

	const history = useHistory();
	
	const showToolbar = toolBarDef.navButtons.length > 0 
		|| toolBarDef.manipulationButtons.mutationButtons.includeEdit 
		|| toolBarDef.manipulationButtons.mutationButtons.includeBuildMode 
		|| toolBarDef.manipulationButtons.deleteButton;

	const navButtonsJsx = () => {
		return (
			<> 
			{
				toolBarDef.navButtons.map((buttonDef) => (
					<MainButton disabled={editModeActive}
						key={buttonDef.title}
						title={buttonDef.title} 
						width={buttonDef.width} 
						onClick={() => {
							if (!editModeActive) {
								history.goBack();
							}
						}} />
				))
			}
			</>
		);
	}

	return (
		<>
			{showToolbar ?
				<div className={style.toolbarContainer}>
					{navButtonsJsx()}

					<LoadingWrapper 
						queries={underlyingEntityQuery ? [underlyingEntityQuery] : []}
						displayIndicator={false}
						onLoad={() => (
							<EditorActionButtons 
								underlyingEntity={underlyingEntityQuery ? underlyingEntityQuery.data : null}
								currentEditOption={currentEditOption}
								selectedTabId={selectedTabId}
								editModeActive={editModeActive}
								toolBarDef={toolBarDef}/> 
						)}
					/>
				</div>
				: <></>
			}
		</>
	);
};


export default EditorToolBar;
