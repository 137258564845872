import React from 'react';
import {usePageState} from 'components/ui-core/page/usePageState';

const UNUSED = "(Unused)"

export const SubsystemArrayInputColumnFilter = ({ column: { id, filterValue, setFilter, preFilteredRows }, tableName }) => {
	// Calculate the options for filtering using the preFilteredRows
	const options = React.useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach((row) => {

			if (row.original.subsystems.length > 0) {
				row.original.subsystems
					.forEach(subsystem => {
						options.add(subsystem)
					}
				)
			} else {
				options.add(UNUSED)
			}


		});
		return [...options.values()].sort();
	}, [preFilteredRows]);

    const {setFilterValue} = usePageState();

	// Render a multi-select box
	return (
		<select
            style={{ width:'14rem', marginRight: '2rem' }}
			value={filterValue}
			onChange={(e) => {
				const newFilterValue = e.target.value || undefined
				setFilterValue(tableName, id, newFilterValue)
				setFilter(newFilterValue);
			}}
		>
			<option value="">Select a value</option>
			{options.map((option, i) => (
				<option key={i} value={option}>
					{option}
				</option>
			))}
		</select>
	);
}


export const getSubsystemStr = (subsystems) => {
	if (subsystems.length === 0)  {
        return UNUSED
    } else if (subsystems.length > 1) {
        return `Multiple (${subsystems.length})`
    } else {
        return subsystems[0]
    }
}  

const isUnused = row => row.original.subsystems.length === 0

export const filterSubsystems = (rows, columnId, filterValue) => {

	let filterFn = filterValue === UNUSED 
		? isUnused : 
		row => row.original.subsystems.includes(filterValue);

	return rows.filter(filterFn);
}

export const getSubsystemArrColumn = (header_name="Subsystem(s)") => {
    return {
        id: 'subsystems',
        Header: header_name,
        Cell: (tableInstance) => {
            const {row} = tableInstance;
			const subsystemsStr = getSubsystemStr(row.original.subsystems)

            return (
                <>{subsystemsStr}</>
            );
        },
        Filter: SubsystemArrayInputColumnFilter,
        filter: filterSubsystems
    }
}

export const decorateWithSubsystems = (objectToDecorate, packetChild, allPackets, packetType) => {
    const childType = packetType.child_entity_type;
    let uniqueSubsystems = [];

    const subsystems = allPackets
        .filter(packet => {
            return packetChild && packet[packetType.childAssocListName].includes(packetChild[childType.nameAttr])
        })
        .map(usingPacket => {
            return usingPacket.subsystem_name
        })

    uniqueSubsystems = Array.from(new Set(subsystems)).sort()

    return {
        ...objectToDecorate,
        "subsystems": uniqueSubsystems
    }
}

export const compareSubsystemThenName = (a, b, nameAttr) => {
    const subsystemStrA = getSubsystemStr(a["subsystems"])
    const subsystemStrB = getSubsystemStr(b["subsystems"])

    const subsystemCompare = subsystemStrA.localeCompare(subsystemStrB);
    if (subsystemCompare !== 0) {
        return subsystemCompare;
    }

    const nameCompare = a[nameAttr].localeCompare(b[nameAttr]);
    return nameCompare;
}

export const getSubsystemDecoratedChildRows = (children, packets, packetType) => {
    console.time('Timer');
    const childType = packetType.child_entity_type;
    const result =  children.map(child => {
        return decorateWithSubsystems(child, child, packets, packetType)
    })
    .sort((a, b) => {
        return compareSubsystemThenName(a, b, childType.nameAttr); 
    });
    console.timeEnd('Timer');
    return result;
}
