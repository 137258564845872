import React from 'react';

import EditAssociations from 'components/ui-core/page/assoc/EditAssociations'
import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';

import { getTagColumn } from 'components/ui-core/table/TableHelper';
import EntityType from 'model/EntityType';

export const CmdPacketAssocTabEditMode = ({ editModeTransitionHandler, updateEntityPromise,
	cmdPacket, allParams}) => {
	
	const lhsColumns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'cmd_param_name',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Type',
				accessor: 'type',
			},
			{
				Header: 'Byte Size',
				accessor: 'size_bytes'
			},
			{
				Header: 'Units',
				accessor: 'units_long'
			},
			getTagColumn(),
		],
		[]
	);

	const rhsColumns = React.useMemo(
		() => [
			{
				Header: 'Seq. No',
				accessor: 'seq_no'			},
			{
				Header: 'Name',
				accessor: 'cmd_param_name',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Type',
				accessor: 'type',
			},
			{
				Header: 'Byte Size',
				accessor: 'size_bytes'
			},
			{
				Header: 'Units',
				accessor: 'units_long'
			},
			getTagColumn(),
		],
		[]
	);


	return (
		<EditAssociations 
			allObjects={allParams}
			packetEntityType={EntityType.CmdPacket}
			lhsColumns={lhsColumns}
			rhsColumns={rhsColumns}
			editModeTransitionHandler={editModeTransitionHandler}
			underlyingEntity={cmdPacket}
			updateEntityPromise={updateEntityPromise}
		/>
	);
};

export default CmdPacketAssocTabEditMode;
