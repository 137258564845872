import React from 'react';
import PropTypes from 'prop-types';
import style from './Tag.module.css';

import { getColorStyleObject } from 'components/tags/TagHelper';

export const Tag = ({ title, ...props }) => {
	const tagStyle = getColorStyleObject(title, "background");
	
	return (
		<>
			<div className={style.tagContainer}>
				<div style={tagStyle}  {...props} className={style.tag}>
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<path
							id="MdPricetag"
							d="M91.485,64H79.111L64.963,78.66a3.318,3.318,0,0,0,0,4.7l9.664,9.659a3.586,3.586,0,0,0,2.25.977,3.917,3.917,0,0,0,2.453-.977L93.985,79V66.5Zm-2.4,8.026a2.5,2.5,0,1,1,1.672-1.672A2.433,2.433,0,0,1,89.087,72.026Z"
							transform="translate(-63.985 -64)"
							fill="#282828"
						/>
					</svg>
					<p>{title}</p>
				</div>
			</div>
		</>
	);
};

Tag.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Tag;
