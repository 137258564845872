
import {FormRow, Input, Select} from 'components/ui-core/form/FormControls';
import { option_severities, option_styles } from './OptionHelper';
import { TLM_RELATED_TYPES } from 'model/EntityType';
import { useBranchData } from 'components/ui-core/layout/Header/BranchContext';

export const LimitOptionFormContent = ({controlProps, entityType, optionsType, formType, optionsData, entryType}) => {


	const {metadataDb} = useBranchData();
	let typeOptions = metadataDb.length != 0 ? metadataDb['sc_modes']: [];
	const selectedModes = optionsData.map(ele => ele['sc_mode'].toUpperCase());
	typeOptions = typeOptions?  typeOptions.filter(ele => !selectedModes.includes(ele)) : [];


	return (
		<>
		{optionsType=='NonDiscrete' && 
		<>
		{ entryType == "Create" &&
			<FormRow rowNum="1">
				<Select title="Spacecraft Mode" attr="sc_mode" {...controlProps} valueOptions={typeOptions} />
			</FormRow>
}
			<FormRow rowNum="2">
				<Input title="Red Low" attr="red_low" {...controlProps}/>
			</FormRow>
			
			<FormRow rowNum="3">
				<Input title="Yellow Low" attr="yellow_low" {...controlProps}/>
			</FormRow>
			<FormRow rowNum="4">
				<Input title="Red High" attr="red_high" {...controlProps}/>
			</FormRow>
			
			<FormRow rowNum="5">
				<Input title="Yellow High" attr="yellow_high" {...controlProps}/>
			</FormRow>
			</>
		}

		{optionsType== "Discrete" && 
		<>
			<FormRow rowNum="1">
				<Select title="Spacecraft Mode" attr="sc_mode" {...controlProps} valueOptions={typeOptions} />
			</FormRow>
			</>
		}	
		</>

	);
};

export default LimitOptionFormContent;
