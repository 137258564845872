import React from 'react';

import EditAssociations from 'components/ui-core/page/assoc/EditAssociations'
import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import SelectInputColumnFilter from 'components/ui-core/table/filter/SelectInputColumnFilter';
import { getTagColumn } from 'components/ui-core/table/TableHelper';

import EntityType from 'model/EntityType';

export const TlmPacketAssocTabEditMode = ({ editModeTransitionHandler, updateEntityPromise,
	tlmPacket, allPoints}) => {
	
	const lhsColumns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'tlm_point_name',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Type',
				accessor: 'type',
				Filter: SelectInputColumnFilter,
				filter: 'includes',
			},
			{
				Header: 'Byte Size',
				accessor: 'size_bytes'
			},
			getTagColumn(),
		],
		[]
	);

	const rhsColumns = React.useMemo(
		() => [
			{
				Header: 'Seq. No',
				accessor: 'seq_no'			
			},
			{
				Header: 'Name',
				accessor: 'tlm_point_name',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Type',
				accessor: 'type',
			},
			{
				Header: 'Byte Size',
				accessor: 'size_bytes'
			},
			getTagColumn(),
		],
		[]
	);


	return (
		<EditAssociations 
			allObjects={allPoints}
			packetEntityType={EntityType.TlmPacket}
			lhsColumns={lhsColumns}
			rhsColumns={rhsColumns}
			editModeTransitionHandler={editModeTransitionHandler}
			underlyingEntity={tlmPacket}
			updateEntityPromise={updateEntityPromise}
		/>
	);
};

export default TlmPacketAssocTabEditMode;
