// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.NotFound_pageNotFound__YYG8m {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
}

.NotFound_pageNotFound__YYG8m h1 {
	font-size: 5rem;
	margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound.module.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,wBAAwB;CACxB,mBAAmB;CACnB,uBAAuB;CACvB,aAAa;CACb,WAAW;AACZ;;AAEA;CACC,eAAe;CACf,mBAAmB;AACpB","sourcesContent":["\n.pageNotFound {\n\tdisplay: flex;\n\tflex-flow: column nowrap;\n\talign-items: center;\n\tjustify-content: center;\n\theight: 100vh;\n\twidth: 100%;\n}\n\n.pageNotFound h1 {\n\tfont-size: 5rem;\n\tmargin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageNotFound": `NotFound_pageNotFound__YYG8m`
};
export default ___CSS_LOADER_EXPORT___;
