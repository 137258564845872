import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import {getCmdPacketSchema, getToastMessage} from 'model/form-schema/ObjectSchemas'
import FormTab from 'components/ui-core/form/FormTab';
import useFormTab from 'components/ui-core/form/useFormTab';
import { FormRow, QueryControlWrapper } from 'components/ui-core/form/FormControls';
import CommandPacketFormContent from 'components/cmd-packet/packet-page/CommandPacketFormContent'

export const CommandPacketFormTab = ({ updateEntityPromise, editModeActive, editModeTransitionHandler, 
		cmdPacket, allCmdPackets, allTlmPacketsQuery, metadata }) => {

	const defaultSchema = getCmdPacketSchema(allCmdPackets, cmdPacket,  editModeActive);
	const [toastMes, setToastMes] = useState("");
	const [schema, setSchema] = useState(defaultSchema);

	const {useFormObj, controlProps} = useFormTab(
		{
			...cloneDeep(cmdPacket),
			dest_nodes: cmdPacket.destination_group.map(nodeEntry => nodeEntry.node)
		}, 
		schema, 
		editModeActive);

	const {watch, getValues} = useFormObj;
	watch("cmd_name");
	useEffect(() => {
		const schem = getCmdPacketSchema(allCmdPackets, getValues(),  editModeActive);
		setSchema(schem);
		schem.isValid(useFormObj.getValues())
    	.then(valid => {
			if(!valid){
				const toa = getToastMessage(allCmdPackets, getValues(), "cmd_node", editModeActive);
				setToastMes(toa);
			}
		});
		
	}, [getValues()['dest_nodes'], getValues()['subsystem_name'], getValues()['cmd_name'], getValues()['csp_port'], getValues()['cmd_code'] ]);
	

	useEffect(() => {
		if(toastMes || toastMes != ""){
			toast.warning(toastMes);
			setToastMes("");
		}
	}, [toastMes]);
	const createSubmitPromise = (formData) => {
		let updatedPacket = {
			...formData,
			destination_group: formData.dest_nodes.map( (nodeName) => {
				return {
					node: nodeName,
					node_id: metadata.nodes
						.find(metaNodeEntry => metaNodeEntry.node === nodeName)?.node_id
				}
			}),
			critical_command_warning: formData.critical_command ? formData.critical_command_warning : null
		}

		
		return updateEntityPromise(updatedPacket);
	};


	const getResponseTlmPackets = () => {
		const result = allTlmPacketsQuery.data
			.filter(tlmPacket => 
				tlmPacket.response_to_cmd_pkts && 
				tlmPacket.response_to_cmd_pkts.includes(getValues("cmd_name")))
			.map(tlmPacket => tlmPacket.tlm_packet_name)
			.join(", ")

		return result
	}
	

	return (
		<>			
			<FormTab editModeActive={editModeActive}
					editModeTransitionHandler={editModeTransitionHandler}
					createSubmitPromise={createSubmitPromise}
					useFormObj={useFormObj}
			>

				<>
					<CommandPacketFormContent 
						controlProps={controlProps} 
						metadata={metadata}
						useFormObj={useFormObj}
						isWizard={false}
					/>

					<FormRow style={{marginTop: "2rem"}} rowNum="6">
						<QueryControlWrapper
							title="Response Packets"
							queries={[allTlmPacketsQuery]}
							wrapperStyle = {{width: "100%"}}
							onLoad={() => (
								<input style={{width: "100%"}} type="text"
									value={getResponseTlmPackets()} disabled/>								
							)}
						/>
					</FormRow>

				</>
			</FormTab>
		</>
	);
}

export default CommandPacketFormTab;