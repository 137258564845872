import React from 'react';

import {getCmdParamSchema} from 'model/form-schema/ObjectSchemas'
import FormTab from 'components/ui-core/form/FormTab';
import useFormTab from 'components/ui-core/form/useFormTab';

import { CommandParameterFormContent } from './CommandParameterFormContent';
import {lookupLongUnitValue} from 'components/ui-core/utils/MetadataHelper'

export const CommandParameterFormTab = ({ updateEntityPromise, editModeActive, editModeTransitionHandler, cmdParam, allParams, metadata, parentPacketExists }) => {
	const schema = getCmdParamSchema(allParams, cmdParam.cmd_param_name);
	const {useFormObj, controlProps} = useFormTab(cmdParam, schema, editModeActive);
  
	const createSubmitPromise = (formData) => {
		let updatedParam = {...formData};
		updatedParam["units_long"] = lookupLongUnitValue(metadata, updatedParam["units_short"]);

		return updateEntityPromise(updatedParam);
	};

	return (
		<FormTab editModeActive={editModeActive}
				editModeTransitionHandler={editModeTransitionHandler}
				createSubmitPromise={createSubmitPromise}
				useFormObj={useFormObj}>

			<CommandParameterFormContent 
				controlProps={controlProps} 
				useFormObj={useFormObj}
				metadata={metadata} 
				isWizard={false}
				isByteReadOnly={parentPacketExists}/>
		</FormTab>		
	);
};


export default CommandParameterFormTab;
