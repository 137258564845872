
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageState } from 'components/ui-core/page/usePageState';

import {FormRow, TextArea, NestedFormRowControlWrapper} from 'components/ui-core/form/FormControls';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable'
import { viewButtonColumn } from 'components/ui-core/table/TableHelper';

export const ReadOnlyDerivedSection = ({ controlProps, useFormObj, allPoints}) => {
    const {getValues} = useFormObj;
    const input_point_names = getValues("derived_inputs").split(",")

    
    const TLM_POINT_TYPE = "Telemetry Point"
    const BITWISE_POINT_TYPE = "Bitwise Point"

    const data = input_point_names.map(input_name => {
        let tlmPoint = allPoints.find(point => point["tlm_point_name"] === input_name); 
        let input_type = null
        if (tlmPoint != null) {
            input_type = TLM_POINT_TYPE
        } else {
            // It should be a bitwise
            tlmPoint = allPoints.find(point => {
                const bitwise_list = point["bitwise_list"] == null ? [] : point["bitwise_list"]
                return bitwise_list.includes(input_name)
            }); 
        
            if (tlmPoint != null) {
                input_type = BITWISE_POINT_TYPE
            } else {
                input_type = "Not found"            
            }

        }
        
        return {
            name: input_name,
            input_type: input_type,
            tlm_point_id: tlmPoint != null ? tlmPoint["_id"] : null  
        }
    }) 

    const history = useHistory();
    const viewInput = (inputRow) => {
        const tlmPointId = inputRow["tlm_point_id"];
        if (tlmPointId != null) {
            const pageState = inputRow["input_type"] === BITWISE_POINT_TYPE 
                ? new PageState("bitwise_tlm_points") 
                : null;
            
            history.push(`/telemetry-point/${tlmPointId}`, pageState);
        }
    }
    
    const columns = React.useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Type",
            accessor: "input_type",
        },
        viewButtonColumn(viewInput)
    ], []);

    return (
        <>
            <FormRow rowNum="7c">
                <NestedFormRowControlWrapper title="Input Points" attr="derived_inputs" {...controlProps}>
                    <ManagedMsdTable tableName="DerivedInputTable" columns={columns} data={data} addPagination={false} />
                </NestedFormRowControlWrapper>

                <TextArea style={{height: '100%', marginRight: '8px'}} title="Derived Expression" attr="derived_expression" 
                    rows={2} {...controlProps} isRequired={true}/>
                <TextArea style={{height: '100%'}} title="Derived Python Expression" attr="derived_expression_python" 
                    rows={2} {...controlProps} />
            </FormRow>
        </>
    )
}
