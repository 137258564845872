import {FormRow, FormCheckmarkRow, Input, TextArea, Select, Checkbox, MultiSelect, ToggleSwitchWrapper} from 'components/ui-core/form/FormControls';
import SubystemCspPortController from 'components/ui-core/common/SubystemCspPortController';
import { getSortedSubsystemValueOptions } from 'components/ui-core/utils/MetadataHelper';
import { isInFswArr } from "components/tags/TagHelper";
import React from 'react';


export const CommandPacketFormContent = ({controlProps, metadata, useFormObj, isWizard}) => {
	const {watch, getValues, control, setValue} = useFormObj;

	// re-render when type change (conditional fields)
	watch("critical_command");

	const destNodeOptions = React.useMemo(() => metadata.nodes.map(nodeEntry => nodeEntry.node), []); 
	const portController = new SubystemCspPortController(useFormObj, metadata);

	const isCriticalCommand = () => getValues("critical_command");
	const isInFSW = () => isInFswArr(getValues("environment_tags"));
	
	




	return (
		<>			
			<FormRow rowNum="1">
				<Input style={{width: "28rem"}}  title="Command Packet Name" attr="cmd_name" {...controlProps}/>

				<Select title="Subsystem" attr="subsystem_name" {...controlProps}
					valueOptions={getSortedSubsystemValueOptions(metadata)}
					includeBlank={isWizard} onChange={portController.handleSubsystemChanged}/>

				<Input title="CSP Port Number" attr="csp_port" {...controlProps} isRequired={isInFSW}/>
			</FormRow>

			<FormRow rowNum="2">
				<TextArea title="Description" attr="description" {...controlProps}/>
			</FormRow>

			<FormRow rowNum="3">
				<Input title="Command Code" attr="cmd_code" {...controlProps} isRequired={isInFSW}/>

				<MultiSelect title="Destination Nodes" attr="dest_nodes" {...controlProps}
					optionValues={destNodeOptions} control={control}/>
			</FormRow>
			<FormCheckmarkRow rowNum="4">
				{/* <Checkbox title="Critical Command" attr="critical_command" {...controlProps} 
					textLabels={{enabled: "Yes", disabled: "No"}}/> */}
				
				<ToggleSwitchWrapper title="Critical Command" attr="critical_command"
                        {...controlProps} isRequired={isCriticalCommand}/>

			</FormCheckmarkRow>
			
			{getValues("critical_command") &&
				<FormRow rowNum="3">
					<TextArea title="Critical Command Warning" attr="critical_command_warning" 
						{...controlProps} isRequired={isCriticalCommand}/>
				</FormRow>	
			}				
		</>
	);
}

export default CommandPacketFormContent;