import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import CreateTelemetryPacketWizard from './CreateTelemetryPacketWizard';

import Modal from 'components/ui-core/modal/Modal/Modal';

import { useASTData } from 'context/ASTContext';
import {useTelemetryPackets, useCommandPackets, useMetadata} from 'data/queryHooks';


import SideBarOption from 'model/SideBarOption';

import MsdQueryTable from 'components/ui-core/table/msdTable/MsdQueryTable'
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import { getTelemetryPacketColumns } from './TelemetryPacketTableHelper';

const TelemetryPacketsTablePage = () => {
	const [createVisible, setCreateVisible] = useState(false);

	const {setSideBarOption, setHeaderTitle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.TlmPacket);
		setHeaderTitle("Telemetry Packets");
		localStorage.setItem('lastPage',"/telemetry-packets");
	}, []);	

	const history = useHistory();
	const viewTelemetryPacketHandler = (tmlPacket) => {
		history.push(`/telemetry-packet/${tmlPacket._id}`);
	};

	const columns = React.useMemo(() => getTelemetryPacketColumns(viewTelemetryPacketHandler), []);

	const allTlmPacketsQuery = useTelemetryPackets();
	const allCmdPacketsQuery = useCommandPackets();
	const metadataQuery = useMetadata();

	return (
		<MsdMainPage>
			{createVisible && 
				<LoadingWrapper 

					queries={[allTlmPacketsQuery, metadataQuery]}
					onLoad={() => (
						<Modal>
							<CreateTelemetryPacketWizard 
								setCreateVisible={setCreateVisible} 
								allTlmPackets={allTlmPacketsQuery.data}
								allCmdPacketsQuery={allCmdPacketsQuery}
								metadata={metadataQuery.data[0]}
								copyPacket={null}
							/>
						</Modal> 
					)}				
				/>
			}			
			
			<MsdQueryTable tableName="TelemetryPacketsTablePage" columns={columns} query={allTlmPacketsQuery}>
				<CreateButton
					width="13rem"
					title="Telemetry Packet"
					onClick={() => {
						setCreateVisible(true);
					}}
				/>		
			</MsdQueryTable>			
		</MsdMainPage>
	);
};

export default TelemetryPacketsTablePage;
