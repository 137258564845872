import React from 'react';
import {usePageState} from 'components/ui-core/page/usePageState';

// Table Filter object rendered as an input text field
function TextInputColumnFilter(props) {
	const { column: { id, filterValue, preFilteredRows, setFilter }, tableName } = props	
	const count = preFilteredRows.length;
	const {setFilterValue} = usePageState();

	return (
		<input
			value={filterValue || ''}
			onChange={(e) => {
				const newFilterValue = e.target.value || undefined
				setFilterValue(tableName, id, newFilterValue)
				setFilter(newFilterValue);
			}}
			placeholder={`Search ${count} records...`}
		/>
	);
}

export default TextInputColumnFilter;

