import React from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import CommandParameterFormTab from './CommandParameterFormTab';
import OptionsTab from 'components/options/OptionsTab';
import TagsTab from 'components/tags/editors/TagsTab'

import { useASTData } from 'context/ASTContext';

import {useEditorPage} from 'components/ui-core/page/useEditorPage'
import EditorPage from 'components/ui-core/layout/EditorPage/EditorPage';
import ConfirmationRequest from 'model/ConfirmationRequest';
import SideBarOption from 'model/SideBarOption';
import CommandParamUsageTab from './CommandParamUsageTab';

import EntityType from 'model/EntityType';

import {useCommandParam, useCommandParams, useMetadata, useSaveCommandParam, 
		 useCommandPackets, useTags,
		usePhysicallyDeleteCommandParam, useLogicallyDeleteCommandParam}  from 'data/queryHooks';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';
import { shouldPhysicallyDelete } from 'components/tags/TagHelper';
import EditModeTransition from 'model/EditModeTransition';


const CommandParameterPage = () => {
	// Ensure each Page has it's instance (fresh component tree) otherwise history.push()
	// to the same route will reuse components for other object navigate away from
	let {cmdParamId} = useParams();
	return <CommandParameterPageBody key={cmdParamId}/>
}

const CommandParameterPageBody = () => {

	const {setSideBarOption, setHeaderTitle, setConfirmationRequest} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.CmdParam);
		setHeaderTitle(null);
	}, [setSideBarOption, setHeaderTitle]);


	let {cmdParamId} = useParams();

	// Data queries
	const paramByIdQuery = useCommandParam(cmdParamId);
	const allParamsQuery = useCommandParams();
	const allPacketsQuery = useCommandPackets();
	const metadataQuery = useMetadata();
	const tagsQuery = useTags();

	React.useEffect(() => {
		if (paramByIdQuery.data) {
			setHeaderTitle(paramByIdQuery.data?.cmd_param_name);	
		}
	}, [paramByIdQuery.data, setHeaderTitle]);	


	const  {mutateAsync: physicallyDelete} = usePhysicallyDeleteCommandParam();
	const  {mutateAsync: logicallyDelete} = useLogicallyDeleteCommandParam();

	const createDeleteRequest = () => {
		const physically_delete = shouldPhysicallyDelete(paramByIdQuery.data)

		return new ConfirmationRequest(
			cmdParamId,
			physically_delete ? physicallyDelete : logicallyDelete,
			`Delete '${paramByIdQuery.data?.cmd_param_name}'`, 
			"Delete",
			"Are you sure you want to delete this command parameter?",
			physically_delete ? '/command-parameters' : null,
			`Command Parameter '${paramByIdQuery.data?.cmd_param_name}' ${physically_delete ? "deleted" : " flagged for deletion"}`
		)
	}

	const enumTypes = ["unsigned", "signed"];
	const tabDefs = [
		{
			id: "form_tab",
			buttonText: "Command Parameter",
			buttonType: "CommandParam",
			editMode: "formEditMode"
		},
		{
			id: "options",
			buttonText: "Enumerations Menu",
			buttonType: "Options",
			editMode: "none",
			enabledWhen: () => {
				return enumTypes.includes(paramByIdQuery.data?.type); 
			}
		},
		{
			id: "tags_tab",
			buttonText: "Tags",
			buttonType: "Tag",
			editMode: "formEditMode"
		},
		{
			id: "usage",
			buttonText: "Usage",
			buttonType: "CommandPacketUsage",
			editMode: "none"
		}
	] 

	const {
        currentEditOption,
        selectedTabId,
        editModeActive,
        tabSelectedHandler,
        editModeTransitionHandler,
		formKey
    } = useEditorPage(tabDefs);

	const toolBarDef = 	{
		navButtons: [
			{
				title: "< View All Command Parameters",
				width: "18.5rem",
				route: "/command-parameters"
			}
		],
		manipulationButtons: {
			mutationButtons: {
				includeEdit: true,
				includeBuildMode: false,
				editModeTransitionHandler: editModeTransitionHandler
			},
			deleteButton: {
				createDeleteRequest: createDeleteRequest
			}
		}
	}


	const  {mutateAsync: saveCommandParam} = useSaveCommandParam();
	const updateEntityPromise = async (updatedParam) => {
		if(paramByIdQuery.data?.size_bytes !== updatedParam["size_bytes"])
		{
			const message = 
				<div>
					Caution, you are changing the size bytes of a cmd parameter.
					<br/>
					<br/>
					This could have cascading implications for all command packets that use this command param. 
					<br/>
					<b>Are you sure you want to edit it?</b>
				</div>
            const confirmationRequest =  new ConfirmationRequest(
                null,
                async () => updateCommandParam(updatedParam),
                `Warning '${updatedParam["cmd_param_name"]}'`, 
                "Confirm Changes",
                message,
                null,
                `Command Param '${updatedParam["cmd_param_name"]}' Updated Successfully`,
				'Cancel',
				async () => editModeTransitionHandler(EditModeTransition.Cancel)
            )
        
            setConfirmationRequest(confirmationRequest);
		}
		else{
			updateCommandParam(updatedParam);
		}
	}

	const updateCommandParam = async (updatedParam)  => {
		return await saveCommandParam(updatedParam)
		.then(refreshedPacket => {
			toast.success(`'${updatedParam.cmd_param_name}' updated`);
			return refreshedPacket;
		})
		.catch(error => {
			toast.error("Command Parameter update failed");
			return Promise.reject(error)
		});	
	}

	return (
		<EditorPage 
			toolBarDef={toolBarDef} currentEditOption={currentEditOption} 
			editModeActive={editModeActive} tabDefs={tabDefs} 
			selectedTabId={selectedTabId} tabSelectedHandler={tabSelectedHandler}
			underlyingEntityQuery = {paramByIdQuery}
		>	

			{selectedTabId === "form_tab" && 
				<LoadingWrapper 
					queries={[paramByIdQuery, allParamsQuery, metadataQuery]}
					onLoad={() => (
						<CommandParameterFormTab 
							key={formKey}
							updateEntityPromise={updateEntityPromise}
							editModeActive={editModeActive} 
							editModeTransitionHandler={editModeTransitionHandler}
							cmdParam={paramByIdQuery.data}
							allParams={allParamsQuery.data}
							metadata={metadataQuery.data[0]}
							parentPacketExists={allPacketsQuery.data
								.filter(packet => packet.cmd_fields.includes(paramByIdQuery.data["cmd_param_name"])).length !== 0}/>
					)}
				/>
			}
			{selectedTabId === "options" && 
				<LoadingWrapper 
					queries={[paramByIdQuery]}
					onLoad={() => (
						<OptionsTab
							key={formKey}
							updateEntityPromise={updateEntityPromise}
							underlyingEntity={paramByIdQuery.data}
							entityType={EntityType.CmdParam}/>
					)}
				/>
			}
			{selectedTabId === "tags_tab" && 
				<LoadingWrapper 
					queries={[paramByIdQuery, tagsQuery]}
					onLoad={() => (
						<TagsTab 
							editModeActive={editModeActive} 
							editModeTransitionHandler={editModeTransitionHandler}
							underlyingEntity={paramByIdQuery.data}
							allTags={tagsQuery.data}
							entityType={EntityType.CmdParam}/>
					)}
				/>
			}

			{selectedTabId === "usage" && 
				<LoadingWrapper 
					queries={[paramByIdQuery, allPacketsQuery]}
					onLoad={() => (
						<CommandParamUsageTab
							key={formKey}
							underlyingEntity={paramByIdQuery.data}
							parentPackets={allPacketsQuery.data
								.filter(packet => packet.cmd_fields.includes(paramByIdQuery.data["cmd_param_name"]))}/>
					)}
				/>
			}

		</EditorPage>
	);
};

export default CommandParameterPage;
