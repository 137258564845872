import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import ASTLogo from 'img/ast-logo-main.png';
import CommandPacketsIcon from 'img/command-packets-icon.svg';
import CommandParametersIcon from 'img/command-parameters-icon.svg';
import TelemetryPacketsIcon from 'img/telemetry-packets-icon.svg';
import TelemetryPointsIcon from 'img/telemetry-points-icon.svg';
import BitwiseIcon from 'img/bitwise-icon.svg';
import TagsIcon from 'img/tags-icon.svg';
import UnitsIcon from 'img/units-icon.svg';
import BoardVersionsIcon from 'img/board-version.svg'
import ReviewIcon from 'img/review-eye.svg';
import NotesIcon from 'img/notes-icon.svg'
import AutomationIcon from "img/automation-svgrepo-com.svg";
import VersionCompareIcon from "img/version-compare.svg"
import AlertIcon from "img/alert.svg"

import style from './SideBar.module.css';
import SideBarOption from 'model/SideBarOption';

import { useASTData } from 'context/ASTContext';
import Wizard from 'components/ui-core/modal/Wizard';
import Modal from 'components/ui-core/modal/Modal/Modal';
import CloseButton from 'components/ui-core/buttons/CloseButton/CloseButton';
import { useState } from 'react';




export const SideBar = () => {
	const history = useHistory();
	const [isReleaseNotesVisible, setIsReleaseNotesVisible] = useState(false);
	//const [isLoading, setLoading] = useState(false);

	const commandPacketsRoute = () => history.push('/');
	const commandParametersRoute = () => history.push('/command-parameters');
	const telemetryPacketsRoute = () => history.push('/telemetry-packets');

	
	const telemetryPointsRoute = () => history.push('/telemetry-points');

	// const handlePush = async (path) => {
	// 	console.log(true)

	// 	setLoading(true);
	// 	try {
	// 	  // Simulate an asynchronous operation, like fetching data before navigation
	// 	  await history.push(path);
	// 	} catch (error) {
	// 	  // Handle errors if necessary
	// 	  console.error('Error navigating:', error);
	// 	} finally {
	// 	  setLoading(false);
	// 	  console.log(false)
	// 	}
	// };

	// const handlePush = async (path) => {
	// 	setLoading(true);
	// 	try {
	// 	  // Simulate an asynchronous operation, like fetching data before navigation
	// 	  await someAsyncFunction();
	// 	  history.push(path);
	// 	} catch (error) {
	// 	  // Handle errors if necessary
	// 	  console.error('Error navigating:', error);
	// 	} finally {
	// 	  setLoading(false);
	// 	}
	// };

		
		
	const bitwiseRoute = () => history.push('/bitwise-points');
	const tagsRoute = () => history.push('/tags');
	const unitsRoute = () => history.push('/units');
	const boardVersionsRoute = () => history.push('/board-versions');
	const reviewRoute = () => history.push('/review');
	const alertRoute = () => history.push('/msd-alerts');
	const versionCompareRoute = () => history.push('/version-compare');
	const openDocsTab = () => {
		window.open('https://ast-fsw.atlassian.net/wiki/spaces/MO/pages/41844749/1.5+Master+Satellite+Database' , '_blank');
	}
	const openUseMSD = () => {
		window.open('https://gitlab.com/ast-science/ground-systems/sandworm/master-satellite-database/msd-backend/-/raw/production/app/use_msd.py?ref_type=heads' , '_blank');
	}

	const { sideBarOption } = useASTData()



	const classForButton = (option) => {
		if (sideBarOption && option === sideBarOption) {
			return style.activePage
		} else {
			return style.inactivePage
		}
	}

	const version_updates = JSON.parse(process.env.REACT_APP_RELEASE_NOTES);

	return (
		<>
			<aside>
				<section>
					<div className={style.astLogo}>
						<Link to="/">
							<img src={ASTLogo} alt="AST SpaceMobile" />
						</Link>
					</div>

					<nav>
						<button onClick={commandPacketsRoute} className={classForButton(SideBarOption.CmdPacket)}>
							<div>
								<img src={CommandPacketsIcon} alt="Command Packets" />
								<p>Command Packets</p>
							</div>
						</button>

						<button onClick={commandParametersRoute} className={classForButton(SideBarOption.CmdParam)}>
							<div>
								<img src={CommandParametersIcon} alt="Command Parameters" />
								<p>Command Parameters</p>
							</div>
						</button>

						<button onClick={telemetryPacketsRoute} className={classForButton(SideBarOption.TlmPacket)}>
							<div>
								<img src={TelemetryPacketsIcon} alt="Telemetry Packets" />
								<p>Telemetry Packets</p>
							</div>
						</button>

						<button onClick={telemetryPointsRoute} className={classForButton(SideBarOption.TlmPoint)}>
							<div>
							
								<img src={TelemetryPointsIcon} alt="Telemetry Points" />
								<p>Telemetry Points</p>
							</div>
						</button>

						<button onClick={bitwiseRoute} className={classForButton(SideBarOption.Bitwise)}>
							<div>
								<img src={BitwiseIcon} alt="Bitwise Points" />
								<p>Bitwise Points</p>
							</div>
						</button>

						<button onClick={alertRoute} className={classForButton(SideBarOption.Alerts)}>
							<div>
								<img src={AlertIcon} alt="Alerts" />
								<p>Alerts (beta)</p>
							</div>
						</button>
						<button onClick={tagsRoute} className={classForButton(SideBarOption.Tag)}>
							<div>
								<img src={TagsIcon} alt="Tags" />
								<p>Tags</p>
							</div>
						</button>

						<button onClick={unitsRoute} className={classForButton(SideBarOption.Units)}>
							<div>
								<img src={UnitsIcon} alt="Units" />
								<p>Units</p>
							</div>
						</button>

						
						<button onClick={boardVersionsRoute} className={classForButton(SideBarOption.BoardVersions)}>
							<div>
								<img src={BoardVersionsIcon} alt="BoardVersions" />
								<p>Board Versions</p>
							</div>
						</button>

						<button onClick={reviewRoute} className={classForButton(SideBarOption.Review)}>
							<div>
								<img src={ReviewIcon} alt="ReviewIcon" />
								<p>Review</p>
							</div>
						</button>
						<button onClick={openUseMSD} className={classForButton(SideBarOption.MSDDocs)}>
							<div>
									<img src={AutomationIcon} alt="AutomationIcon" />
									<p>Automation</p>
							</div>
						</button>
						<button onClick={versionCompareRoute} className={classForButton(SideBarOption.MSDVersionCompare)}>
							<div>
									<img src={VersionCompareIcon} alt="versionCompareIcon" />
									<p>Version Compare (beta)</p>
							</div>
						</button>
						<button onClick={openDocsTab} className={classForButton(SideBarOption.MSDScript)}>
							<div>
									<img src={NotesIcon} alt="NotesIcon" />
									<p>Docs</p>
							</div>
						</button>
					</nav>
				</section>
				<div className={style.spacer}></div>
					<button onClick={() => setIsReleaseNotesVisible(true)} className={style.releaseNotesBtn}>Release Notes</button>
			</aside>
			{isReleaseNotesVisible &&
				<Modal>
					<Wizard width="50rem">
						<div className={style.modalRowHeading}>
							<div>
								<h1>Release Notes</h1>
							</div>
							<div>
								<div onClick={() => setIsReleaseNotesVisible(false)}>
									<CloseButton />
								</div>
							</div>
						</div>

						<ul style={{ overflowX: "anywhere" }}>
							{version_updates.map(updates => (
								<li>
									{updates}
								</li>))}
						</ul>

					</Wizard>
				</Modal>
			}
		</>
	);
};


export default SideBar;
