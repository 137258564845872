import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';

import CreateCommandPacketWizard from './CreateCommandPacketWizard';

import Modal from 'components/ui-core/modal/Modal/Modal';

import { useASTData } from 'context/ASTContext';
import {useCommandPackets, useTelemetryPackets, useMetadata} from 'data/queryHooks';

import SideBarOption from 'model/SideBarOption';

import MsdQueryTable from 'components/ui-core/table/msdTable/MsdQueryTable'
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import { getCommandPacketColumns } from 'components/cmd-packet/table-page/CommandPacketTableHelper';

const CommandPacketsTablePage = () => {
	const [createVisible, setCreateVisible] = useState(false);

	const {setSideBarOption, setHeaderTitle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.CmdPacket);
		setHeaderTitle("Command Packets");
		localStorage.setItem('lastPage',"/");
	}, [setHeaderTitle, setSideBarOption]);

	const history = useHistory();
	const viewCommandPacketHandler = (cmdPacket) => {
		history.push(`/command-packet/${cmdPacket._id}`);
	};
	const columns = React.useMemo(() => getCommandPacketColumns(viewCommandPacketHandler), []);

	const allCmdPacketsQuery = useCommandPackets();
	const metadataQuery = useMetadata();

	// Cache for when a packet is viewed
	useTelemetryPackets();
		
	return (
		<MsdMainPage>
			{createVisible && 
				<LoadingWrapper 
					queries={[allCmdPacketsQuery, metadataQuery]}
					onLoad={() => (
						<Modal>
							<CreateCommandPacketWizard 
								setCreateVisible={setCreateVisible} 
								allCmdPackets={allCmdPacketsQuery.data}
								metadata={metadataQuery.data[0]}
								copyPacket={null}
							/>
						</Modal> 
					)}				
				/>
			}

			<MsdQueryTable tableName="CommandPacketsTablePage" columns={columns} query={allCmdPacketsQuery}>
				<CreateButton
					width="13rem"
					title="Command Packet"
					onClick={() => {
						setCreateVisible(true);
					}}
				/>		
			</MsdQueryTable>
		</MsdMainPage>
	);
};

export default CommandPacketsTablePage;
