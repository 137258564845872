import React, {useState} from 'react';

import ReviewPushLogTabContent from './ReviewPushLogTabContent';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import {usePushJobs,} from 'data/queryHooks';

export const ReviewPushLogTab = () => {

    const allPushJobsQuery = usePushJobs();

    return (
        <LoadingWrapper 
            queries={[allPushJobsQuery]}
            onLoad={() => (
                <ReviewPushLogTabContent 
                    pushJobs={allPushJobsQuery.data}
                />
            )}
        />
    );
};

export default ReviewPushLogTab;