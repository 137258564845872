import { Route, Redirect } from 'react-router-dom'
import { useAuthContext } from 'components/ui-core/auth/AuthContext'; 
import { toast } from 'react-toastify';



const PrivateRoute = ({children, requiredScopes, ...rest}) => {
    const authContext = useAuthContext()
    const { user, getMissingPermissions } = authContext


    if(!user?.idToken) {
        return <Route {...rest}><Redirect to="/login"/></Route>
    }

    const missingPermissions = getMissingPermissions(requiredScopes) 
    if (missingPermissions.length > 0) {
        toast.error(
            `Unable to move page. Missing required permission(s) are '${missingPermissions.join(", ")}'`);

        return <Route {...rest}><Redirect to="/login"/></Route>
    }

    return <Route {...rest}>{children}</Route>
}

export default PrivateRoute;