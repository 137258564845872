import React, {useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {DndProvider} from 'react-dnd'
import { toast } from 'react-toastify';

// Use MouseBackEnd because HTML5Backend does not allow props/state 
// (and hence css feedback) to be set no hovering a mouse inside a drop target.
// We need this to show the drop position based on whether the mouse is in the
// top half or bottom half of drop target
import MouseBackEnd from "react-dnd-mouse-backend";

import 'animate.css';
import style from 'App.module.css';
import formControlsStyle from 'components/ui-core/form/FormControls.module.css';

import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';

import SaveableTab from 'components/ui-core/page/SaveableTab';
import useMsdTable from 'components/ui-core/table/msdTable/useMsdTable'
import MsdTable from 'components/ui-core/table/msdTable/MsdTable'
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";

import Modal from 'components/ui-core/modal/Modal/Modal';

import TableEditButton from 'components/ui-core/buttons/TableEditButton/TableEditButton';
import TableDeleteButton from 'components/ui-core/buttons/TableDeleteButton/TableDeleteButton';

import CreateBitwisePointWizard from 'components/tlm-point/point-page/bitwise/CreateBitwisePointWizard';
import { BitwiseEditForm } from './BitwiseEditForm';

import {assignOrderAndStartNumbers, isSystemManagedFiller, calculateBitTotals} from 'components/tlm-point/point-page/bitwise/BitwiseHelper';

import { getBitwiseSummaryItems } from 'components/tlm-point/point-page/bitwise/BitwiseHelper';
import { useSummmaryContext } from 'components/ui-core/common/SummaryContext';

import {DragWithinTable} from 'components/ui-core/table/Table/TableUtil';
import MoveToPos from 'components/ui-core/table/Table/MoveToPos';

import { useSaveTlmBitwise } from 'data/queryHooks';
import { getOptionsColumn } from 'components/options/OptionHelper';
import EntityType from 'model/EntityType';

export const BitwiseTableTabBuildMode = ({ editModeTransitionHandler, initialTlmPoint, initialBitwise}) => {
	const FILLER_DESC = "System managed bit filler";

	const initialTableData = React.useMemo(
		() => {
			const tableData = initialTlmPoint.bitwise_list.map(
				bitwiseName => {
					const row = cloneDeep(initialBitwise.find(bitwise => bitwise.tlm_bitwise_name === bitwiseName));
					// Note: all rows must have an id apart from the name in order for the name itself to editable
					// For existing object this will be the uuid. For new object, it'll be the name it was created with 
					row["localId"] = row._id;
					return row;
				});
			assignOrderAndStartNumbers(tableData);
			return tableData;
		},
		[]
	);

	const [createVisible, setCreateVisible] = useState(false);
	const [editBitwise, setEditBitwise] = useState(null);	
	const [tableData, setTableData] = useState(initialTableData);


	const columns = React.useMemo(
		() => [
			{
				Header: "Sequence No.",
				accessor: "sequence_num"
		  	},
			{
				Header: "Name",
				accessor: "tlm_bitwise_name",
				Filter: TextInputColumnFilter
			},
		  	{
				Header: "Description",
				accessor: "description"
		  	},
		  	{
				Header: "Bit Size",
				accessor: "size_bits"
		  	},
			{
				Header: "Start bit",
				accessor: "start_bit"
		  	},
			getOptionsColumn(EntityType.BitwisePoint),
		  	{
				Header: ' ',
				accessor: (bitwiseRow) => {
					return (
						<div className={style.tableCellContainer}>
							{!isSystemManagedFiller(bitwiseRow) && 
							<>
								<TableEditButton editHandler={() => setEditBitwise(bitwiseRow)}/>
								<TableDeleteButton deletionHandler={(event) => handleDeleteConfirm(event, bitwiseRow)}/>							
							</>
							}
						</div>
					);
				},
				columnType: "buttons"
		  	},
		],
		[]
	);

	//DnD
    const DndItemTypes = {
        BITWISE_ROW: "bitwise_row"
    }

	const msdTableObject = useMsdTable(
		{
			tableName: "BitwiseTableTabBuildMode",
			columns: columns, 
			data: tableData, 
			sortBy: null, 
			addSort: false, 
			addSelection: true,
			addPagination: false, 
			dndSpec: {
                tableId: "Bitwise",
				nameAttr: "tlm_bitwise_name",
                dragSpec: {
                    type: DndItemTypes.BITWISE_ROW,
                },
                dropSpec: {
                    accept: DndItemTypes.BITWISE_ROW,
                    performDrop: (dropRequest) => performDrop(dropRequest)
                }
            },
            options: {
                initialState: {
                },
                getRowId: (row) => {
                    return row["tlm_bitwise_name"];
                }
            }
        }
	);

    const performDrop = (dropRequest) => {
        console.log(`DROP into ${dropRequest.dropTableId}: `, dropRequest);

		const dragWithTable = new DragWithinTable(
				dropRequest, tableData, setTableData, assignOrderAndStartNumbers, true);
        
		dragWithTable.performDrag();
    } 

	const addBitwise = (newBitwise) => {
		setTableData(latestTableData => 
			createNewTableDataAfterAddition(latestTableData, newBitwise));
	}


	const createNewTableDataAfterAddition = (latestTableData, newBitwise) => {
        let newTableData = [...latestTableData];
		newBitwise["dirty"]=true;
		newTableData.push(newBitwise);

		assignOrderAndStartNumbers(newTableData);

		return newTableData;	
	}

	const handleDeleteConfirm = (event, bitwiseToDelete) => {
		//Prevent the event propagating to ensure the MsdTable click hander doesn't
		//try and reselect this event (causes a crash)
		event.stopPropagation();
		
		deleteBitwise(bitwiseToDelete);
	};

	const deleteBitwise = (bitwiseToDelete) => {
		// A React querk, but here "tableData" is the original value on component creation
		// because our closure is inside the memoized column definition. The latest tableData
		// value is available when using a useState setter method with a function 
		setTableData(latestTableData => 
			createNewTableDataAfterDeletion(latestTableData, bitwiseToDelete));	
	}

	const createNewTableDataAfterDeletion = (latestTableData, bitwiseToDelete) => {
        let newTableData = [...latestTableData];
		newTableData = newTableData.filter(row => row.tlm_bitwise_name !== bitwiseToDelete.tlm_bitwise_name);
		assignOrderAndStartNumbers(newTableData);

		return newTableData;	
	}

	const isSaveEnabled = () => {
		const changedMade = tableData.some(row => row.dirty) 
			|| initialTableData.length !== tableData.length;

		const valid = getTableErrors().length === 0;

		return changedMade && valid;
    };

	const  {mutateAsync: saveTlmBitwise} = useSaveTlmBitwise();

	const createBitwiseObjFromTableData = (row) => {

		const serverObj = {
			tlm_bitwise_name: row.tlm_bitwise_name,
			sequence_num: row.sequence_num,
			parent_tlm_point: row.parent_tlm_point,
			size_bits: row.size_bits,
			representation_list: row.representation_list,
			description: row.description,
			depreciated: row.depreciated
		};

		if (row["_id"]) {
			serverObj["_id"] = row["_id"]
		}

		return serverObj; 	
	};

	const createSubmitPromise = async (event) => {
		console.log("-- TableData", tableData);

		const bitwiseNames = tableData.map(row => row.tlm_bitwise_name);

		const updated = tableData
			.filter(row => row["_id"])
			.map(row => createBitwiseObjFromTableData(row)); 

		const created = tableData
			.filter(row => !row["_id"])
			.map(row => createBitwiseObjFromTableData(row)); 

		const request = {
			tlmPointName: initialTlmPoint.tlm_point_name,
			bitwise_list: bitwiseNames,
			existing_bitwise: updated,
			created_bitwise: created		
		}

		return await saveTlmBitwise(request)
			.then(response => {
				toast.success(`'${initialTlmPoint.tlm_point_name}' updated`);
				return response;
			})
			.catch(error => {
				console.log("Server error updating Telemetry Point", error);
				toast.error(`Telemetry Point update failed`);
				return Promise.reject(error)
			});		

    }

	const bitwiseEdited = (updatedBitwise) => {
		replaceBitwiseInTable(updatedBitwise.localId, updatedBitwise);
	}

	const replaceBitwiseInTable = (originalLocalId, updatedBitwise) => {
		updatedBitwise["dirty"]=true;

        let newTableData = tableData.map(row => {
			if (row.localId === originalLocalId) {
				return updatedBitwise;
			} else {
				return row;
			}
		});
		assignOrderAndStartNumbers(newTableData);

		setTableData(newTableData);	
	}

	const arrayFnOnMove = (newTableData, movedItems) => {
		assignOrderAndStartNumbers(newTableData);
		movedItems.forEach(bitwise => bitwise["dirty"] = true);
	};

	const getBitTotals = () => {
		return calculateBitTotals(initialTlmPoint, tableData)
	}

	const getTableErrors = () => {
		const result = [];
		const {expectedBits, actualBits} = getBitTotals();
		
		if (actualBits !== expectedBits) {
			result.push(`The bitwise points must total ${expectedBits} bits in length. They currently total ${actualBits}`)
		}

		tableData.forEach((row) => {
			const start_pos_in_byte = row["start_bit"] % 8
			if (start_pos_in_byte + row["size_bits"] > 8) {
				result.push(`Bitwise point ${row["tlm_bitwise_name"]} crosses a byte boundary. This must be avoided as it causes problems with COSMOS`)
			}
		})

		return result;
	}
	const tableError = getTableErrors();

	const createFiller = (noBitsToAdd, namePostfix) => {
		if (noBitsToAdd < 1) {
			return;
		}

		const fillerName = `BIT_FILLER_${namePostfix}`;
		const filler = {
			tlm_bitwise_name: fillerName,
			size_bits: noBitsToAdd,
			parent_tlm_point: initialTlmPoint.tlm_point_name,
			depreciated: {
				depreciated_status: false,
				notes: null
			},
			representation_list: [],
			localId: fillerName,
			description: FILLER_DESC
		};

		return filler;
	}

	const createExpectedFillers = () => {
		const {expectedBits, userDefinedBits} = getBitTotals();
		let noFillerBitsLeftToAdd = expectedBits - userDefinedBits;
		
		const fillers = [];
		while (noFillerBitsLeftToAdd > 0) {
			let fillerSize = noFillerBitsLeftToAdd % 8;
			if (fillerSize === 0) {
            	fillerSize = 8;
			}

			noFillerBitsLeftToAdd -= fillerSize;
			let namePostfix = fillerSize.toString();
		
			// Avoid duplicate names
			if (fillerSize === 8) {
				const noExisting8s = fillers.filter(filler => filler["size_bits"] === 8).length;
				if (noExisting8s) {
					namePostfix = "8_" + (noExisting8s + 1);
				} else if (noFillerBitsLeftToAdd > 0) {
					namePostfix = "8_1";
				}
			}

			const filler = createFiller(fillerSize, namePostfix)
			fillers.push(filler)
		}

		return fillers;
	}

	const markDifferencesAsDirty = (expectedTableData) => {
		// Go through each row set and mark differences as first	
		let differencesExist = false;
		for (let i = 0; i < expectedTableData.length; i++) {
			const expectedRow = expectedTableData[i];
			const actualRow = i < tableData.length ? tableData[i] : null

			// Do they significantly differ (in terms of name and size)
			if (actualRow == null 
				|| actualRow["tlm_bitwise_name"] !== expectedRow["tlm_bitwise_name"]
				|| actualRow["size_bits"] !== expectedRow["size_bits"]
			) {
				differencesExist = true;
				expectedRow["dirty"]=true;
			}
		}

		// cover the case whether the actual list is longer
		differencesExist |= expectedTableData.length !== tableData.length

		return differencesExist;
	}

	const resolveFillerBits = () =>  {
		const expectedFillers = createExpectedFillers()
		const nonFillers = tableData.filter(bitwise => !isSystemManagedFiller(bitwise))
		
		const expectedTableData = nonFillers.concat(expectedFillers)
		const differencesExist = markDifferencesAsDirty(expectedTableData)

		if (differencesExist) {
			assignOrderAndStartNumbers(expectedTableData);	
			setTableData(expectedTableData);	
		}
	}
	resolveFillerBits();


    const {setSummaryItems} = useSummmaryContext();
	React.useEffect(() => {
			setSummaryItems(getBitwiseSummaryItems(initialTlmPoint, tableData));
		}, 
		[initialTlmPoint, tableData]
	);

	return (		
		<>
			{!editBitwise && 
				<>
					<SaveableTab editModeActive={true}
						editModeTransitionHandler={editModeTransitionHandler}
						createSubmitPromise={createSubmitPromise}
						saveEnabled={isSaveEnabled()}
						saveButtonTitle="Save All"
					>        

						<DndProvider backend={MouseBackEnd}>
							{createVisible && 
								<Modal>
									<div>Wizard</div>
									<CreateBitwisePointWizard 
										setCreateVisible={setCreateVisible} 
										addBitwise={addBitwise}
										tlmPoint={initialTlmPoint}
										allTlmBitwise={tableData}
									/>
								</Modal> 
							}		

							<MsdTable useMsdTableObj={msdTableObject}
								tableErrors={() => {
									if (tableError.length > 0) {
									return ( 
										<p className={formControlsStyle.nonPaginatedTableMessage}>
											{tableError[0]}
										</p>
									)}
								}}							
							>

								<MoveToPos
									tableData={tableData}
									msdTableObject={msdTableObject}
									nameAttr="tlm_bitwise_name"
									arrayFnOnAdjustedArr={arrayFnOnMove}
									setTableDataFn={setTableData}
								/>

								<CreateButton
									width="10.5rem"
									title="Bitwise Point"
									onClick={() => {
										setCreateVisible(true);
									}}
								/>			
							</MsdTable>		
						</DndProvider>

					</SaveableTab>
				</>
			}


			{editBitwise && 
				<BitwiseEditForm 
					setEditBitwise={setEditBitwise}
					bitwiseEdited={bitwiseEdited}
					initialBitwisePoint={editBitwise}
					allBitwiseForTlm={tableData}
				/>
			}

		</>
	);
};


export default BitwiseTableTabBuildMode;
