import SideBarOption from 'model/SideBarOption';
import { useState, createContext, useContext } from 'react';

const ASTContext = createContext();
export const useASTData = () => useContext(ASTContext);

const ASTContextProvider = ({ children }) => {

	const [confirmationRequest, setConfirmationRequest] = useState(null);
	
	const [sideBarOption, setSideBarOption] = useState(SideBarOption.CmdPacket);
	const [headerTitle, setHeaderTitle] = useState("Command Packets");

	const [submitInProgress, setSubmitInProgress] = useState(false);


	const value = {
		// Page Context
		sideBarOption, 
		setSideBarOption,
		headerTitle, 
		setHeaderTitle,
		
		// Confirmation Delete Handlers 
		confirmationRequest,
		setConfirmationRequest,

		// Submit
		submitInProgress,
		setSubmitInProgress,
	};

	return <ASTContext.Provider value={value}>{children}</ASTContext.Provider>;
};

export default ASTContextProvider;
