// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.versioncompare_pageRootContainer__Lz4Fp {
	display: flex;
	flex-flow: row nowrap;
	height: 100vh;
	width: 100vw;
	max-width: none;
	position: relative;
	z-index: 0;
}

main {
	padding: 1rem;
	width: 100vw;
}

.versioncompare_mainSection__z517X {
	display: flex;
	flex-flow: column nowrap;
	height: 100% !important;

}

.versioncompare_mainHeader__sFHw8{
	flex-shrink: 0;
	flex-grow: 0;
}


.versioncompare_mainBody__9uX0V {
	min-height: 0;
	flex-grow: 1;
}

.versioncompare_snapShotContainer__Juqv3 {
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Translucent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Adjust the z-index as needed */
}`, "",{"version":3,"sources":["webpack://./src/components/version-compare/versioncompare.module.css"],"names":[],"mappings":";;AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,aAAa;CACb,YAAY;CACZ,eAAe;CACf,kBAAkB;CAClB,UAAU;AACX;;AAEA;CACC,aAAa;CACb,YAAY;AACb;;AAEA;CACC,aAAa;CACb,wBAAwB;CACxB,uBAAuB;;AAExB;;AAEA;CACC,cAAc;CACd,YAAY;AACb;;;AAGA;CACC,aAAa;CACb,YAAY;AACb;;AAEA;CACC,eAAe;IACZ,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,iCAAiC;IACvE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,iCAAiC;AACpD","sourcesContent":["\n\n.pageRootContainer {\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n\theight: 100vh;\n\twidth: 100vw;\n\tmax-width: none;\n\tposition: relative;\n\tz-index: 0;\n}\n\nmain {\n\tpadding: 1rem;\n\twidth: 100vw;\n}\n\n.mainSection {\n\tdisplay: flex;\n\tflex-flow: column nowrap;\n\theight: 100% !important;\n\n}\n\n.mainHeader{\n\tflex-shrink: 0;\n\tflex-grow: 0;\n}\n\n\n.mainBody {\n\tmin-height: 0;\n\tflex-grow: 1;\n}\n\n.snapShotContainer {\n\tposition: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5); /* Translucent black background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 9999; /* Adjust the z-index as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageRootContainer": `versioncompare_pageRootContainer__Lz4Fp`,
	"mainSection": `versioncompare_mainSection__z517X`,
	"mainHeader": `versioncompare_mainHeader__sFHw8`,
	"mainBody": `versioncompare_mainBody__9uX0V`,
	"snapShotContainer": `versioncompare_snapShotContainer__Juqv3`
};
export default ___CSS_LOADER_EXPORT___;
