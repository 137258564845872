import React, {useState} from 'react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import Modal from 'components/ui-core/modal/Modal/Modal';

import TableEditButton from 'components/ui-core/buttons/TableEditButton/TableEditButton';
import TableDeleteButton from 'components/ui-core/buttons/TableDeleteButton/TableDeleteButton';

import { useASTData } from 'context/ASTContext';
import { useMetadata, useSaveMetadata} from 'data/queryHooks';

import 'animate.css';
import style from 'App.module.css';

import SideBarOption from 'model/SideBarOption';
import ConfirmationRequest from 'model/ConfirmationRequest';

import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import MsdQueryTable from 'components/ui-core/table/msdTable/MsdQueryTable'
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';
import useSubmitWithWarnings from 'components/ui-core/auth/ProdWriteWarning'
import CreateBoardWizard from './CreateBoardWizard';
import { EditBoardModal } from './EditBoardModal';
import { useAuthContext } from 'components/ui-core/auth/AuthContext';

const BoardVersionsPage = () => {
	const {user, logoutUser} = useAuthContext();
	const [createVisible, setCreateVisible] = useState(false);
	const [editBoard, setEditBoard] = useState(null);	
	const {setConfirmationRequest} = useASTData();

	const {setSideBarOption, setHeaderTitle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.BoardVersions);
		setHeaderTitle("Board Versions");
		localStorage.setItem('lastPage',"/board-versions");
	}, [setSideBarOption,setHeaderTitle]);

	
	const metadataQuery = useMetadata();
	const extractMetadataFromQuery = () => {
		return metadataQuery.data[0].boards? metadataQuery.data[0].boards: [];
	}

	const requestDelConfirmationHandler = (boardRow) => {
		let deleteRequest =  new ConfirmationRequest(
			boardRow,
			async () => performBoardDeletionHandler(boardRow),
			`Delete '${boardRow.name}'`, 
			"Delete",
			"Are you sure you would like continue?",
			null,
			`Board '${boardRow.name}' deleted`,
		);
	
		setConfirmationRequest(deleteRequest);
	}
    const {submitWithAppropriateWarnings} = useSubmitWithWarnings(requestDelConfirmationHandler)

	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Version',
				accessor: 'version',
				Filter: TextInputColumnFilter
			},
			{
				Header: ' ',
				accessor: (boardRow) => {
					return (
						<div className={style.tableButtonContainer}>
							<TableEditButton editHandler={() => setEditBoard(boardRow)}/>
							<TableDeleteButton deletionHandler={() => submitWithAppropriateWarnings(boardRow)}/>
						</div>
					);
				},
				columnType: "buttons"
			},
		],
		[submitWithAppropriateWarnings]
	);

	const  {mutateAsync: updateMetadata} = useSaveMetadata();
	const performBoardDeletionHandler = async (boardToDelete) => {
		const metadataClone = cloneDeep(metadataQuery.data[0])

		metadataClone.boards = metadataClone.boards.filter(board => board.name !== boardToDelete.name);

		return await updateMetadata(metadataClone).then((response) => {
			toast.success(`Board '${boardToDelete.name}' deleted`);

			return response;
		})
	};


	return (
		<MsdMainPage>
			{createVisible && 
				<LoadingWrapper 
					queries={[metadataQuery]}
					onLoad={() => (
						<Modal>
							<CreateBoardWizard 
								setCreateVisible={setCreateVisible}
								metadata={metadataQuery.data[0]}
								userName={user.getUsername()}
							/>
						</Modal> 
					)}				
				/>
			}

			{editBoard && 
				<LoadingWrapper 
					queries={[metadataQuery]}
					onLoad={() => (
						<Modal>
							<EditBoardModal
								setEditBoard={setEditBoard}
								editBoard={editBoard}
								metadata={metadataQuery.data[0]}
								userName={user.getUsername()}
							/>
						</Modal> 
					)}				
				/>
			}

			<MsdQueryTable tableName="BoardVersionPage"
					columns={columns} query={metadataQuery} dataExtractFn={extractMetadataFromQuery}
			>
				<CreateButton
					width="12rem"
					title="Add New Board"
					onClick={() => {
						setCreateVisible(true);
					}}
				/>		
			</MsdQueryTable>
		</MsdMainPage>
	);
}; 

export default BoardVersionsPage;
