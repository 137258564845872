// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.EditorPage_editorPageContainer__Zt7Bu {
    display: flex;
    flex-flow: column nowrap;
    height: 100%
}

.EditorPage_editorToolBarContainer__0o4rP {
    flex-shrink: 0;
    flex-grow: 0;
    min-height: 0;
}

.EditorPage_editorPageBody__sPyee {
    flex-shrink: 1;
    flex-grow: 1;
    min-height: 0;

    display: flex;
    flex-flow: column nowrap;
    height: 100%
}

.EditorPage_tabMenu__sOpHA {
    flex-shrink: 0;
    flex-grow: 0;
    min-height: 0;
}

.EditorPage_tabsContainer__fse5u {
    flex-shrink: 1;
    flex-grow: 1;
    min-height: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/layout/EditorPage/EditorPage.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,wBAAwB;IACxB;AACJ;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;;IAEb,aAAa;IACb,wBAAwB;IACxB;AACJ;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;AACjB","sourcesContent":["\n.editorPageContainer {\n    display: flex;\n    flex-flow: column nowrap;\n    height: 100%\n}\n\n.editorToolBarContainer {\n    flex-shrink: 0;\n    flex-grow: 0;\n    min-height: 0;\n}\n\n.editorPageBody {\n    flex-shrink: 1;\n    flex-grow: 1;\n    min-height: 0;\n\n    display: flex;\n    flex-flow: column nowrap;\n    height: 100%\n}\n\n.tabMenu {\n    flex-shrink: 0;\n    flex-grow: 0;\n    min-height: 0;\n}\n\n.tabsContainer {\n    flex-shrink: 1;\n    flex-grow: 1;\n    min-height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorPageContainer": `EditorPage_editorPageContainer__Zt7Bu`,
	"editorToolBarContainer": `EditorPage_editorToolBarContainer__0o4rP`,
	"editorPageBody": `EditorPage_editorPageBody__sPyee`,
	"tabMenu": `EditorPage_tabMenu__sOpHA`,
	"tabsContainer": `EditorPage_tabsContainer__fse5u`
};
export default ___CSS_LOADER_EXPORT___;
