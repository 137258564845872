import React from 'react';

import {FormRow, Input, TextArea, Select, Checkbox, Spacer} from 'components/ui-core/form/FormControls';
import {getUnitEntryOptions} from 'components/ui-core/utils/MetadataHelper'
import {getCmdParamApplicableTypes} from 'components/ui-core/utils/MetadataHelper'

import { getBlockArrayRowIfRequired } from 'components/ui-core/utils/BlockArrayHelper';

export const CommandParameterFormContent = ({ controlProps, useFormObj, metadata, isWizard, isByteReadOnly }) => {

    const unitOptions = getUnitEntryOptions(metadata); 
	const typeOptions = getCmdParamApplicableTypes();

	const {getValues, watch} = controlProps.useFormObj;
	const isTypeFloat = () => getValues("type") === "float";
	const doesSupportNan = () => getValues("support_nan");
	
	
	watch(["type", "support_nan", "mapped_nan_value"]);

	return (
		<>

			<FormRow rowNum="1">
				<Input style={{width: "30rem"}} title="Command Parameter Name" attr="cmd_param_name" {...controlProps}/>
				<Select title="Type" attr="type" {...controlProps} valueOptions={typeOptions} 
					includeBlank={isWizard} revalidateOnChange={true}/>
			</FormRow>

			<FormRow rowNum="2">
				<TextArea title="Description" attr="description" {...controlProps}/>
			</FormRow>

			<FormRow rowNum="3">
			<div>
				<Input 
				title="Byte Size"
				attr="size_bytes" 
				{...controlProps}/>
				{isByteReadOnly && controlProps.editModeActive && <span style={{border: "6px solid var(--ast-secondary-red-color)", backgroundColor: "var(--ast-secondary-red-color)", color: "white"}}>Note: parameter exist in parent packet</span>}
				</div>
				<Select title="Units" attr="units_short" {...controlProps} entryOptions={unitOptions} includeBlank={true}/>
			</FormRow>

			<FormRow rowNum="4">
				<Input title="Min Value" attr="min_value" {...controlProps} 
					isRequired={isTypeFloat} revalidateOnChange={true} />
				<Input title="Max Value" attr="max_value" {...controlProps} 
					isRequired={isTypeFloat} revalidateOnChange={true} />
				<Input title="Default Value" attr="default_value" {...controlProps}/>
			</FormRow>

			<FormRow rowNum="5">
				{isTypeFloat() &&
					<>
	                    <Checkbox title="Supports NaN" attr="support_nan" {...controlProps} 
							revalidateOnChange={true}/>
						{doesSupportNan() &&
							<>
								<Input title="Mapped NaN Value" attr="mapped_nan_value" 
									{...controlProps} revalidateOnChange={true}/>
								<Spacer />
							</>						
					}
					</>
				}
			</FormRow>

            {/* Block Array */}
            {getBlockArrayRowIfRequired("5", controlProps, useFormObj, isWizard)}            




		</>
	);
};


export default CommandParameterFormContent;
