import {lookupLongUnitValue} from 'components/ui-core/utils/MetadataHelper'

export const createTlmPointFromForm = (formData, useFormObj, metadata) => {
    const {getValues} = useFormObj;

    let tlmPoint = {...formData};

    // Correct values:
    // Units
    tlmPoint["units_long"] = lookupLongUnitValue(metadata, tlmPoint["units_short"]);

    // Limit
    if (!getValues("limit")) {
      tlmPoint["red_low_limit"] = null;
      tlmPoint["red_high_limit"] = null;
      tlmPoint["yellow_low_limit"] = null;
      tlmPoint["yellow_high_limit"] = null;
    }

    // Block Array
  
    if (getValues("type") !== "block_array" &&  getValues("type") !== "var_block_array"){
      tlmPoint["array_element_type"] = null;
      tlmPoint["array_element_size"] = null;
    }

    // Derived
    if (getValues("type") !== "derived") {
      tlmPoint["derived_type"] = null;
      tlmPoint["derived_inputs"] = null;
      tlmPoint["derived_calc_freq"] = null;
      tlmPoint["derived_algorithm"] = null;
      tlmPoint["derived_expression"] = null;
    }

    return tlmPoint; 
}

export default createTlmPointFromForm;