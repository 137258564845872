import style from './ToggleSwitch.module.css';

export const ToggleSwitch = ({ editModeActive, value, onChange, ...props }) => {

	let isOn = value;
	const setOn = (newValue) => {
		isOn = newValue;
		onChange(newValue);
	}

	console.log("ON: " + isOn);
	return (
		<>
			<div>
				{isOn ? (
					<div {...props} className={style.ToggleSwitchDetailedView}>
						<div
							className={style.toggleMask}
							onClick={() => {
								editModeActive && setOn(false);
							}}
						></div>
						<div className={style.ToggleSwitchDetailedViewGroup}>
							<div className={style.ToggleSwitchDetailedViewContainer}>
								<input type="checkbox" id="switch" checked={true} />
								<label htmlFor="switch"></label>
							</div>
							<p>Enabled</p>
						</div>
					</div>
				) : (
					<div {...props} className={style.ToggleSwitchDetailedView}>
						<div
							className={style.toggleMask}
							onClick={() => {
								editModeActive && setOn(true);
							}}
						></div>
						<div className={style.ToggleSwitchDetailedViewGroup}>
							<div className={style.ToggleSwitchDetailedViewContainer}>
								<input type="checkbox" id="switch" checked={false} 
									disabled={true}/>
								<label htmlFor="switch"></label>
							</div>
							<p>Disabled</p>
						</div>
					</div>
				)}
			</div>
		</>
	);
};


export default ToggleSwitch;
