import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import TelemetryPacketFormTab from './TelemetryPacketFormTab';
import TagsTab from 'components/tags/editors/TagsTab'
import TlmPacketAssocTab from './assoc-tab/TlmPacketAssocTab';
import EntityType from 'model/EntityType';

import { useASTData } from 'context/ASTContext';

import {useEditorPage} from 'components/ui-core/page/useEditorPage'
import EditorPage from 'components/ui-core/layout/EditorPage/EditorPage';
import ConfirmationRequest from 'model/ConfirmationRequest';
import SideBarOption from 'model/SideBarOption';

import {useTelemetryPacket, useTelemetryPackets, useTelemetryPoints, useTelemetryPointsOptimized,
		useMetadata, useTags, useSaveTelemetryPacket, useCommandPackets,
		usePhysicallyDeleteTelemetryPacket, useLogicallyDeleteTelemetryPacket} from 'data/queryHooks';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import SummaryBar from 'components/ui-core/common/SummaryBar';
import SummaryContextProvider from 'components/ui-core/common/SummaryContext';


const TelemetryPacketPage = () => {
	// Ensure each Page has it's instance (fresh component tree) otherwise history.push()
	// to the same route will reuse components for other object navigate away from
	let {tlmPacketId} = useParams();
	return <TelemetryPacketPageBody key={tlmPacketId}/>
}

const TelemetryPacketPageBody = () => {
	const {setSideBarOption, setHeaderTitle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.TlmPacket);
		setHeaderTitle(null);
	}, []);

	let {tlmPacketId} = useParams();	

	// Data queries
	const packetByIdQuery = useTelemetryPacket(tlmPacketId);
	const allTlmPacketsQuery = useTelemetryPackets();
	const allTlmPointsQuery = useTelemetryPointsOptimized();
	const metadataQuery = useMetadata();
	const tagsQuery = useTags();
	const allCmdPacketsQuery = useCommandPackets();

	React.useEffect(() => {
		if (packetByIdQuery.data) {
			setHeaderTitle(packetByIdQuery.data?.tlm_packet_name);	
		}	
	}, [packetByIdQuery.data]);

	const  {mutateAsync: physicallyDelete} = usePhysicallyDeleteTelemetryPacket();
	const  {mutateAsync: logicallyDelete} = useLogicallyDeleteTelemetryPacket();
	
	const createDeleteRequest = () => {
		//const physically_delete = shouldPhysicallyDelete(packetByIdQuery.data)
		const physically_delete =true;

		return new ConfirmationRequest(
			tlmPacketId,
			physically_delete ? physicallyDelete : logicallyDelete,
			`Delete '${packetByIdQuery.data?.tlm_packet_name}'`, 
			"Delete",
			"Are you sure you want to delete this telemetry packet?",
			physically_delete ? '/telemetry-packets' : null,
			`Telemetry Packet '${packetByIdQuery.data?.tlm_packet_name}' ${physically_delete ? "deleted" : " flagged for deletion"}`
		)
	}

	const tabDefs = [
		{
			id: "form_tab",
			buttonText: "Telemetry Packet",
			buttonType: "TelemetryPacket",
			editMode: "formEditMode"
		},
		{
			id: "tlm_points_tab",
			buttonText: "Telemetry Points",
			buttonType: "TelemetryPoint",
			editMode: "formEditMode"
		},
		{
			id: "tags_tab",
			buttonText: "Tags",
			buttonType: "Tag",
			editMode: "formEditMode"
		}
	] 

	const {
        currentEditOption,
        selectedTabId,
        editModeActive,
        tabSelectedHandler,
        editModeTransitionHandler,
		formKey
    } = useEditorPage(tabDefs);

	const toolBarDef = 	{
		navButtons: [
			{
				title: "< View All Telemetry Packets",
				width: "15.5rem",
				route: "/telemetry-packets"
			}
		],
		manipulationButtons: {
			mutationButtons: {
				includeEdit: true,
				includeBuildMode: true,
				editModeTransitionHandler: editModeTransitionHandler
			},
			deleteButton: {
				createDeleteRequest: createDeleteRequest
			},
			copyButton: {
			}	
		}
	}

	const  {mutateAsync: saveTelemetryPacket} = useSaveTelemetryPacket();
	const updateEntityPromise = async (updatedPacket) => {
		return await saveTelemetryPacket(updatedPacket)
			.then(refreshedPacket => {
				toast.success(`'${updatedPacket.tlm_packet_name}' updated`);
				return refreshedPacket;
			})
			.catch(error => {
				toast.error(`Telemetry Packet update failed`);
				return Promise.reject(error)
			});				
	}

	const getAssocSummaryJsx = () => {
		return selectedTabId === "tlm_points_tab" 
			? <SummaryBar/>
			: <></>		
	} 

	return (
		<SummaryContextProvider>
			<EditorPage 
				toolBarDef={toolBarDef} currentEditOption={currentEditOption} 
				editModeActive={editModeActive} tabDefs={tabDefs} 
				selectedTabId={selectedTabId} tabSelectedHandler={tabSelectedHandler}
				underlyingEntityQuery = {packetByIdQuery}
				tabControls = {getAssocSummaryJsx}
			>		

				{selectedTabId === "form_tab" && 
					<LoadingWrapper 
						queries={[packetByIdQuery, allTlmPacketsQuery, metadataQuery]}
						onLoad={() => (
							<TelemetryPacketFormTab
								key={formKey}
								updateEntityPromise={updateEntityPromise}
								editModeActive={editModeActive} 
								editModeTransitionHandler={editModeTransitionHandler}
								tlmPacket={packetByIdQuery.data}
								allTlmPackets={allTlmPacketsQuery.data}
								allCmdPacketsQuery={allCmdPacketsQuery}
								metadata={metadataQuery.data[0]} />
						)}
					/>
				}
				{selectedTabId === "tlm_points_tab" && 
					<LoadingWrapper 
						queries={[packetByIdQuery, allTlmPointsQuery]}
						onLoad={() => (
							<TlmPacketAssocTab 
								editModeActive={editModeActive} 
								editModeTransitionHandler={editModeTransitionHandler}
								updateEntityPromise={updateEntityPromise}
								tlmPacket={packetByIdQuery.data}
								allPoints={allTlmPointsQuery.data}/>
						)}
					/>
				}				
				{selectedTabId === "tags_tab" && 
					<LoadingWrapper 
						queries={[packetByIdQuery, tagsQuery]}
						onLoad={() => (
							<TagsTab 
								editModeActive={editModeActive} 
								editModeTransitionHandler={editModeTransitionHandler}
								underlyingEntity={packetByIdQuery.data}
								allTags={tagsQuery.data}
								entityType={EntityType.TlmPacket}/>
						)}
					/>
				}
			
			</EditorPage>
		</SummaryContextProvider>
	);
};

export default TelemetryPacketPage;
