import React from 'react';
import PropTypes from 'prop-types';
import style from './ToolBarCopyButton.module.css';

export const ToolBarCopyButton = ({ title, width, disabled, ...props }) => {
	
	let background = disabled ? '#666666': '#429e55';
	return (
		<>
			<button {...props} style={{ width: width, background: background }} className={style.ToolBarCopyButton}>
			<svg
					id="Edit_Icon"
					data-name="Edit Icon"
					xmlns="http://www.w3.org/2000/svg"
					width="18.408"
					height="21.541"
					viewBox="0 0 18.408 21.541"
				>
					<path
						id="Path_1345"
						data-name="Path 1345"
						d="M25.648,9.619a4.019,4.019,0,0,0,0-5.058,2.753,2.753,0,0,0-4.322,0L20.254,5.817l4.322,5.057Z"
						transform="translate(-8.135 -3.514)"
						fill="#ffffff"
					/>
					<path
						id="Path_1346"
						data-name="Path 1346"
						d="M19.753,12.561l-9.134,10.69a3.722,3.722,0,0,1-1.775,1.176l-3.5,1.025a.692.692,0,0,1-.726-.235,1,1,0,0,1-.2-.849l.876-4.1a4.71,4.71,0,0,1,1-2.077L15.431,7.5l4.322,5.058Z"
						transform="translate(-4.393 -3.937)"
						fill="#ffffff"
					/>
				</svg>
				{title}
			</button>
		</>
	);
};

ToolBarCopyButton.propTypes = {
	title: PropTypes.string.isRequired,
	width: PropTypes.string.isRequired,
};

ToolBarCopyButton.defaultProps = {
	title: 'Copy Packet',
	width: '8.5rem',
};

export default ToolBarCopyButton;
