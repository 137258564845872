// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tag_tagContainer__ixYiW {
	display: inline-block;
}

.Tag_tag__WNjnM {
	background: rgba(255, 199, 89, 50%);
	border: 0.1rem solid var(--ast-primary-orange-1-color);
	height: 2rem;
	border-radius: 0.5rem;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-around;
	padding: 0 0.1rem;
}

.Tag_tag__WNjnM svg {
	height: 1rem;
	width: 1rem;
}

.Tag_tag__WNjnM p {
	font-weight: 500;
}

.Tag_addTagButton__56OV\\+ {
	padding: 0;
	border: none;
	cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/tags/Tag.module.css"],"names":[],"mappings":"AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,mCAAmC;CACnC,sDAAsD;CACtD,YAAY;CACZ,qBAAqB;CACrB,aAAa;CACb,qBAAqB;CACrB,mBAAmB;CACnB,6BAA6B;CAC7B,iBAAiB;AAClB;;AAEA;CACC,YAAY;CACZ,WAAW;AACZ;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,UAAU;CACV,YAAY;CACZ,eAAe;AAChB","sourcesContent":["@import 'App.module.css';\n\n.tagContainer {\n\tdisplay: inline-block;\n}\n\n.tag {\n\tbackground: rgba(255, 199, 89, 50%);\n\tborder: 0.1rem solid var(--ast-primary-orange-1-color);\n\theight: 2rem;\n\tborder-radius: 0.5rem;\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n\talign-items: center;\n\tjustify-content: space-around;\n\tpadding: 0 0.1rem;\n}\n\n.tag svg {\n\theight: 1rem;\n\twidth: 1rem;\n}\n\n.tag p {\n\tfont-weight: 500;\n}\n\n.addTagButton {\n\tpadding: 0;\n\tborder: none;\n\tcursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagContainer": `Tag_tagContainer__ixYiW`,
	"tag": `Tag_tag__WNjnM`,
	"addTagButton": `Tag_addTagButton__56OV+`
};
export default ___CSS_LOADER_EXPORT___;
