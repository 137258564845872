import React from 'react';
import { useHistory } from 'react-router-dom';

import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable'

import { getPacketSummaryItems } from 'components/ui-core/common/PacketSummaryHelper';
import { useSummmaryContext } from 'components/ui-core/common/SummaryContext';

import { doesEntityContainTag } from 'components/tags/TagHelper';
import ManagedTag from 'model/ManagedTag';
import { getTelemetryPointAssocsColumns } from 'components/tlm-point/TelemetryPointTableHelper';

export const TlmPacketAssocTabViewMode = ({ tlmPacket, allPoints}) => {

	let sequenceNo = 0;
	const tableData = React.useMemo(
		() => {
			return tlmPacket.fields
				.map(pointName => {
					const tlmPoint = allPoints.find(point => point.tlm_point_name === pointName);
					if (tlmPoint) {
						const isLogicallyDeleted = doesEntityContainTag(tlmPoint, ManagedTag.DELETED.name);
						return isLogicallyDeleted
							? {
								...tlmPoint,
								sequence: null
							}
							: {
								...tlmPoint,
								sequence: sequenceNo++
							};				
					} else {
						return {
							tlm_point_name: pointName + "(deleted)",
							sequence: sequenceNo++
						}	
					}
				}
			)			
		},
		[tlmPacket]
	)

	const history = useHistory();
	const viewTlmPointHandler = (tlmPointRow) => {
		history.push(`/telemetry-point/${tlmPointRow._id}`);
	};
	const columns = React.useMemo(() => getTelemetryPointAssocsColumns(viewTlmPointHandler), []);

    const {setSummaryItems} =  useSummmaryContext();
	React.useEffect(() => {
			setSummaryItems(getPacketSummaryItems(tableData, "Telemetry Point"));
		}, 
		[tableData]
	);

	return (
		<>
			<ManagedMsdTable tableName="TlmPacketAssocTabViewMode" columns={columns} data={tableData} />		
		</>
	);
};

export default TlmPacketAssocTabViewMode;
