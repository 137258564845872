import React, { useEffect, useState } from 'react';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import OptionFormContent from './LimitOptionFormContent'

export const CreateLimitOptionWizard = ({ createAddOptionPromise, setCreateVisible, schema, entityType, optionsData , maxOptionValue, optionsType}) => {
	const [create, setCreate] = useState(schema);
	const {useFormObj, controlProps} = useWizardForm({}, create);
	const {watch, getValues} = useFormObj;
	watch("name");
	const title = "Create " + optionsType;
	return (
		<Wizard width='20rem'>
			<WizardForm title={title}
				setWizardVisible={setCreateVisible} useFormObj={useFormObj} 
				createSubmitPromise={createAddOptionPromise}
			>
				<OptionFormContent controlProps={controlProps} entityType={entityType} optionsType={optionsType} formType={'Create'} optionsData={optionsData} entryType={"Create"} />
			</WizardForm>
		</Wizard>		
	);
};


export default CreateLimitOptionWizard;
