import React, {useState} from 'react';
import {getIsoFormattedDateColumn} from 'components/ui-core/table/TableHelper'
import style from 'App.module.css';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable';
import SelectInputColumnFilter from 'components/ui-core/table/filter/SelectInputColumnFilter';
import StatusColumnFilter from 'components/ui-core/table/filter/StatusColumnFilter';
import { PushReportModal } from './PushReportModal';
import { STATUS_TO_STYLES_MAP, NON_TERMINAL_STATES } from 'components/review/PushJobHelper'; 
import { useASTData } from "context/ASTContext";
import { useCancelPushJob } from 'data/queryHooks';
import { toast } from 'react-toastify';
import ConfirmationRequest from 'model/ConfirmationRequest';

export const ReviewPushLogTabContent = ({pushJobs}) => {
	const [pushJob, setPushJob] = useState(null)
	const { setConfirmationRequest,} = useASTData();

    const viewPushJob = (pushJob) => {
        setPushJob(pushJob)
    }


	const  {mutateAsync: cancelPushJob} = useCancelPushJob();
	const cancelPushPromise = async (pushJob) => {
		return await cancelPushJob(pushJob["_id"])
			.catch(error => {
				toast.error("Error cancelling task");
				return Promise.reject(error)
			});				
	}

    const requestCancelPushConfirmationHandler = (pushJob) => {
		const message = 
			<div>
				This task may still be running
				<br/>
				<br/>
				Cancel it if you suspect it has stalled (e.g. if the server went down during processing) so that
                further 'Push to Production' tasks can be started
				<br/>
				<br/>
				Cancelling could leave the production database in an inconsistent state. If you cancel, you should
                attempt to re-push the staged changes to ensure consistency
				<br/>
				<br/>
				Are you sure you wish to cancel the task?
			</div>

		let request =  new ConfirmationRequest(
			pushJob,
			async () => cancelPushPromise(pushJob),
			`Cancel Push Task`, 
			"Cancel Push Task",
			message,
            null,
			`Push to Production task successfully cancelled`,
            "Don't Cancel"
		);
	
		setConfirmationRequest(request);
	}


	const columns = React.useMemo(
		() => [
            getIsoFormattedDateColumn('created_on', 'Created Time'),
			{
                Header: 'Created By',
                accessor: 'instigated_by',
				Filter: SelectInputColumnFilter,
				filter: 'includes'
		
			},
			{
                id: 'status',
                accessor: 'status',
                Header: 'Status',
                Cell: (tableInstance) => {
                    const {row} = tableInstance;
                    const styleClass = STATUS_TO_STYLES_MAP[row.original.status]["tableStyle"]

                    return (
                        <div className ={styleClass}>      
                            {row.original.status}                            
                        </div>
                    );
                },
				Filter: StatusColumnFilter,
				filter: 'includes',		
			},
            {
                id: "progress",
                Header: "Progress",
                accessor: pushJob => {
                    const total = pushJob.total_tasks

                    if (total) {
                        const attempted = pushJob.tasks_attempted
                        const percent = 100 * attempted / total
                        return `Tasks Completed: ${attempted} of ${total} (${Math.round(percent)}%)`       
                    } else if (!NON_TERMINAL_STATES.includes(pushJob.status)) {
                        return "Unknown"
                    } else {
                        return "Calculating ..."
                    }
                },            
            },
            {
				Header: ' ',
				accessor: (pushJob) => {
					return (
                        <>
                            {pushJob && pushJob.result && 
                                <div>
                                    <button className={style.tableTextButton} 
                                        onClick={() => viewPushJob(pushJob)}
                                    >
                                        View Report
                                    </button>
                                </div>
                            }
                            {pushJob && NON_TERMINAL_STATES.includes(pushJob.status) && 
                                <div>
                                    <button className={style.tableTextButton} 
                                        onClick={() => requestCancelPushConfirmationHandler(pushJob)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            }
                        </>
					);
				}
			}
		],
		[pushJob]
	);

    return (
        <>
			{pushJob && <PushReportModal pushJob={pushJob} setPushJob={setPushJob}/>}        

            <ManagedMsdTable tableName="ReviewPushLogTabContent" columns={columns} data={pushJobs} />
        </>
    );
};

export default ReviewPushLogTabContent;