import React, { useEffect, useState } from 'react';

import { getVersionCompareSchema} from 'model/form-schema/ObjectSchemas'

import useFormTab from 'components/ui-core/form/useFormTab';
import { VersionCompareFormContent } from './VersionCompareFormContent';
import style from './versioncompare.module.css'
import SideBar from 'components/ui-core/layout/SideBar/SideBar';
import Header from 'components/ui-core/layout/Header/Header';
import ConfirmationModal from 'components/ui-core/modal/ConfirmationModal/ConfirmationModal';
import VersionFormTab from 'components/ui-core/form/versionCompareTab';
import useDataService from 'data/useDataService';
import { toast } from 'react-toastify';

export const VersionComparePage = ({versionData}) => {
    const spacecrafts= versionData.map(ele => ele['spacecraft_name'] );
	console.log(spacecrafts)
	const defaultSchema = getVersionCompareSchema();
	const [schema, setSchema] = useState(defaultSchema);
	const {useFormObj, controlProps} = useFormTab(
		{
			spacecraft_a: "", 
            spacecraft_b: "",
		}, 
		schema, 
		true);
    const {watch, getValues} = useFormObj;
	watch("spacecraft_a")
    const {compareMsdVersions} = useDataService();

	const createSubmitPromise = async (formData) => {
        console.log(formData);
        toast.warning('Processing..., File Will Download in Few Minutes');
	    const result = await compareMsdVersions(formData.spacecraft_a, formData.version_a, formData.spacecraft_b, formData.version_b);
        console.log(result.length());
        return result;

	};
    
    return (
    <>
        <div id="MsdMainPageRoot" className={style.pageRootContainer}>
        
            
                  <SideBar/>
            
                  <main id="MainPage" className={style.mainSection}>
                      <div className={style.mainHeader}>
                          <Header title={'MSD Version Compare'} />
                      </div>
                      <div id="mainBody" className={style.mainBody}>
                      <VersionFormTab editModeActive={true}
                              editModeTransitionHandler={null}
                              createSubmitPromise={createSubmitPromise}
                              useFormObj={useFormObj}>
        
                          <VersionCompareFormContent 
                              controlProps={controlProps} 
                              useFormObj={useFormObj}					
                              isWizard={false}
                              spacecrafts={spacecrafts}
                              allVersions = {versionData}
                          />				
                      </VersionFormTab>
                      </div>
                  </main>
                  
                  <ConfirmationModal />
              </div>        
          
        
        </>
        );
};
export default VersionComparePage;

























