import React from 'react';

import {usePageState} from 'components/ui-core/page/usePageState';

export function AlertStatusColumnFilter({ column: { id, filterValue, setFilter }, tableName}) {
	const options = React.useMemo(() => {
		const options = new Set(['active', 'inactive']);
		return [...options.values()].sort();
	}, []);

	const {setFilterValue} = usePageState();

	return (
		<select
            style={{ marginRight: '2rem', backgroundColor: "white"  }}
			value={filterValue}
			onChange={(e) => {
				const newFilterValue = e.target.value || undefined
				setFilterValue(tableName, id, newFilterValue)
				setFilter(newFilterValue);
			}}
        >
            <option value="">Select a value</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
		</select>
	);
}

export const filterStatus = (rows, columnId, filterValue) => {
    const filterState = filterValue === 'active' ? true : false;
	const result = rows.filter(row => {
		return row.original.active === filterState;
	})

	return result;
}

export default AlertStatusColumnFilter;