// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainButton_mainButton__p8WfT {
	font-size: 1rem;
	font-weight: 500;
	width: 5.5rem;
	height: 1.813rem;
	border-width: 0.063rem;
	color: #ffffff;
	border: none;
	font-weight: bold;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
	box-shadow: 0rem 0.2rem 0rem 0rem #d77306;
	background: var(--ast-primary-orange-color);
	cursor: pointer;
}

.MainButton_mainButtonContainer__dPbe8 {
	margin: 0rem 0rem 0.3rem 0rem;
}


.MainButton_mainButton__p8WfT:hover {
	background: #d77306;
}


.MainButton_mainButton__p8WfT:disabled {
	background: var(--ast-disabled-orange-color);
	box-shadow: 0rem 0.2rem 0rem 0rem #695339;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/buttons/MainButton/MainButton.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,gBAAgB;CAChB,aAAa;CACb,gBAAgB;CAChB,sBAAsB;CACtB,cAAc;CACd,YAAY;CACZ,iBAAiB;CACjB,8BAA8B;CAC9B,+BAA+B;CAC/B,iCAAiC;CACjC,kCAAkC;CAClC,yCAAyC;CACzC,2CAA2C;CAC3C,eAAe;AAChB;;AAEA;CACC,6BAA6B;AAC9B;;;AAGA;CACC,mBAAmB;AACpB;;;AAGA;CACC,4CAA4C;CAC5C,yCAAyC;AAC1C","sourcesContent":[".mainButton {\n\tfont-size: 1rem;\n\tfont-weight: 500;\n\twidth: 5.5rem;\n\theight: 1.813rem;\n\tborder-width: 0.063rem;\n\tcolor: #ffffff;\n\tborder: none;\n\tfont-weight: bold;\n\tborder-top-left-radius: 0.3rem;\n\tborder-top-right-radius: 0.3rem;\n\tborder-bottom-left-radius: 0.3rem;\n\tborder-bottom-right-radius: 0.3rem;\n\tbox-shadow: 0rem 0.2rem 0rem 0rem #d77306;\n\tbackground: var(--ast-primary-orange-color);\n\tcursor: pointer;\n}\n\n.mainButtonContainer {\n\tmargin: 0rem 0rem 0.3rem 0rem;\n}\n\n\n.mainButton:hover {\n\tbackground: #d77306;\n}\n\n\n.mainButton:disabled {\n\tbackground: var(--ast-disabled-orange-color);\n\tbox-shadow: 0rem 0.2rem 0rem 0rem #695339;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainButton": `MainButton_mainButton__p8WfT`,
	"mainButtonContainer": `MainButton_mainButtonContainer__dPbe8`
};
export default ___CSS_LOADER_EXPORT___;
