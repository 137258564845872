// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LimitOptionsTable_snapShotContainer__eYBAR {
	background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 20px;
}
  
  /* Style for the loading container */`, "",{"version":3,"sources":["webpack://./src/components/alerts/limitOptions/LimitOptionsTable.module.css"],"names":[],"mappings":"AAEA;CACC,sBAAsB;IACnB,uCAAuC;IACvC,mBAAmB;IACnB,aAAa;AACjB;;EAEE,oCAAoC","sourcesContent":["@import 'App.module.css';\n\n.snapShotContainer {\n\tbackground-color: #fff;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    border-radius: 20px;\n    padding: 20px;\n}\n  \n  /* Style for the loading container */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"snapShotContainer": `LimitOptionsTable_snapShotContainer__eYBAR`
};
export default ___CSS_LOADER_EXPORT___;
