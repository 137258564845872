import { isEmpty} from 'lodash'; 

import React from 'react';

import style from './Form.module.css';
import MainButton from 'components/ui-core/buttons/MainButton/MainButton';
import EditModeTransition from 'model/EditModeTransition';
import { useASTData } from "context/ASTContext";
import useSubmitWithWarnings from 'components/ui-core/auth/ProdWriteWarning'

export const VersionFormTab = ({editModeActive, editModeTransitionHandler, useFormObj, createSubmitPromise, 
        saveButtonTitle = "Download Results", saveButtonWidth = '15.5rem', customSubmitEnabledFn, ...props} ) => {
    
    const {setSubmitInProgress} = useASTData();

    const {
		handleSubmit,
		formState: { isDirty, isValid, errors}
	} = useFormObj;


    let isEnabled = customSubmitEnabledFn 
        ? customSubmitEnabledFn()
        : isDirty && isValid;
        
    console.log(`isDirty: ${isDirty}, isValid: ${isValid}, enabled: ${isEnabled}`)
    if (!isEmpty(errors)) {
        console.error("Validation Errors", errors);
    }

    const submitWrapper = (event) => {
        // If we're not in edit mode the quit now (there are some page with buttons in the form that
        // cause this method to be called)
        if (!editModeActive) {
            return false
        }

        setSubmitInProgress(true);
        const submitPromise = createSubmitPromise(event);
        submitPromise
            .then(() => {
                setSubmitInProgress(false);
                editModeTransitionHandler(false)
            })
            .catch(() => {
                setSubmitInProgress(false);
            })
    }

    const {submitWithAppropriateWarnings} = useSubmitWithWarnings(submitWrapper)

	return ( 
        <form onSubmit={handleSubmit(submitWithAppropriateWarnings)} className={style.msdForm}>
            <div id="saveContainer" className={style.formTabContainer}>
                {props.children}

                {editModeActive &&
                    <div id="saveButtons" className={style.formTabButtonsContainer}>
                        <></>
                        <MainButton 
                            width={saveButtonWidth}
                            title={saveButtonTitle} 
                            type="submit"
                            disabled= {!isEnabled}
                            onSubmit={() => {handleSubmit(submitWithAppropriateWarnings)}}
                        />
                    </div>
                }
            </div>
        </form>  
	);
};

export default VersionFormTab;
