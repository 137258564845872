
class EditModeTransition {
    // Create new instances of the same class as static attributes
    static LaunchEdit = new EditModeTransition("LaunchEdit", true);
    static Cancel = new EditModeTransition("Cancel", false);
    static EditSuccess = new EditModeTransition("EditSuccess", false);
  
    constructor(name, resultantEditMode) {
      this.name = name;
      this.resultantEditMode = resultantEditMode;
    }
  }

  export default EditModeTransition;