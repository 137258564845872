// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Option_optionContainer__qzN7F {
	display: inline-block;
}

.Option_option__ZvuW7, 
.Option_severity__GIl5M {
	padding: 2px 8px;
	border: 0.1rem solid var(--ast-primary-orange-1-color);
	border-radius: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/alerts/limitOptions/Option.module.css"],"names":[],"mappings":"AAEA;CACC,qBAAqB;AACtB;;AAEA;;CAEC,gBAAgB;CAChB,sDAAsD;CACtD,qBAAqB;AACtB","sourcesContent":["@import 'App.module.css';\n\n.optionContainer {\n\tdisplay: inline-block;\n}\n\n.option, \n.severity {\n\tpadding: 2px 8px;\n\tborder: 0.1rem solid var(--ast-primary-orange-1-color);\n\tborder-radius: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionContainer": `Option_optionContainer__qzN7F`,
	"option": `Option_option__ZvuW7`,
	"severity": `Option_severity__GIl5M`
};
export default ___CSS_LOADER_EXPORT___;
