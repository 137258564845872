import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ASTContextProvider from './context/ASTContext';
import { QueryClient, QueryClientProvider } from 'react-query';

//Amplify imports
import { Amplify} from 'aws-amplify';
import { awsConfig } from 'config/AWSCognitoConfig';

Amplify.configure(awsConfig);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			cacheTime: 1800000,
			refetchOnMount: true,
			refetchOnWindowFocus: false,
			refetchIntervalInBackground: false,
			notifyOnChangeProps: ['data', 'error']
		}
	},
});


ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<ASTContextProvider>
				<App/>
			</ASTContextProvider>
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
