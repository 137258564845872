import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';

import {getAlertSchema} from 'model/form-schema/ObjectSchemas'
import {useCreateAlert} from 'data/queryHooks';
import AlertFormContent from '../alert-page/AlertFormContent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export const CreateAlertWizard = ({setCreateVisible, allAlerts, metadata, tlmPointLite, tlmPackets}) => {

	const defaultSchema = getAlertSchema(allAlerts, null, false);
	const [schema, setSchema] = useState(defaultSchema);
	const {useFormObj, controlProps} = useWizardForm({}, schema);
	const packetNames =  tlmPackets.map(row => row['tlm_packet_name']);
	const tlmNames =  tlmPointLite.map(row => row.tlm_point_name);
	const  {mutateAsync: addAlert} = useCreateAlert(); 
	const {watch,setValue} = useFormObj;

	const tlm_point_names = useFormObj.getValues()['tlm_name']
	const board_names = useFormObj.getValues()['board']

	watch("tlm_name");
	watch("board");
	useEffect(() => {
		const formValues = useFormObj.getValues();
		if(formValues['tlm_name'] ){
			formValues['tlm_id'] = tlmPointLite?.find(ele => ele['tlm_point_name'] == formValues['tlm_name'])['_id'];
			formValues['packet'] = tlmPackets.filter(ele => ele['fields'].includes(formValues['tlm_name'])).map(ele => ele['tlm_packet_name']);
			setValue("tlm_id", formValues['tlm_id'], {
                shouldValidate: true,
                shouldDirty: true
            });
			setValue("packet", formValues['packet'], {
                shouldValidate: true,
                shouldDirty: true
            });
		}
		setSchema(getAlertSchema(allAlerts , formValues ,  true))
	}, [tlm_point_names, board_names, allAlerts, setValue, tlmPointLite, tlmPackets, useFormObj]);


	const findLimitTypeFromTlmPoint = async (formData) => {
		const tlm_point = tlmPointLite.find(ele => ele['_id'] == formData.tlm_id);
		if(tlm_point['type'] == 'float' || (!tlm_point['list'] || tlm_point['list'].length === 0)){
			return "non-discrete";
		}
		else if(tlm_point['list'].length > 0){
			return "discrete";
		}
	}


	const createSubmitPromise = async (formData) => {
		const limitType = await findLimitTypeFromTlmPoint(formData);
		let newAlertRow = {
			...formData,
			active: formData.active? formData.active: false,
			thresholds: limitType == 'non-discrete' ? [
				{
					sc_mode: "default",
					red_low: 0,
					yellow_low: 0,
					yellow_high: 0,
					red_high: 0,
				}
			  ] : null,
			enums: limitType == 'discrete' ? [
				{
					sc_mode: "default",
					discrete_values: [
						{
							name:"",
							value: 0,
							severity: ""
						}
					]
				}
			] : null
		};


		return await addAlert(newAlertRow)
			.then(response => {
				toast.success(`Alert '${newAlertRow.alert_name}' created`);
				return response;
			})
			.catch(error => {
				toast.error(`Alert creation failed: ${error.message}`);
				return Promise.reject(error)
			});

	};

	const history = useHistory();
	const viewPoint = () => {
		const formValues = useFormObj.getValues();
		history.push(`/telemetry-point/${formValues['tlm_id']}`, null);
	}

	return (
		<Wizard width='80rem'>
			<WizardForm title="Create Alert" getObjectbyIdRoutePrefix='/alert'
				setWizardVisible={setCreateVisible} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
			>
				<AlertFormContent 
					controlProps={controlProps} 
					useFormObj={useFormObj}
					metadata={metadata}
					isWizard={true}
					isByteReadOnly={false}
					allAlerts = {allAlerts}
					tlmNames = {tlmNames}
					packetNames = {packetNames}
					viewPoint={viewPoint}
				/>
			</WizardForm>
		</Wizard>
	);
};

export default CreateAlertWizard;
