
import 'animate.css';
import style from 'App.module.css';

import SelectInputColumnFilter from 'components/ui-core/table/filter/SelectInputColumnFilter';
import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';

import {TagColumnFilter, filterTag} from 'components/ui-core/table/filter/TagColumnFilter';
import TagPanel from "components/tags/TagPanel";
import NodeColumnFilter from './filter/NodeColumnFilter';

export const subsytemColumn = () => {
    return {
        Header: 'Subsystem',
        accessor: 'subsystem_name',
        Filter: SelectInputColumnFilter,
        filter: 'includes',
    }
}

export const nodeCmdColumnFilter = () => {
    return {
        Header: 'Destination Node',
        accessor: (packet) => {
            if (packet.destination_group) {
                return packet.destination_group.filter(nodeEntry => nodeEntry.node).map(node => node.node).join(', ');
            }

            return null; 
        },
        Filter: NodeColumnFilter,
        filter: 'includes',
    }
}

export const nodeTlmColumnFilter = () => {
    return {
        Header: 'Source Node',
        accessor: (packet) => {
            if (packet.source_group) {
                return packet.source_group.filter(nodeEntry => nodeEntry.node).map(node => node.node).join(', ');
            }

            return null; 
        },
        Filter: NodeColumnFilter,
        filter: 'includes',
    }
}

export const nameColumn = (nameAttribute, header_name="Name") => {
    return {
        Header: header_name,
        accessor: nameAttribute,
        Filter: TextInputColumnFilter
    }
}

export const descriptionColumn = () => {
    return {
        Header: 'Description',
        accessor: 'description'
    }
}

export const cspPortColumn = () => {
    return {
        Header: 'CSP Port No.',
        accessor: 'csp_port'
    }
}

export const createdByUserNameColumn = () => {
    return {
        Header: 'Created By',
        accessor: 'created_by'
    }
}

export const getIsoFormattedDateColumn = (dateAttr, headerText) => {
    return {
        id: dateAttr,
        Header: headerText,
        accessor: row => {
            const dateValue = row[dateAttr]
            if (dateValue) {
                try {
                    const date = new Date(dateValue);
                    return date.toISOString();
                }
                catch (err){
                    console.log(`dateValue: ${dateValue}`);
                    return dateValue;
                }
            } else {
                return null;
            }
        }
    }
}

export const creationDateColumn = () => {
    return getIsoFormattedDateColumn('created_on', 'Created')
}

export const getTagColumn = (header_name="Tags") => {
    return {
        id: 'tags',
        Header: header_name,
        Cell: (tableInstance) => {
            const {row} = tableInstance;
            return (
                <div>      
                    <TagPanel tagArr={row.original.environment_tags} />
                </div>
            );
        },
        Filter: TagColumnFilter,
        filter: filterTag
    }
}


export const lastModifiedUserNameColumn = () => {
    return {
        Header: 'Last Modified By',
        accessor: 'modified_by'
    }
}

export const lastModifiedDateColumn = () => {
    return getIsoFormattedDateColumn('modified_time', 'Last Modified')
}

export const viewButtonColumn = (viewHandler) => {
    return     {
        id: "View",
        Header: ' ',
        accessor: (data) => {
            return (
                <div>
                    <button className={style.tableTextButton} 
                        onClick={() => viewHandler(data)}
                    >
                        View
                    </button>
                </div>
            );
        }
    }
}
