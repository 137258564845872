// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditorToolBar_toolbarContainer__qxEaK {
	background: #eae7e7;
	padding: 1rem;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.EditorToolBar_buttonContainer__WtiZX {
	display: flex;
	flex-flow: row nowrap;
	margin-left: auto;
	gap: 10px;
	justify-content: space-between;
}

/* Responsive website scaling for 1920 x 1080 screens */

@media screen and (max-width: 1920px) {
	.EditorToolBar_toolbarContainer__qxEaK button {
		font-size: 0.9rem;
	}
}



`, "",{"version":3,"sources":["webpack://./src/components/ui-core/toolbar/EditorToolBar/EditorToolBar.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,aAAa;CACb,aAAa;CACb,qBAAqB;CACrB,8BAA8B;AAC/B;;AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,iBAAiB;CACjB,SAAS;CACT,8BAA8B;AAC/B;;AAEA,uDAAuD;;AAEvD;CACC;EACC,iBAAiB;CAClB;AACD","sourcesContent":[".toolbarContainer {\n\tbackground: #eae7e7;\n\tpadding: 1rem;\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n\tjustify-content: space-between;\n}\n\n.buttonContainer {\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n\tmargin-left: auto;\n\tgap: 10px;\n\tjustify-content: space-between;\n}\n\n/* Responsive website scaling for 1920 x 1080 screens */\n\n@media screen and (max-width: 1920px) {\n\t.toolbarContainer button {\n\t\tfont-size: 0.9rem;\n\t}\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarContainer": `EditorToolBar_toolbarContainer__qxEaK`,
	"buttonContainer": `EditorToolBar_buttonContainer__WtiZX`
};
export default ___CSS_LOADER_EXPORT___;
