// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.TintedPage_red__IFtl7 {
	opacity: 0.1;
	background: red;
	position: fixed;
	width: 100%;
	height: 100%;
}

.TintedPage_green__fWWGb {
	opacity: 0.1;
	background: green;
	position: fixed;
	width: 100%;
	height: 100%;
}

.TintedPage_blue__qSx0b {
	opacity: 0.1;
	background: blue;
	position: fixed;
	width: 100%;
	height: 100%;
}

.TintedPage_childLayer__eHhB9 {
	position: fixed;
    width: 100%;
    height: 100%;
}


.TintedPage_container__PBw3s {
	position: relative;
}



`, "",{"version":3,"sources":["webpack://./src/components/ui-core/page/TintedPage.module.css"],"names":[],"mappings":";;AAEA;CACC,YAAY;CACZ,eAAe;CACf,eAAe;CACf,WAAW;CACX,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,iBAAiB;CACjB,eAAe;CACf,WAAW;CACX,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,gBAAgB;CAChB,eAAe;CACf,WAAW;CACX,YAAY;AACb;;AAEA;CACC,eAAe;IACZ,WAAW;IACX,YAAY;AAChB;;;AAGA;CACC,kBAAkB;AACnB","sourcesContent":["\n\n.red {\n\topacity: 0.1;\n\tbackground: red;\n\tposition: fixed;\n\twidth: 100%;\n\theight: 100%;\n}\n\n.green {\n\topacity: 0.1;\n\tbackground: green;\n\tposition: fixed;\n\twidth: 100%;\n\theight: 100%;\n}\n\n.blue {\n\topacity: 0.1;\n\tbackground: blue;\n\tposition: fixed;\n\twidth: 100%;\n\theight: 100%;\n}\n\n.childLayer {\n\tposition: fixed;\n    width: 100%;\n    height: 100%;\n}\n\n\n.container {\n\tposition: relative;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"red": `TintedPage_red__IFtl7`,
	"green": `TintedPage_green__fWWGb`,
	"blue": `TintedPage_blue__qSx0b`,
	"childLayer": `TintedPage_childLayer__eHhB9`,
	"container": `TintedPage_container__PBw3s`
};
export default ___CSS_LOADER_EXPORT___;
