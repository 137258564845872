

export const Row = ({row, index, getCustomRowProps, ...props}) => {

    return (
        <tr {...props} {...row.getRowProps(getCustomRowProps(row))}>
            {row.cells.map((cell, j) => {

                let style = {};
                if (cell.column.columnType === "buttons") {
                    style["padding"] = "2px 6px";
                } else if (cell.column.id === "selection") {
                    style["padding"] = "0px";
                }

                if (cell.column.id === "selection" && row.original.dirty) {
                    style["borderLeft"] =  "6px solid var(--ast-disabled-orange-color)";
                }

                if (row.original.dirty) {
                    style["background"] =  "var(--ast-primary-orange-3-color)";
                }

                if (row.original.endBoundary) {
                    style["borderBottom"] =  "2px solid var(--ast-secondary-blue-color)";
                }

                return (
                    <td key={j} {...cell.getCellProps({style})}>
                        {cell.render('Cell')}
                    </td>
                );
            })}
        </tr>
    );    
}

export default Row;