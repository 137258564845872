import React, { useState } from 'react';
import {usePageState} from 'components/ui-core/page/usePageState';
import { useMetadata } from 'data/queryHooks';

function NodeColumnFilter({ column: { id, filterValue, setFilter, preFilteredRows }, tableName }) {
	// Calculate the options for filtering
	// using the preFilteredRows
	//Node Filter Fix , Will wait for metadata api , if not assign empty 
	const [options, setOptions] = useState([]);
	const metadataQuery = useMetadata();
	React.useEffect(() => {
		if (metadataQuery.data) {
			if (metadataQuery.data.length != 0) {
				const nodes = metadataQuery.data[0].nodes.map(nodeEntry => nodeEntry.node);
				let optionsSet = new Set();
				nodes.forEach((row) => {
					optionsSet.add(row);
				});
				optionsSet = [...optionsSet.values()].sort();
				setOptions(optionsSet);
			}
		}
		
	}, [metadataQuery.data]);

	const {setFilterValue} = usePageState();

	// Render a multi-select box
	return (
		<select
            style={{ width:'14rem', marginRight: '2rem', backgroundColor: "white" }}
			value={filterValue}
			onChange={(e) => {
				const newFilterValue = e.target.value || undefined
				setFilterValue(tableName, id, newFilterValue)
				setFilter(newFilterValue);
			}}
            >
			<option value="">Select a value</option>
			{options.map((option, i) => (
				<option key={i} value={option}>
					{option}
				</option>
			))}
		</select>
	);
}

export default NodeColumnFilter;