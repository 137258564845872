import React, {useState} from 'react';
import { toast } from 'react-toastify';

import Modal from 'components/ui-core/modal/Modal/Modal';

import TableEditButton from 'components/ui-core/buttons/TableEditButton/TableEditButton';
import TableDeleteButton from 'components/ui-core/buttons/TableDeleteButton/TableDeleteButton';

import { useASTData } from 'context/ASTContext';
import { useCreateDevVersion, useDeleteDevVersion, useMetadata, useSaveMetadata, useSCVersioning} from 'data/queryHooks';

import 'animate.css';
import style from 'App.module.css';

import ConfirmationRequest from 'model/ConfirmationRequest';

import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import MsdQueryTable from 'components/ui-core/table/msdTable/MsdQueryTable'
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';
import useSubmitWithWarnings from 'components/ui-core/auth/ProdWriteWarning'
import { Fragment } from 'react';
import { useEffect } from 'react';
import Wizard from 'components/ui-core/modal/Wizard';
import CloseButton from 'components/ui-core/buttons/CloseButton/CloseButton';
import { EditSnapShotModal } from './editSnapShotModal';
import { useBranchData } from './BranchContext';
import { useHistory } from 'react-router-dom';


const SnapshotTable = ({ setCreateVisible, userName}) => {
	const [editSnapShot, setEditSnapShot] = useState(null);	
	const {setConfirmationRequest} = useASTData();
	const { totalDbList, setTotalDbList,  dbList, setDbList} = useBranchData();
    setDbList(totalDbList);
    const [dbs, setdbs] = useState(dbList);
    const [loading, setLoading] = useState(false);    
	const history = useHistory()

	const metadata_query = useSCVersioning();
	
	const extractMetadataFromQuery = () => {
        const columnData = []
        totalDbList.forEach(function (item) {
            if(item.version.includes("FLIGHT_DEV_MSD"))
            columnData.push({version: item.version, test_status: item.test_status, comments: item.comments, created_date: new Date(item.created_date).toLocaleString()});
        });
        return columnData;
	}

	const requestDelConfirmationHandler = (versionRow) => {
		let deleteRequest =  new ConfirmationRequest(
			versionRow,
			async () => performVersionDeletionHandler(versionRow),
			`Delete '${versionRow.version}'`, 
			"Delete",
			"Are you sure you would like continue?",
			null,
			`version '${versionRow.version}' deleted`,
		);
	
		setConfirmationRequest(deleteRequest);
	}
    const {submitWithAppropriateWarnings} = useSubmitWithWarnings(requestDelConfirmationHandler)

	const columns = React.useMemo(
		() => [
			{
				Header: 'Version Name',
				accessor: 'version',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Test Status',
				accessor: 'test_status',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Comments',
				accessor: 'comments',
				Filter: TextInputColumnFilter
			},
			{
				Header: 'Created Date (UTC)',
				accessor: 'created_date',
				Filter: ''
			},
			{
				Header: ' ',
				accessor: (versionRow) => {
					return (
						<div className={style.tableButtonContainer}>
							<TableEditButton editHandler={() => setEditSnapShot(versionRow)}/>
							<TableDeleteButton deletionHandler={() => submitWithAppropriateWarnings(versionRow)}/>
						</div>
					);
				},
				columnType: "buttons"
			},
		],
		[totalDbList]
	);

	const  {mutateAsync: deleteDevVersion} = useDeleteDevVersion();
	const performVersionDeletionHandler = async (versionToDelete) => {

		return await deleteDevVersion(versionToDelete.version).then((response) => {
			toast.success(`Version '${versionToDelete.version}' deleted`);
			setTotalDbList(response['databases']);
			// dbList = response["databases"].filter(ele => ele.version !== versionToDelete.version);
			// setdbs(dbList);
			// setDbList(dbList)
			return response;
		})
	};

    const {mutateAsync: updateDevVersion} = useCreateDevVersion();
    const performAddNewDevVersionHandler = async () => {
    try{
        setLoading(true);
		
		return await updateDevVersion().then((response) => {
			setLoading(false);
			setTotalDbList(response["databases"]);
			// dbList = response["databases"].filter(ele => ele.version.includes("FLIGHT_DEV_MSD"))
			// setdbs(dbList);
			// setDbList(dbList);
			toast.success(`Updated New Dev Version `);

				toast.success(`Updated New Dev Version `);
				
				return response;
			})
		}
		catch (error) {
			console.error("Error during update:", error);
			// Handle error if needed
		} finally {
			// Set loading to false, whether the operation succeeded or not
			setLoading(false);
		}

	};

	const onEditSave = (dbListValue) => {
		const temp = dbListValue.filter(ele => ele.version.includes("FLIGHT_DEV_MSD"));
		// setdbs(dbList);
		// setDbList(dbList);
	}

	return (
        <Fragment>
			
            {loading && (
                <Wizard width='40rem'>
                            <div role="status">
          <span>Creating Dev Snapshot...</span>
        </div>
                    </Wizard>
            )}

			{editSnapShot && 
				<LoadingWrapper 
					queries={[metadata_query]}
					onLoad={() => (
						<Modal>
							<EditSnapShotModal 
								setEditSnapShot={setEditSnapShot}
								editSnapShot={editSnapShot}
								metadata={metadata_query.data}
								onEditSave={onEditSave}
								userName = {userName}
								dbList = {dbList}
							/>
						</Modal> 
					)}				
				/>
			}
			
			{!loading && 
			<><div className={style.snapshotHeader}>
				<div>
					<h1>Dev Snapshots</h1>
				</div>
				<div onClick={() => {setCreateVisible(false)}}>
					<CloseButton />
				</div>
			</div>
			<MsdQueryTable tableName="SnapShotsTablePage"
					columns={columns} query={metadata_query} dataExtractFn={extractMetadataFromQuery}
			>
	
				<CreateButton
					width="10rem"
					title="SnapShot"
					onClick={() => {
						//console.log("SnapShot Disabled")
						
						performAddNewDevVersionHandler(); 
						

					}}
					
				/>
					
			</MsdQueryTable></>}
			
            </Fragment>
	);
}; 

export default SnapshotTable;