import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import Row from './Row'

const DndRow = ({ row, index, getCustomRowProps={}, useMsdTableObj}) => {
  
  const {
    dndSpec: {
      dragSpec: tableDragSpec, 
      dropSpec: tableDropSpec, 
      tableId, 
      nameAttr, 
    }, 
    selectedFlatRows
  } = useMsdTableObj;

    const dndRef = React.useRef(null);

    const dropSpec = {
      ...tableDropSpec,

      drop: (item, monitor) => {
        console.log("Dropping");        
        tableDropSpec.performDrop({
          dragDetails: item, 
          dropAtIndex: index, 
          mousePositionAtDropIndex: overPosition,
          dropTableId: tableId,
          nameAttr
        });
      },
      
      collect: monitor => ({
        overPosition: (() => {
          if (!monitor.isOver()) {
            return;
          }

          // Determine rectangle on screen
          const rowBoundingRect = dndRef.current.getBoundingClientRect();
          // Get vertical middle
          const middleY =
            (rowBoundingRect.bottom - rowBoundingRect.top) / 2;
          // Determine mouse position
          const clientOffset = monitor.getClientOffset()
          // Get pixels to the top
          const mouseY = clientOffset.y - rowBoundingRect.top

          if (mouseY < middleY) {
            return "top-half";
          }
          // Dragging upwards
          if (mouseY > middleY) {
            return "bottom-half"
          }
        })(),
      }),
    }
    const [{overPosition}, drop] = useDrop(dropSpec)
  
    const dragSpec = {
      ...tableDragSpec,
      item: (item, monitor) => {
        console.log("Begin drag");

        let selectedTableData = selectedFlatRows.map(row => row.original);

        return { 
          type: tableDragSpec.type, 
          selectedTableData,
          tableId
        };
      },
      canDrag(monitor) {
        return selectedFlatRows.find(selectedRow => selectedRow.values[nameAttr] === row.values[nameAttr]);
      },
      collect: monitor => ({
        canDrag: monitor.canDrag(),
        includedInDrag: monitor.getItem() 
          ? monitor.getItem().selectedTableData
            .find(element => element[nameAttr] === row.values[nameAttr])
          : false
      }),    
    }

    const [{includedInDrag}, drag, preview] = useDrag(dragSpec);

    const style = {}
    if (includedInDrag) {
      style["background"] = "var(--ast-secondary-blue-1-color)";
      style["opacity"] = 0.7;
    }
    if (overPosition === "top-half") {
      style["borderTop"] = (index === 0) 
        ? "6px solid var(--ast-secondary-blue-color)" 
        : "4px solid var(--ast-secondary-blue-color)";
    } else if (overPosition === "bottom-half") {
      style["borderBottom"] = "4px solid var(--ast-secondary-blue-color)";    
    }

    preview(drop(dndRef))
    drag(dndRef)
  
    const getDndCustomRowProps = (row) => (
      {
        ...getCustomRowProps(row),
        ref: dndRef,
      }  
    )
    
    return (
      <Row row={row} index={index} getCustomRowProps={getDndCustomRowProps} style={style} />
    )
  }
  
export default DndRow;