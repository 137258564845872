import React from 'react';

import {getTlmPointSchema} from 'model/form-schema/ObjectSchemas'
import FormTab from 'components/ui-core/form/FormTab';
import useFormTab from 'components/ui-core/form/useFormTab';

import { TelemetryPointFormContent } from './TelemetryPointFormContent';
import createTlmPointFromForm from '../TelemetryPointFormHelper';

export const TelemetryPointFormTab = ({ updateEntityPromise, editModeActive, 
    editModeTransitionHandler, tlmPoint, allPoints, metadata, parentPacketExists }) => {
	const schema = getTlmPointSchema(allPoints, tlmPoint.tlm_point_name);
	const {useFormObj, controlProps} = useFormTab(tlmPoint, schema, editModeActive);

	const createSubmitPromise = (formData) => {
    let updatedPoint = createTlmPointFromForm(formData, useFormObj, metadata);
		return updateEntityPromise(updatedPoint);
	};

  return (
    <FormTab editModeActive={editModeActive}
        editModeTransitionHandler={editModeTransitionHandler}
        createSubmitPromise={createSubmitPromise}
        useFormObj={useFormObj}>

      <TelemetryPointFormContent 
        controlProps={controlProps}  
        metadata={metadata} 
        useFormObj={useFormObj}
        isWizard={false}
        allPoints={allPoints}
        isByteReadOnly={parentPacketExists}/>
    </FormTab>		
  );
};


export default TelemetryPointFormTab;
