import TagsTabViewMode from "./TagsTabViewMode";
import TagsTabEditMode from "./TagsTabEditMode";

export const TagsTab = (
	{ editModeActive, editModeTransitionHandler, underlyingEntity, entityType, allTags }) => {

	return (
		<>
			{editModeActive
				? 	
					<TagsTabEditMode 
						editModeTransitionHandler={editModeTransitionHandler}
						underlyingEntity={underlyingEntity}
						allTags={allTags}
						entityType={entityType}/>
				: 
					<TagsTabViewMode 
						underlyingEntity={underlyingEntity}
						allTags={allTags}/>
			}
		</>
	);
};

export default TagsTab;