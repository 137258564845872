import TlmPacketAssocTabViewMode from "./TlmPacketAssocTabViewMode";
import TlmPacketAssocTabEditMode from "./TlmPacketAssocTabEditMode";

export const TlmPacketAssocTab = ({editModeActive, editModeTransitionHandler, updateEntityPromise,
		tlmPacket, allPoints }) => {
	return (
		<>
			{editModeActive
				? 	
					<TlmPacketAssocTabEditMode 
						editModeTransitionHandler={editModeTransitionHandler}
						updateEntityPromise={updateEntityPromise}
						tlmPacket={tlmPacket}
						allPoints={allPoints}/>
				: 
					<TlmPacketAssocTabViewMode tlmPacket={tlmPacket} allPoints={allPoints}/>
			}
		</>
	);
};

export default TlmPacketAssocTab;

