// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableBarButton_tableBarButton__68Y\\+5 {
	background: var(--ast-secondary-blue-color);
	border-radius: 0.5rem;
	border: none;
	color: #ffffff;
	padding: 0.5rem;
	font-weight: 600;
	font-size: 1rem;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	cursor: pointer;
	width: 11.5rem;
	height: 2rem;
	justify-content: space-around;
}

.TableBarButton_tableBarButton__68Y\\+5:hover {
	opacity: 0.5;
}

.TableBarButton_tableBarButton__68Y\\+5:disabled {
	background: var(--ast-disabled-blue-color);
}


/* Responsive website scaling for 1920 x 1080 screens */

@media screen and (max-width: 1920px) {
	.TableBarButton_createIcon__h297W {
		width: 1.2rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/buttons/TableBarButton/TableBarButton.module.css"],"names":[],"mappings":"AAEA;CACC,2CAA2C;CAC3C,qBAAqB;CACrB,YAAY;CACZ,cAAc;CACd,eAAe;CACf,gBAAgB;CAChB,eAAe;CACf,aAAa;CACb,qBAAqB;CACrB,mBAAmB;CACnB,eAAe;CACf,cAAc;CACd,YAAY;CACZ,6BAA6B;AAC9B;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,0CAA0C;AAC3C;;;AAGA,uDAAuD;;AAEvD;CACC;EACC,aAAa;CACd;AACD","sourcesContent":["@import 'App.module.css';\n\n.tableBarButton {\n\tbackground: var(--ast-secondary-blue-color);\n\tborder-radius: 0.5rem;\n\tborder: none;\n\tcolor: #ffffff;\n\tpadding: 0.5rem;\n\tfont-weight: 600;\n\tfont-size: 1rem;\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n\talign-items: center;\n\tcursor: pointer;\n\twidth: 11.5rem;\n\theight: 2rem;\n\tjustify-content: space-around;\n}\n\n.tableBarButton:hover {\n\topacity: 0.5;\n}\n\n.tableBarButton:disabled {\n\tbackground: var(--ast-disabled-blue-color);\n}\n\n\n/* Responsive website scaling for 1920 x 1080 screens */\n\n@media screen and (max-width: 1920px) {\n\t.createIcon {\n\t\twidth: 1.2rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBarButton": `TableBarButton_tableBarButton__68Y+5`,
	"createIcon": `TableBarButton_createIcon__h297W`
};
export default ___CSS_LOADER_EXPORT___;
