import { createContext, useState, useContext, useEffect } from 'react'
import { useQueryClient } from 'react-query';
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom'
import { Auth, Hub } from 'aws-amplify/lib-esm';

const AuthContext = createContext()
export const useAuthContext = () => useContext(AuthContext)
class User {
    constructor(authResult) {
        this.getDecodeUserFromAuthToken(authResult)
    }

    getDecodeUserFromAuthToken = (authResult) => {
        if (authResult?.IdToken) {
            this.idToken = jwt_decode(authResult?.IdToken)
        }
    }

    getUsername = () => {
        if (this.idToken && this.idToken["cognito:username"]) {
            return this.idToken["cognito:username"]
        }
        return null
    }

    getPermissions = () => {
        if (this.idToken && this.idToken["cognito:groups"]) {
            return this.idToken["cognito:groups"]
        }
        return []
    }
}

function getUser() {
    return new Promise((res,rej) => {
        Auth.currentAuthenticatedUser()
        .then(authResult => {
            if (authResult) {
                authResult.getSession((err, session) => {
                    let idToken = session.getIdToken().getJwtToken();
                    let user = new User({ IdToken: idToken });
                    res(user);
                });
            }
            console.log('Not signed in');
            //rej(null);
        })
        .catch(() => {
            console.log('Not signed in');
            //rej("Not signed in");
        });
    });
}

export const AuthContextProvider = ({ routes }) => {
    const [user, setUser] = useState(new User());
    const history = useHistory();
    const queryClient = useQueryClient();


    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                    getUser().then(user => {
                        setUser(user);
                        history.push("/");
                    });
                    break;
                case 'signOut':
                    setUser(null);
                    break;
                case 'signIn_failure':
                    console.log('Sign in failure', data);
                    break;
            }
        });

        getUser().then(user => {
            setUser(user);
            if(localStorage.getItem("lastPage") == "null"){
                history.push("/");
            }
            else{
                history.push(localStorage.getItem("lastPage"))                
            }
        });
    }, []);

    const federatedLogin = () => {
        Auth.federatedSignIn({ customProvider: 'ASTAzureAD' });
    }

    let logoutUser = () => {
        setUser(null)
        queryClient.clear()
        history.push('/login')
        localStorage.setItem('lastPage',null);
        Auth.signOut();
    }

    const envType = process.env.REACT_APP_ENV_TYPE;
    const SCOPES_FOR_ENV = {
        "FSW_DEV": ["MsdUser", "MsdPushToProd"],
        "FSW_PROD": ["MsdUser", "MsdProdWriter"]
    }

    const filterApplicableRequiredScopes = (requiredScopes) => {
        return requiredScopes.filter(scope => SCOPES_FOR_ENV[envType].includes(scope))
    }

    const getMissingPermissions = (requiredScopes) => {
        const missing = []
        filterApplicableRequiredScopes(requiredScopes).forEach(scope => {
            if (!user.getPermissions().includes(scope)) {
                missing.push(scope)
            }
        })
        return missing
    }

    const getBearerToken = async () => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken();
            const jwtToken = accessToken.getJwtToken();
            const token = `Bearer ${jwtToken}`;
            return token;
        } catch (error) {
            console.log('Error getting token:', error);
            return null;
        }
    };

    let value = {
        user: user,
        federatedLogin: federatedLogin,
        logoutUser: logoutUser,
        getMissingPermissions: getMissingPermissions,
        getBearerToken: getBearerToken,
    }

    return (
        <AuthContext.Provider value={value} >
            {routes()}
        </AuthContext.Provider>
    )
}
export default AuthContextProvider;
