import { useHistory, useLocation } from 'react-router-dom';
import { areArrayEqualsAndInOrder } from 'components/ui-core/utils/ArrayUtils'

export class PageState {
    constructor(tabId) {
        this.tabId = tabId;
        this.tableStates = {};
    }    
}

export class TableState {
    constructor() {
        this.filterValues = {}
        this.pageIndex = 0
        this.pageSize =20
        this.selectedRowIds = []
    }
}

export const usePageState = () => {
    const location = useLocation();
    const history = useHistory();

    const getPageState = () => {
        // Ensure a page state object exists if it doesn't already
        let pageState = location.state
        if (!pageState) {
            pageState = new PageState();
            location["state"] = pageState;
        }
        return pageState;
    }

    const replacePageState = (newState) => {
       history.replace(history.location, newState);       
    }

    const getTabId = () => {
        return getPageState().tabId; 
    }
    const setTabId = (newTabId) => {
        const pageState = getPageState();
        pageState.tabId = newTabId;
        replacePageState(pageState);
    } 

    const getTableState = (tableName) => {
        const pageState = getPageState();
        let tableState = pageState.tableStates[tableName]
        if (!tableState) {
            tableState = new TableState();
            pageState.tableStates[tableName] = tableState;
        }
        return tableState
    }
    
    const setFilterValue = (tableName, columnId, newValue) => {
        const tableState = getTableState(tableName)
        tableState.filterValues[columnId] = newValue;

        replacePageState(getPageState());
    }
        
    const getFilterValuesAsInitialState = (tableName) => {
        const tableState = getTableState(tableName)

        const result = [];
        for (const [id, value] of Object.entries(tableState.filterValues)) {
            if (value) {
                result.push({id, value})
            }
        }        
        return result;
    }
    
    const setPaginationState = (tableName, pageIndex, pageSize) => {
        const tableState = getTableState(tableName)

        // Only replace the state if it's actually changed - otherwise setting the location
        // state will cause a re-render and an infinite loop
        if (tableState.pageIndex !== pageIndex || tableState.pageSize !== pageSize) {
            tableState.pageIndex = pageIndex
            tableState.pageSize = pageSize

            replacePageState(getPageState());
        }
    }

    const getPaginationState = (tableName) => {
        const tableState = getTableState(tableName);
        return {
            pageIndex: tableState.pageIndex,
            pageSize: tableState.pageSize
        }
    }

    const setSeletedRowIds = (tableName, newIds) => {
        const tableState = getTableState(tableName)

        // Only replace the state if it's actually changed - otherwise setting the location
        // state will cause a re-render and an infinite loop
        if (!areArrayEqualsAndInOrder(tableState.selectedRowIds, newIds)) {
            tableState.selectedRowIds = newIds

            replacePageState(getPageState());
        } 
    }

    const getSeletedRowIds = (tableName) => {
        const tableState = getTableState(tableName);
        return tableState.selectedRowIds;
    }
    
    return {
        getPageState,
        getTableState,
        replacePageState,
        // Tabs
        setTabId,
        getTabId,
        // Tables - Filters
        setFilterValue,
        getFilterValuesAsInitialState,
        // Pagination
        setPaginationState,
        getPaginationState,
        // Selection
        setSeletedRowIds,
        getSeletedRowIds,
    };
}

export default usePageState;