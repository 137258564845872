// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.LoadingWrapper_loadingSpinner__iSg-B {
    width: 100%;
    height: 100%;
    /* color: blue;
    background-color: red; */
	cursor: progress !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/page/LoadingWrapper.module.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ;4BACwB;CAC3B,2BAA2B;AAC5B","sourcesContent":["\n.loadingSpinner {\n    width: 100%;\n    height: 100%;\n    /* color: blue;\n    background-color: red; */\n\tcursor: progress !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSpinner": `LoadingWrapper_loadingSpinner__iSg-B`
};
export default ___CSS_LOADER_EXPORT___;
