import React from 'react';
import PropTypes from 'prop-types';
import style from './MainButton.module.css';

export const MainButton = ({ title, width, height, disabled, ...props }) => {

	const styleClass = disabled ? style.mainButton : style.mainButton

	return (
		<div className={style.mainButtonContainer}>
			<button {...props} disabled={disabled} style={{ width: width, height: height }} 
					className={styleClass}>
				{title}
			</button>
		</div>
	);
};

MainButton.propTypes = {
	title: PropTypes.string.isRequired,
	width: PropTypes.string.isRequired,
	height: PropTypes.string.isRequired,
};

MainButton.defaultProps = {
	width: '5.5rem',
	height: '1.813rem',
};

export default MainButton;
