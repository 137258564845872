import style from './SummaryBar.module.css';

import { useSummmaryContext} from './SummaryContext';

export const SummaryBar = () => {

    const {summaryItems} =  useSummmaryContext();

	return (
		<div className={style.summaryContainer}>
                {
                    summaryItems.map((item) => {
                        return (
                            <div key={item.title}>			
                                <label>{item.title}:</label>
                                <input value={item.value} disabled/>
                            </div>			
                        );
                    })
                }
		</div>
	);
}

export default SummaryBar;