import React from 'react';

import deleteIcon from 'img/delete-icon.svg';
import style from './TableDeleteButton.module.css';

export const TableDeleteButton = ({deletionHandler}) => {
	return (
		<>
			<button className={style.tableDeleteButton} onClick={deletionHandler}>
				<img src={deleteIcon} alt="Delete Icon" />
			</button>
		</>
	);
};

export default TableDeleteButton;
