import React from 'react';

import style from './TableAddButton.module.css';
import AddIcon from 'img/table-add-icon.svg';

export const TableAddButton = ({addHandler}) => {
	return (
		<>
			<button className={style.tableAddButton} onClick={addHandler}>
				<img src={AddIcon} alt="Add Icon" />
			</button>
		</>
	);
};

export default TableAddButton;
