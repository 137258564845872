import React from 'react';
import {usePageState} from 'components/ui-core/page/usePageState';

function SelectInputColumnFilter({ column: { id, filterValue, setFilter, preFilteredRows }, tableName }) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = React.useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach((row) => {
			options.add(row.values[id]);
		});
		return [...options.values()].sort();
	}, [id, preFilteredRows]);

	const {setFilterValue} = usePageState();

	// Render a multi-select box
	return (
		<select
            style={{ width:'14rem', marginRight: '2rem', backgroundColor: "white" }}
			value={filterValue}
			onChange={(e) => {
				const newFilterValue = e.target.value || undefined
				setFilterValue(tableName, id, newFilterValue)
				setFilter(newFilterValue);
			}}
		>
			<option value="">Select a value</option>
			{options.map((option, i) => (
				<option key={i} value={option}>
					{option}
				</option>
			))}
		</select>
	);
}

export default SelectInputColumnFilter;