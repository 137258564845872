import React from 'react';


import { useASTData } from 'context/ASTContext';
import { useSCVersioning } from 'data/queryHooks';
import SideBarOption from 'model/SideBarOption';
import VersionComparePage from './VersionComparePage';

export const VersionCompareFormPage = () => {
	const dbversions = useSCVersioning();
	const {setSideBarOption, setHeaderTitle} = useASTData();


	React.useEffect(() => {
		setSideBarOption(SideBarOption.MSDVersionCompare);
		setHeaderTitle('MSD Version Compare');
		localStorage.setItem('lastPage',"/version-compare");
	}, []);
		
	return (

		<modal>
			<div><VersionComparePage versionData = {dbversions.data}/></div>
		</modal>
	);
};

export default VersionCompareFormPage;