// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody tr:nth-child(odd) {background: rgba(225, 221, 221, 0.5)}
tbody tr:nth-child(even) {background: rgba(255, 255, 255, 1)}


table {
	width: 100%;
	border-bottom: 0.15rem solid #707070;
}


thead {
	background: var(--ast-dark-color);
	color: var(--ast-light-color);
	font-weight: 500;
	font-size: 0.875rem;
	text-align: left;
}

thead th {
	padding: 1rem 1rem 1rem 0.5rem;
	border-right: 0.15rem solid #707070;
	border-top: 0.15rem solid #707070;
}

table,
td {
	border-right: 0.15rem solid #707070;
	border-collapse: collapse;
	padding: 0.5rem;
	text-align: left;
}

table tr td {
	text-align: left;
}

td:nth-child(1),
th:nth-child(1) {  
	border-left: 0.15rem solid #707070;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/table/Table/Table.module.css"],"names":[],"mappings":"AAAA,yBAAyB,oCAAoC;AAC7D,0BAA0B,kCAAkC;;;AAG5D;CACC,WAAW;CACX,oCAAoC;AACrC;;;AAGA;CACC,iCAAiC;CACjC,6BAA6B;CAC7B,gBAAgB;CAChB,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;CACC,8BAA8B;CAC9B,mCAAmC;CACnC,iCAAiC;AAClC;;AAEA;;CAEC,mCAAmC;CACnC,yBAAyB;CACzB,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;;CAEC,kCAAkC;AACnC","sourcesContent":["tbody tr:nth-child(odd) {background: rgba(225, 221, 221, 0.5)}\ntbody tr:nth-child(even) {background: rgba(255, 255, 255, 1)}\n\n\ntable {\n\twidth: 100%;\n\tborder-bottom: 0.15rem solid #707070;\n}\n\n\nthead {\n\tbackground: var(--ast-dark-color);\n\tcolor: var(--ast-light-color);\n\tfont-weight: 500;\n\tfont-size: 0.875rem;\n\ttext-align: left;\n}\n\nthead th {\n\tpadding: 1rem 1rem 1rem 0.5rem;\n\tborder-right: 0.15rem solid #707070;\n\tborder-top: 0.15rem solid #707070;\n}\n\ntable,\ntd {\n\tborder-right: 0.15rem solid #707070;\n\tborder-collapse: collapse;\n\tpadding: 0.5rem;\n\ttext-align: left;\n}\n\ntable tr td {\n\ttext-align: left;\n}\n\ntd:nth-child(1),\nth:nth-child(1) {  \n\tborder-left: 0.15rem solid #707070;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
