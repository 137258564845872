import React, { useEffect, useState } from 'react';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import OptionFormContent from './OptionFormContent'
import { getBitwiseOptionsSchema, getOptionsSchema } from 'model/form-schema/ObjectSchemas';

export const CreateOptionWizard = ({ createAddOptionPromise, setCreateVisible, schema, entityType, optionsData , maxOptionValue}) => {
	const [create, setCreate] = useState(schema);
	const {useFormObj, controlProps} = useWizardForm({}, create);
	const {watch, getValues} = useFormObj;
	watch("name");
	useEffect(() => {
		setCreate( entityType["code"] === 'CmdParam' || entityType["code"] === 'TlmPoint'? 
		getOptionsSchema(optionsData, null) : 
		getBitwiseOptionsSchema(optionsData, getValues("name") , maxOptionValue))
	}, [useFormObj.getValues("name")]);

	return (
		<Wizard width='20rem'>
			<WizardForm title="Create Enumeration" 
				setWizardVisible={setCreateVisible} useFormObj={useFormObj} 
				createSubmitPromise={createAddOptionPromise}
			>
				<OptionFormContent controlProps={controlProps} entityType={entityType} />
			</WizardForm>
		</Wizard>		
	);
};


export default CreateOptionWizard;
