import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable'
import {assignOrderAndStartNumbers} from 'components/tlm-point/point-page/bitwise/BitwiseHelper';

import { getBitwiseSummaryItems } from 'components/tlm-point/point-page/bitwise/BitwiseHelper';
import { useSummmaryContext } from 'components/ui-core/common/SummaryContext';

import formControlsStyle from 'components/ui-core/form/FormControls.module.css';
import { getOptionsColumn } from 'components/options/OptionHelper';
import EntityType from 'model/EntityType';

export const BitwiseTableTabViewMode = ({ tlmPoint, allTlmBitwise}) => {

	const tableData = React.useMemo(
		() => {
			return tlmPoint.bitwise_list.map(
				bitwiseName => cloneDeep(allTlmBitwise.find(bitwise => bitwise.tlm_bitwise_name === bitwiseName)));			
		},
		[tlmPoint]
	)

	assignOrderAndStartNumbers(tableData);

	const columns = React.useMemo(
		() => [
			{
				Header: "Sequence No.",
				accessor: "sequence_num"
		  	},
			{
				Header: "Name",
				accessor: "tlm_bitwise_name",
				Filter: TextInputColumnFilter
			},
		  	{
				Header: "Description",
				accessor: "description"
		  	},
		  	{
				Header: "Bit Size",
				accessor: "size_bits",
		  	},
			{
				Header: "Start bit",
				accessor: "start_bit"
		  	},
			getOptionsColumn(EntityType.BitwisePoint)
		],
		[]
	);
	
	const defaultSortBy = [
		{
			id: 'sequence_num',
			desc: false
		}
	]

	const getTableErrors = () => {
		const result = [];

		const expectedBits = tlmPoint.size_bytes * 8;
		const actualBits =  tableData.reduce(
			(accumulator, bitwiseRow) => accumulator + bitwiseRow.size_bits,
			0
		);

		if (actualBits !== expectedBits) {
			result.push(`The bitwise points must total ${expectedBits} bits in length. They currently total ${actualBits}`)
		}

		return result;
	}
	const tableError = getTableErrors();


    const {setSummaryItems} =  useSummmaryContext();
	React.useEffect(() => {
			setSummaryItems(getBitwiseSummaryItems(tlmPoint, tableData));
		}, 
		[tlmPoint, tableData]
	);

	return (
		<ManagedMsdTable tableName="BitwiseTableTabViewMode" columns={columns} data={tableData} sortBy={defaultSortBy} 
			tableErrors={() => {
				if (tableError.length > 0) {
				return ( 
					<p className={formControlsStyle.nonPaginatedTableMessage}>
						{tableError[0]}
					</p>
				)}
			}}
		/>		
	);
};


export default BitwiseTableTabViewMode;
