import React from 'react';
import {FormRow, FormCheckmarkRow, Input, TextArea, Select, Checkbox, MultiSelect, QueryControlWrapper} from 'components/ui-core/form/FormControls';
import SubystemCspPortController from 'components/ui-core/common/SubystemCspPortController';
import { getSortedSubsystemValueOptions } from 'components/ui-core/utils/MetadataHelper';
import { isInFswArr } from "components/tags/TagHelper";

export const TelemetryPacketFormContent = ({controlProps, metadata, allCmdPacketsQuery, useFormObj, isWizard}) => {
	const {control, getValues, watch} = useFormObj;

	// re-render when response change (conditional fields)
	watch("response");

	const srcNodeOptions = React.useMemo(() => metadata.nodes.map(nodeEntry => nodeEntry.node), []); 
	const getResponseToOptions = React.useMemo(() => {
		return allCmdPacketsQuery.data.map(cmdPacket => cmdPacket.cmd_name);
	  }, [allCmdPacketsQuery.data]);
	const portController = new SubystemCspPortController(useFormObj, metadata);

	const isInFSW = () => isInFswArr(getValues("environment_tags"));

	return (
		<>
			<FormRow rowNum="1">
				<Input style={{width: "28rem"}} title="Telemetry Packet Name" attr="tlm_packet_name" {...controlProps}/>

				<Select title="Subsystem" attr="subsystem_name" {...controlProps}
					valueOptions={getSortedSubsystemValueOptions(metadata)}
					includeBlank={isWizard} onChange={portController.handleSubsystemChanged}/>

				<Input title="CSP Port Number" attr="csp_port" {...controlProps} isRequired={isInFSW}/>
			</FormRow>

			<FormRow rowNum="2">
				<TextArea title="Description" attr="description" {...controlProps}/>
			</FormRow>

			<FormRow rowNum="3">
				<MultiSelect title="Source Nodes" attr="src_nodes" {...controlProps}
					optionValues={srcNodeOptions} control={control}/>
			</FormRow>

			<FormRow rowNum="4">
				<Input title="Telemetry ID" attr="tlm_id" {...controlProps} isRequired={isInFSW}/>
				<Input title="File ID" attr="file_id" {...controlProps}/>
				<Input title="Update Frequency (Hz)" attr="update_frequency" {...controlProps}/>
			</FormRow>

			<FormCheckmarkRow rowNum="5">
				<Checkbox title="Response Packet" attr="response" {...controlProps}/>
				<Checkbox title="Non-Telemetry" attr="non_telem" {...controlProps}/>
				<Checkbox title="Allow Short" attr="allow_short" {...controlProps}/>
			</FormCheckmarkRow>

			{getValues("response") &&
				<FormRow style={{marginTop: "2rem"}} rowNum="6">
					<QueryControlWrapper
						title="Response To"
						attr="response_to_cmd_pkts"
						schema={controlProps.schema}
						queries={[allCmdPacketsQuery]}
						onLoad={() => (
							<MultiSelect minWidth={"30rem"}
								attr="response_to_cmd_pkts" {...controlProps} control={control} 
								optionValues={getResponseToOptions} />

						)}
					/>
				</FormRow>
			}
		</>
	);
};

export default TelemetryPacketFormContent;
