import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import cloneDeep from 'lodash/cloneDeep';

import {getCmdPacketSchema, getToastMessage} from 'model/form-schema/ObjectSchemas'
import CommandPacketFormContent from 'components/cmd-packet/packet-page/CommandPacketFormContent'
import {useCreateCommandPacket} from 'data/queryHooks';
import useWizardForm from 'components/ui-core/form/useWizardForm';
import { useState } from 'react';
import { useEffect } from 'react';

export const CreateCommandPacketWizard = ({ setCreateVisible, allCmdPackets, metadata, copyPacket }) => {
	if(copyPacket?.hasOwnProperty("_id")) 	copyPacket["_id"] = null; 
	const defaultSchema = getCmdPacketSchema(allCmdPackets, copyPacket? copyPacket : null, false);
	const [schema, setSchema] = useState(defaultSchema);
	const [toastMes, setToastMsg] = useState("");

	const {useFormObj, controlProps} = useWizardForm(copyPacket !== null ? {
		...cloneDeep(copyPacket),
		dest_nodes: []
	} : {environment_tags: []}, 
	schema, true);
	const {watch, getValues} = useFormObj;
	watch("cmd_name");
	useEffect(() => {
		const schem = getCmdPacketSchema(allCmdPackets, getValues(),  true);
		setSchema(schem);
		schem.isValid(useFormObj.getValues())
    	.then(valid => {
			if(!valid){
				const toa = getToastMessage(allCmdPackets, getValues(), "cmd_node", true);
				setToastMsg(toa);
			}
		});
	}, [getValues()['dest_nodes'], getValues()['subsystem_name'], getValues()['cmd_name'], getValues()['csp_port'], getValues()['cmd_code']]);
	
	useEffect(() => {
		if(toastMes || toastMes != ""){
			toast.warning(toastMes);
			setToastMsg("");
		}
	}, [toastMes]);

	const  {mutateAsync: addCommandPacket} = useCreateCommandPacket();

	const createSubmitPromise = async (formData) => {
		
		let newCommandPacket = {
			cmd_name: formData.cmd_name,
			subsystem_name: formData.subsystem_name,
			csp_port: formData.csp_port,
			description: formData.description,
			cmd_code: formData.cmd_code,
			critical_command: formData.critical_command === undefined ? false : formData.critical_command,
			critical_command_warning: formData.critical_command ? formData.critical_command_warning : null,
			time_created: dayjs(new Date()).toISOString(),
			cmd_fields: formData.cmd_fields? formData.cmd_fields : [],
			destination_group: formData.dest_nodes 
				?  formData.dest_nodes.map( (nodeName) => {
					return {
						node: nodeName,
						node_id: metadata.nodes
							.find(metaNodeEntry => metaNodeEntry.node === nodeName)?.node_id
					}})
				: [],
			satellite: ["BW3"],
			environment_tags: [],
			depreciated: {
				depreciated_status: false,
				notes: null
			},
		}

		return await addCommandPacket(newCommandPacket)
			.then(response => {
				toast.success(`Command Packet '${newCommandPacket.cmd_name}' created`);
				return response;
			})
			.catch(error => {
				toast.error(`Command Packet creation failed: ${error.message}`);
				return Promise.reject(error)
			});
	};

	return (
		<Wizard width='90rem'>
			<WizardForm title="Create Command Packet" getObjectbyIdRoutePrefix='/command-packet'
				setWizardVisible={setCreateVisible} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise} copyPacketType={copyPacket? (copyPacket.cmd_name? 'cmd_type': 'tlm_type'):  null}
			>
				<CommandPacketFormContent 
					controlProps={controlProps} 
					metadata={metadata}
					useFormObj={useFormObj}
					isWizard={true}
				/>
			</WizardForm>
		</Wizard>
	);
};

export default CreateCommandPacketWizard;
