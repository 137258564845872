import React from 'react';
import style from './Option.module.css';

import { option_styles } from './OptionHelper';
export const Option = ({ option }) => {
	
	return (
		<>
			<div className={style.optionContainer}>
				<div style={option_styles[option.severity]} className={style.option}>
					{`${option.name}: ${option.value}`}
				</div>
			</div>
		</>
	);
};

export default Option;
