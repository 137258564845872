class ConfirmationRequest {
    constructor(item, asyncCallbackFn, title, actionButtonTitle, 
        bodyContent, navPathOnConfirm, successMessage, cancelButtonTitle = "Cancel" , asyncCancelFn = null) {
        this.item = item;
        this.asyncCallbackFn = asyncCallbackFn;
        this.title = title;
        this.actionButtonTitle = actionButtonTitle;
        this.bodyContent = bodyContent;
        this.navPathOnConfirm = navPathOnConfirm;
        this.successMessage = successMessage;
        this.cancelButtonTitle = cancelButtonTitle
        this.asyncCancelFn = asyncCancelFn
    }
}

export default ConfirmationRequest;