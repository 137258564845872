import React from 'react';
import { useHistory } from 'react-router-dom';

import { PageState } from 'components/ui-core/page/usePageState';
import ManagedMsdTable from 'components/ui-core/table/msdTable/ManagedMsdTable'
import { getTelemetryPacketColumns } from 'components/tlm-packet/table-page/TelemetryPacketTableHelper';
import { getDerivedInputUsageColumns } from 'components/tlm-point/TelemetryPointTableHelper'

export const TelemetryPointUsageTab = ({tlmPoint, parentPackets, allPoints}) => {

	const history = useHistory();
	
	// Parent Packet
	const viewTelemetryPacketHandler = (tlmPacket) => {
		history.push(`/telemetry-packet/${tlmPacket._id}`, new PageState("tlm_points_tab"));
	};
    const parentPacketColumns = React.useMemo(
		() => getTelemetryPacketColumns(viewTelemetryPacketHandler, "Packet Name"), []);


	const viewTelemetryPointHandler = (tlmPoint) => {
		history.push(`/telemetry-point/${tlmPoint._id}`, new PageState("form_tab"));
	};
	const derivedInputUsageColumns = React.useMemo(
		() => getDerivedInputUsageColumns(viewTelemetryPointHandler), []);
	const derivedUsagePoints = allPoints.filter(derived_point => {
		if(derived_point["type"] !== "derived") {
			return false;
		} 

		if(derived_point["tlm_point_name"] === "IC_PN_D1") {
			console.log("THis one")
		}
		const derived_input_names = derived_point["derived_inputs"].split(",")

		// Is the TLM point itself used?
		if (derived_input_names.includes(tlmPoint["tlm_point_name"])) {
			return true;
		} 

		// Is the a bitwise point from this TLM point used?
		if (tlmPoint["type"] === "bitwise") {
			for (const index in tlmPoint["bitwise_list"]) {
				if (derived_input_names.includes(tlmPoint["bitwise_list"][index])) {
					return true;
				} 
			}
		} 

		return false;

	})


	return (
		<div>
			<h2>Parent Telemetry Packets</h2>
			<ManagedMsdTable tableName="TlmPointPacketUsage" columns={parentPacketColumns} data={parentPackets} />

			<h2 style={{marginTop: "1rem"}}>Derived Telemetry Points</h2>
			<ManagedMsdTable tableName="TlmPointDerivedTlmUsage" columns={derivedInputUsageColumns} data={derivedUsagePoints} />
		</div>

	);
};

export default TelemetryPointUsageTab;
