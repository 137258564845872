import React, { useEffect, useState } from 'react';

import {getAlertSchema} from 'model/form-schema/ObjectSchemas'
import FormTab from 'components/ui-core/form/FormTab';
import useFormTab from 'components/ui-core/form/useFormTab';

import { AlertFormContent } from './AlertFormContent';
import {lookupLongUnitValue} from 'components/ui-core/utils/MetadataHelper'
import { useAlerts } from 'data/queryHooks';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export const AlertFormTab = ({ updateEntityPromise, editModeActive, editModeTransitionHandler, alert, allAlerts, metadata, tlmPointLite, tlmPackets }) => {

	const defaultSchema = getAlertSchema(allAlerts.data, alert, editModeActive);
	const [schema, setSchema] = useState(defaultSchema);
	const {useFormObj, controlProps} = useFormTab(alert, schema, editModeActive);
	const packetNames =  tlmPackets.map(row => row['tlm_packet_name']);
	const tlmNames =  tlmPointLite.map(row => row.tlm_point_name);

	const findLimitTypeFromTlmPoint = async (formData) => {
		const tlm_point = tlmPointLite.find(ele => ele['_id'] == formData.tlm_id);
		if(tlm_point['type'] == 'float' || (!tlm_point['list'] || tlm_point['list'].length === 0)){
			return "non-discrete";
		}
		else if(tlm_point['list'].length > 0){
			return "discrete";
		}
	}

	// const adjustFormData = async (formData, limittype) => {

	// 	if(limittype == "non-discrete"){
	// 		setThresholdType('non-discrete');
	// 		formData['thresholds'] = [{
	// 			sc_mode: "default",
	// 			red_low: 0,
	// 			yellow_low: 0,
	// 			yellow_high: 0,
	// 			red_high: 0,
	// 		}];
	// 		formData["enums"] = null;
			
	// 	}
	// 	else {
	// 		setThresholdType('discrete');
	// 		formData['thresholds'] = null
	// 		formData["enums"] =  [{
	// 			sc_mode: "default",
	// 			discrete_values: [
	// 				{
	// 					name:"",
	// 					value: 0,
	// 					severity: ""
	// 				}
	// 			]
	// 		}];
			
	// 	}
	// 	return formData;
	// }

	const createSubmitPromise = async (formData) => {
		const limitType = await findLimitTypeFromTlmPoint(formData);
		const oldlimittype = await findLimitTypeFromTlmPoint(alert);
		if(oldlimittype !== limitType){
			// formData = await adjustFormData(formData, limitType);
			toast.error("Cannot Edit Limit Definition Type , please create a new limit")
			return null;
		}
		let updatedParam = {...formData};

		return updateEntityPromise(updatedParam);
	};
	const {watch, getValues, setValue} = useFormObj;

	watch("tlm_name");
	useEffect(() => {
		const formValues = useFormObj.getValues();
		if(formValues['tlm_name'] && formValues['tlm_name'] != alert['tlm_name']){
			formValues['tlm_id'] = tlmPointLite?.find(ele => ele['tlm_point_name'] == formValues['tlm_name'])['_id'];
			formValues['packet'] = tlmPackets.filter(ele => ele['fields'].includes(formValues['tlm_name'])).map(ele => ele['tlm_packet_name']);
			setValue("tlm_id", formValues['tlm_id'], {
                shouldValidate: true,
                shouldDirty: true
            });
			setValue("packet", formValues['packet'], {
                shouldValidate: true,
                shouldDirty: true
            });
			setValue("board", '', {
                shouldValidate: true,
                shouldDirty: true
            });
		}
		if(formValues['tlm_name'] == alert['tlm_name']){
			setValue("tlm_id", alert['tlm_id'], {
                shouldValidate: true,
                shouldDirty: true
            });
			setValue("packet", alert['packet'], {
                shouldValidate: true,
                shouldDirty: true
            });
			setValue("board", alert['board'], {
                shouldValidate: true,
                shouldDirty: true
            });
		}
		const test = getValues();
		setSchema(getAlertSchema(allAlerts.data , getValues(),  true))
	}, [useFormObj.getValues()['tlm_name']]);

	const history = useHistory();
	const viewPoint = () => {
		history.push(`/telemetry-point/${alert['tlm_id']}`, null);
	}

	return (
		<FormTab editModeActive={editModeActive}
				editModeTransitionHandler={editModeTransitionHandler}
				createSubmitPromise={createSubmitPromise}
				useFormObj={useFormObj}>

			<AlertFormContent 
				controlProps={controlProps} 
				useFormObj={useFormObj}
				metadata={metadata} 
				isWizard={false}
				isByteReadOnly={false}
				allAlerts={allAlerts.data}
				tlmNames = {tlmNames}
				packetNames = {packetNames}
				viewPoint = {viewPoint}
				editModeActive={editModeActive}/>
		</FormTab>		
	);
};


export default AlertFormTab;
