
import style from './Form.module.css';

export const getEditClass = (editMode) => {
    let result =  editMode ? style.fieldEnabled : style.fieldDisabled;
    return result;
}

export const isUniqueValue = (value, attrName, otherObjects, excludeValue) => {
    return !otherObjects.filter(obj => obj[attrName] !== excludeValue).some(obj => obj[attrName] === value);
}

export const isUniqueField = (value, attrName, otherObjects, existingValue) => {
    if(existingValue){
        return otherObjects.filter(obj => obj[attrName] === value && value != existingValue).length < 1;
    }
    else{
        return otherObjects.filter(obj => obj[attrName] === value).length < 1;
    }
}

export const getUniqueFieldValues = (dataList, fieldName) => {
    const values = dataList.map(d => d[fieldName]);
    const result = [...new Set(values)]
    console.log(`Unique values from ${fieldName}: ${result}`);
    return result;
}

export const getAdjustedDisabledControlProps = (controlProps, disabled) => {
    const disabledValue = evalDisabled(disabled)
    
    const result = {...controlProps};
    result["disabled"] = disabledValue;
    result["className"] = getEditClass(!disabledValue);
    return result;
}

const evalDisabled = (disabled) => {
    if (typeof disabled === "function") {
        return disabled();
    } 

    return disabled;
}