import MainButton from "components/ui-core/buttons/MainButton/MainButton";
import styles from "./ASTLoginButton.module.css";
import { useAuthContext } from "components/ui-core/auth/AuthContext";

const ASTLoginButton = () => {
    const {federatedLogin} = useAuthContext();

    return (
        <div className={styles.container}>
            {/* <div className={styles.authText}>Please Authenticate with Your AST Login Credentials</div> */}
            <MainButton onClick={federatedLogin}  title="Login with AST Credentials" width="23.5rem" height="2.813rem"/>
        </div>
    );
};

export default ASTLoginButton;