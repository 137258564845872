// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.TablePaginator_tablePagination__sNh6- {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	width: 100%;
	margin-top: 1rem;
	margin-bottom: 0.2rem;
	padding-bottom: 10px;
}

.TablePaginator_tablePagination__sNh6- button {
	padding: 0.2rem 0.5rem 0.2rem 0.5rem;
	border: 0.1rem solid #707070;
	cursor: pointer;
}


`, "",{"version":3,"sources":["webpack://./src/components/ui-core/table/TablePaginator/TablePaginator.module.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,qBAAqB;CACrB,uBAAuB;CACvB,WAAW;CACX,gBAAgB;CAChB,qBAAqB;CACrB,oBAAoB;AACrB;;AAEA;CACC,oCAAoC;CACpC,4BAA4B;CAC5B,eAAe;AAChB","sourcesContent":["\n.tablePagination {\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n\tjustify-content: center;\n\twidth: 100%;\n\tmargin-top: 1rem;\n\tmargin-bottom: 0.2rem;\n\tpadding-bottom: 10px;\n}\n\n.tablePagination button {\n\tpadding: 0.2rem 0.5rem 0.2rem 0.5rem;\n\tborder: 0.1rem solid #707070;\n\tcursor: pointer;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablePagination": `TablePaginator_tablePagination__sNh6-`
};
export default ___CSS_LOADER_EXPORT___;
