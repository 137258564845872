import React, {useState} from 'react';
import { toast } from 'react-toastify';

import CreateTagWizard from 'components/tags/table-page/CreateTagWizard'
import EditTagModal from 'components/tags/table-page/EditTagModal'
import Tag from 'components/tags/Tag';

import Modal from 'components/ui-core/modal/Modal/Modal';

import TableEditButton from 'components/ui-core/buttons/TableEditButton/TableEditButton';
import TableDeleteButton from 'components/ui-core/buttons/TableDeleteButton/TableDeleteButton';

import { useASTData } from 'context/ASTContext';
import { useTags, useDeleteTag} from 'data/queryHooks';

import 'animate.css';
import style from 'App.module.css';

import SideBarOption from 'model/SideBarOption';
import ConfirmationRequest from 'model/ConfirmationRequest';

import TextInputColumnFilter from 'components/ui-core/table/filter/TextInputColumnFilter';
import MsdQueryTable from 'components/ui-core/table/msdTable/MsdQueryTable'
import CreateButton from "components/ui-core/buttons/CreateButton/CreateButton";
import MsdMainPage from 'components/ui-core/layout/MsdMainPage/MsdMainPage';
import LoadingWrapper from 'components/ui-core/page/LoadingWrapper';

import useDataService from 'data/useDataService';
import {isManagedTag} from 'components/tags/TagHelper';
import {getManagedTagNames, getTagByName} from 'model/ManagedTag';
import DownloadIcon from 'img/download-icon.svg';
import UploadIcon from 'img/upload-icon.svg';

const TagsTablePage = () => {
	const [createVisible, setCreateVisible] = useState(false);
	const [editTag, setEditTag] = useState(null);	
	const {setConfirmationRequest} = useASTData();

	const {setSideBarOption, setHeaderTitle} = useASTData();
	React.useEffect(() => {
		setSideBarOption(SideBarOption.Tag);
		setHeaderTitle("Tags");
		localStorage.setItem('lastPage',"/tags");
	}, []);

	const tagsQuery = useTags();

	const {exportTagToS3} = useDataService();
	const performHandleExportToS3 = async (tagName) => {
		await exportTagToS3(tagName)
			.catch((error) => {
				toast.error(`Error exporting '${tagName}' to S3`);
				return Promise.reject(error)
			})
	}

	const requestExportConfirmation = (tagRow) => {
		console.log("Export pressed");

		const message = 
			<div>
				Are you sure you want to export the contents of tag {tagRow.name} to S3?
				<br/>
				<br/>
				This may take several minutes.
			</div>

		const confirmRequest = new ConfirmationRequest(
			tagRow.name,
			performHandleExportToS3,
			`Export '${tagRow.name}' to S3`, 
			"Export",
			message,
			null,
			`Exported tag '${tagRow.name}' to S3`,
		);
		
		setConfirmationRequest(confirmRequest);
	}

	const {downloadCosmosCmd, downloadCosmosTlm, downloadCosmosPocmos} = useDataService();
	const handleCmdDownload = (tagName) => {
		downloadCosmosCmd(tagName);
	}

	const handleTlmDownload = async (tagName) => {
		await downloadCosmosTlm(tagName);
	}

	const handlePocmosDownload = (tagName) => {
		downloadCosmosPocmos(tagName);
	}



	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				Filter: TextInputColumnFilter,
				Cell: (tableInstance) => {
					const tagRow = tableInstance.row.original;
					return (
						<Tag key={tagRow.name} title={tagRow.name} />
					);
				},
		
			},
			{
				Header: 'Description',
				accessor: 'description',
			},
			{
				id: 'tags',
				Header: ' ',
				Cell: (tableInstance) => {
					const tagRow = tableInstance.row.original;
					return (
						<div className={style.tableCellContainer}>
							
							{!isManagedTag(tagRow.name) &&
								<>
									<TableEditButton editHandler={() => setEditTag(tagRow)}/>
									<TableDeleteButton deletionHandler={() => requestDelConfirmationHandler(tagRow)}/>
								</>							
							}
							{tagRow.name !== "DELETED" && tagRow.name !== "PENDING" && 
							<>
							<button  style={{width:"175px"}} className={style.tableTextButton}  onClick={() => requestExportConfirmation(tagRow)}>
								<img src={UploadIcon} alt="UploadToS3" className={style.tableButtonIcon} />
								<span >Export to S3 & Run Ingester</span>								
							</button>
							
							<button style={{width:"175px"}} className={style.tableTextButton}  onClick={() => handleCmdDownload(tagRow.name)}>
								<img src={DownloadIcon} alt="Download CMD" className={style.tableButtonIcon} />
								<span >Latest COSMOS 'cmd.txt'</span>								
							</button>
							
							<button  style={{width:"175px"}} className={style.tableTextButton} onClick={() => handleTlmDownload(tagRow.name)}>
								<img src={DownloadIcon} alt="Download TLM" className={style.tableButtonIcon} />
								<span >Latest COSMOS 'tlm.txt'</span>					
							</button>

							<button  style={{width:"175px"}} className={style.tableTextButton} onClick={() => handlePocmosDownload(tagRow.name)}>
								<img src={DownloadIcon} alt="Download POCMOS" className={style.tableButtonIcon} />
								<span >Latest COSMOS POCMOS 'cmd.txt'</span>					
							</button>
							</>
							}

						</div>
					);
				},				
				columnType: "buttons"
			},
		],
		[]
	);

	const userColumns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				Filter: TextInputColumnFilter,
				Cell: (tableInstance) => {
					const tagRow = tableInstance.row.original;
					return (
						<Tag key={tagRow.name} title={tagRow.name} />
					);
				},
		
			},
			{
				Header: 'Description',
				accessor: 'description',
			},
			{
				id: 'tags',
				Header: ' ',
				Cell: (tableInstance) => {
					const tagRow = tableInstance.row.original;
					return (
						<div className={style.tableCellContainer}>
							
							{!isManagedTag(tagRow.name) &&
								<>
									<TableEditButton editHandler={() => setEditTag(tagRow)}/>
									<TableDeleteButton deletionHandler={() => requestDelConfirmationHandler(tagRow)}/>
								</>							
							}

							<>
							<button  style={{width:"123px", overflowWrap: "anywhere"}} className={style.tableTextButton}  onClick={() => requestExportConfirmation(tagRow)}>
								<img src={UploadIcon} alt="UploadToS3" className={style.tableButtonIcon} />
								<span >Export to S3 & Run Ingester</span>								
							</button>
							
							<button style={{width:"123px", overflowWrap: "anywhere"}} className={style.tableTextButton}  onClick={() => handleCmdDownload(tagRow.name)}>
								<img src={DownloadIcon} alt="Download CMD" className={style.tableButtonIcon} />
								<span >Latest COSMOS 'cmd.txt'</span>								
							</button>
							
							<button  style={{width:"123px", overflowWrap: "anywhere"}} className={style.tableTextButton} onClick={() => handleTlmDownload(tagRow.name)}>
								<img src={DownloadIcon} alt="Download TLM" className={style.tableButtonIcon} />
								<span >Latest COSMOS 'tlm.txt'</span>					
							</button>
							</>

						</div>
					);
				},				
				columnType: "buttons"
			},
		],
		[]
	);


	const  {mutateAsync} = useDeleteTag();
	const requestDelConfirmationHandler = (tagToDelete) => {
		const tagName = tagToDelete.name;

		if (isManagedTag(tagName)) {
			toast.error(`'${tagName}' is a system-managed tag, it cannot be deleted`);
			return
		}

		let deleteRequest =  new ConfirmationRequest(
			tagToDelete._id,
			mutateAsync,
			`Delete '${tagName}'`, 
			"Delete",
			"This tag will be removed from all assigned command and telemetry packets. Are you sure you would like continue?",
			null,
			`Tag '${tagName}' deleted`,
		);
	
		setConfirmationRequest(deleteRequest);
	}

	const getSystemTags = (query) => {
		const result = query.data
			.filter(envTag => getManagedTagNames().includes(envTag.name))
			.sort((row_a, row_b) =>  getTagByName(row_a.name).workflowOrder - getTagByName(row_b.name).workflowOrder)
	
		return result;
	}

	const getUserTags = (query) => {
		return query.data.filter(envTag => !getManagedTagNames().includes(envTag.name))
	}

	return (
		<MsdMainPage>
			{createVisible && 
				<LoadingWrapper 
					queries={[tagsQuery]}
					onLoad={() => (
						<Modal>
							<CreateTagWizard 
								setCreateVisible={setCreateVisible}
								allTags={tagsQuery.data}
							/>
						</Modal> 
					)}				
				/>
			}

			{editTag && 
				<LoadingWrapper 
					queries={[tagsQuery]}
					onLoad={() => (
						<Modal>
							<EditTagModal 
								setEditTag={setEditTag}
								editTag={editTag}
								allTags={tagsQuery.data}
							/>
						</Modal> 
					)}				
				/>
			}
			<div style={{overflow: "scroll", height:"100%"}}>
			<div style={{height:"flex"}}>
				<h2>System Tags</h2>
				<MsdQueryTable tableName="TagsTablePage-System" 
					columns={columns} query={tagsQuery} dataExtractFn={getSystemTags} addPagination={false}/>	
			</div>
			<div style={{height:"flex"}}>
				<h2 style={{marginTop: "4rem"}}>User Tags</h2>
				<MsdQueryTable tableName="TagsTablePage-User"
					columns={userColumns} query={tagsQuery} dataExtractFn={getUserTags}
				>
					<CreateButton
						width="5rem"
						title="Tag"
						onClick={() => {
							setCreateVisible(true);
						}}
					/>		
				</MsdQueryTable>
				</div>
			</div>
		</MsdMainPage>
	);
};

export default TagsTablePage;
