// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToolBarCopyButton_ToolBarCopyButton__M2uTF {
	border-radius: 0.3rem;
	border: none;
	color: #ffffff;
	padding: 0.5rem;
	font-weight: 500;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	cursor: pointer;
	width: 8.5rem;
	height: 2rem;
	justify-content: space-around;
}

.ToolBarCopyButton_ToolBarCopyButton__M2uTF:hover {
	opacity: 0.8;
}

.ToolBarCopyButton_ToolBarCopyButton__M2uTF svg {
	width: 1rem;
	height: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-core/toolbar/buttons/ToolBarCopyModeButton/ToolBarCopyButton.module.css"],"names":[],"mappings":"AAEA;CACC,qBAAqB;CACrB,YAAY;CACZ,cAAc;CACd,eAAe;CACf,gBAAgB;CAChB,aAAa;CACb,qBAAqB;CACrB,mBAAmB;CACnB,eAAe;CACf,aAAa;CACb,YAAY;CACZ,6BAA6B;AAC9B;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,WAAW;CACX,YAAY;AACb","sourcesContent":["@import 'App.module.css';\n\n.ToolBarCopyButton {\n\tborder-radius: 0.3rem;\n\tborder: none;\n\tcolor: #ffffff;\n\tpadding: 0.5rem;\n\tfont-weight: 500;\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n\talign-items: center;\n\tcursor: pointer;\n\twidth: 8.5rem;\n\theight: 2rem;\n\tjustify-content: space-around;\n}\n\n.ToolBarCopyButton:hover {\n\topacity: 0.8;\n}\n\n.ToolBarCopyButton svg {\n\twidth: 1rem;\n\theight: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToolBarCopyButton": `ToolBarCopyButton_ToolBarCopyButton__M2uTF`
};
export default ___CSS_LOADER_EXPORT___;
