import React from 'react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';


import {getUnitSchema} from 'model/form-schema/ObjectSchemas'
import UnitFormContent from './UnitFormContent'
import {useSaveMetadata} from 'data/queryHooks';

export const CreateUnitWizard = ({ setCreateVisible,  metadata }) => {
	const metadataClone = cloneDeep(metadata)

	const schema = getUnitSchema(metadata.units, null, null);
	const {useFormObj, controlProps} = useWizardForm({}, schema);
	const  {mutateAsync: updateMetadata} = useSaveMetadata();

	const createSubmitPromise = async (formData) => {
		
		let newUnit = {
			...formData
		}

		metadataClone.units.push(newUnit);

		return await updateMetadata(metadataClone).then((response) => {
			toast.success(`Unit '${newUnit.units_long}' created`);

			return response;
		})			
	};


	return (
		<Wizard width='40rem'>
			<WizardForm title="Create Unit" 
				setWizardVisible={setCreateVisible} useFormObj={useFormObj} 
				createSubmitPromise={createSubmitPromise}
			>
				<UnitFormContent controlProps={controlProps} />
			</WizardForm>
		</Wizard>		
	);
};


export default CreateUnitWizard;
