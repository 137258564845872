import React, {useState} from 'react';

import 'animate.css';

import {getDiscreteOptionsSchema} from 'model/form-schema/ObjectSchemas'
import Modal from 'components/ui-core/modal/Modal/Modal';

import { useASTData } from 'context/ASTContext';
import ConfirmationRequest from 'model/ConfirmationRequest';

import { transformOption } from './OptionHelper';
import CreateLimitOptionWizard from './CreateLimitOptionWizard';
import LimitOptionsTable from './LimitOptionsTable';
import { EnumsEditForm } from './EnumsEditForm';

export const DiscreteOptionsTab = ({updateEntityPromise, underlyingEntity, entityType}) => {

	const [createVisible, setCreateVisible] = useState(false);
	const [editOptionValue, setEditOptionValue] = useState(null);	

	const {setConfirmationRequest} = useASTData();
	const optionsAttrName = entityType.optionsAttrName

	const performEnumDeletionHandler = async (rowToDelete) => {
		underlyingEntity['enums'] = 
			underlyingEntity['enums']
				.filter(ele => ele['sc_mode'] != rowToDelete['sc_mode']);
		await updateEntityPromise(underlyingEntity);
	};
	
	const requestDelConfirmationHandler = (optionToDelete) => {
		let deleteRequest =  new ConfirmationRequest(
			optionToDelete,
			performEnumDeletionHandler,
			`Delete Enum '${optionToDelete.sc_mode}'`, 
			"Delete",
			"Are you sure you want to delete this Enum?",
			null,
			`Enum '${optionToDelete.sc_mode}' deleted`, 
		);
	
		setConfirmationRequest(deleteRequest);
	}

	const [optionsData, setOptionsData] = useState([]);
	
	React.useEffect(() => {
		console.log(underlyingEntity["enums"], optionsData);
		setOptionsData(underlyingEntity["enums"]);
	}, [underlyingEntity["enums"]]);


	const createAddEnumOptionPromise = async (formData) => {
		const adjustedFormData = transformOption(formData);
		let discrete = [];
		if(optionsData.length > 0){
			discrete = optionsData[0]['discrete_values'];
		}
		const newEnumRow = {
			  sc_mode: adjustedFormData.sc_mode,
			  discrete_values: discrete
		  };		
		underlyingEntity['enums'].push(newEnumRow);
		
		return updateEntityPromise(underlyingEntity);
	};

	const createEditEnumsOptionPromise = async (formData) => {
		const adjustedFormData = transformOption(formData);
		console.log(adjustedFormData);
		let editDiscrete = 
			underlyingEntity['enums']
			.find(ele => ele.sc_mode == editOptionValue.sc_mode);
		editDiscrete['discrete_values'] = adjustedFormData.discrete_values;
		console.log(underlyingEntity);
		setOptionsData(underlyingEntity['enums'])
		return updateEntityPromise(underlyingEntity);
	};

	const createOptionSchema = () => getDiscreteOptionsSchema();

	return (
		<>
			{createVisible && 
				<Modal>
					<CreateLimitOptionWizard
						createAddOptionPromise={createAddEnumOptionPromise}
						schema={createOptionSchema()}
						setCreateVisible={setCreateVisible}
						entityType={entityType}
						optionsData={optionsData}
                        optionsType={"Discrete"}
					/>
				</Modal> 
			}

			{editOptionValue &&
				<EnumsEditForm
					createEditEnumsOptionPromise={createEditEnumsOptionPromise}
					editEnumValue={editOptionValue}
					setEditEnumValue={setEditOptionValue}
					schema={createOptionSchema()}
					totalOptionsData={optionsData}
				/>
			}
					

			<LimitOptionsTable 
				optionsData={optionsData}
				setCreateVisible={setCreateVisible}
				setEditOptionValue={setEditOptionValue}
				underlyingEntity={underlyingEntity}
				handleDelete={requestDelConfirmationHandler}
                optionsType={"Discrete"}
			/>			
		</>

	);
};

export default DiscreteOptionsTab;
