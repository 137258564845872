
class EntityType {
    // Create new instances of the same class as static attributes
    static CmdPacket = new EntityType("CmdPacket", "Command Packet", "cmd_name", "cmd_fields")
    static CmdParam = new EntityType("CmdParam", "Command Parameter", "cmd_param_name", null, "param_options_list")
    static TlmPacket = new EntityType("TlmPacket", "Telemetry Packet", "tlm_packet_name", "fields")
    static TlmPoint = new EntityType("TlmPoint", "Telemetry Point", "tlm_point_name", null, "list")
    static BitwisePoint = new EntityType("BitwisePoint", "Bitwise Point", "tlm_bitwise_name", null, "representation_list")
  
    constructor(code, description, nameAttr, childAssocListName, optionsAttrName) {
      this.code = code;
      this.description = description;
      this.nameAttr = nameAttr;
      this.childAssocListName = childAssocListName
      this.optionsAttrName = optionsAttrName
    }
}

EntityType.CmdPacket.child_entity_type = EntityType.CmdParam
EntityType.TlmPacket.child_entity_type = EntityType.TlmPoint

export const ALL_TYPES = [EntityType.CmdPacket, EntityType.CmdParam, EntityType.TlmPacket, EntityType.TlmPoint];
export const PACKETS_ONLY = [EntityType.CmdPacket, EntityType.TlmPacket];

export const CMD_RELATED_TYPES = [EntityType.CmdPacket, EntityType.CmdParam]
export const TLM_RELATED_TYPES = [EntityType.TlmPacket, EntityType.TlmPoint, EntityType.BitwisePoint]

export const getEntityTypeByCode = (code) => {
  return ALL_TYPES.find(entityType => entityType.code === code);
}

export default EntityType;