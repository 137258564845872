import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import WizardForm from 'components/ui-core/form/WizardForm';
import Wizard from 'components/ui-core/modal/Wizard';
import useWizardForm from 'components/ui-core/form/useWizardForm';

import {getTlmPacketSchema, getToastMessage} from 'model/form-schema/ObjectSchemas'
import TelemetryPacketFormContent from 'components/tlm-packet/packet-page/TelemetryPacketFormContent'
import {useCreateTelemetryPacket} from 'data/queryHooks';
import { useState , useEffect} from 'react';


export const CreateTelemetryPacketWizard = ({ setCreateVisible, allTlmPackets, allCmdPacketsQuery, metadata, copyPacket }) => {
	if(copyPacket?.hasOwnProperty("_id")) 	copyPacket["_id"] = null;
	const defaultSchema = getTlmPacketSchema(allTlmPackets, copyPacket? copyPacket : null, false);
	const [schema, setSchema] = useState(defaultSchema);
	const [toastMes, setToastMes] = useState("");
	const {useFormObj, controlProps} = useWizardForm(copyPacket !== null ? {
		...cloneDeep(copyPacket),
		source_nodes: [],
		source_group: [],
	} : {environment_tags: []}, 
	schema);
	const {watch, getValues} = useFormObj;
	useEffect(() => {
		const schem = getTlmPacketSchema(allTlmPackets, getValues(),  true);
		setSchema(schem);
		schem.isValid(useFormObj.getValues())
    	.then(valid => {
			if(!valid){
				const toa = getToastMessage(allTlmPackets, getValues(), "tlm_node", true);
				setToastMes(toa);
			}
		});
	}, [getValues()['src_nodes'], getValues()['subsystem_name'], getValues()['csp_port'], getValues()['tlm_id']]);

	useEffect(() => {
		if(toastMes || toastMes != ""){
			toast.warning(toastMes);
			setToastMes("");
		}
	}, [toastMes]);
	const  {mutateAsync: addTelemetryPacket} = useCreateTelemetryPacket();

	const createSubmitPromise = async (formData) => {
		
		let newTlmPacket = {
			tlm_packet_name: formData.tlm_packet_name,
			subsystem_name: formData.subsystem_name,
			subsystem_port: metadata.subsystems.find(sub => sub.subsystem === formData.subsystem_name).subsystem_port,
			csp_port: formData.csp_port,
			description: formData.description,
			tlm_id: formData.tlm_id,
			file_id: formData.file_id,
			update_frequency: formData.update_frequency,
			non_telem: formData.non_telem,
			allow_short: formData.allow_short,
			response: formData.response,
			time_created: dayjs(new Date()).toISOString(),
			fields: formData.fields? formData.fields : [] ,
			source_group: formData.src_nodes
				? formData.src_nodes.map( (nodeName) => {
					return {
						node: nodeName,
						node_id: metadata.nodes
							.find(metaNodeEntry => metaNodeEntry.node === nodeName)?.node_id
					}})
				: [],
			use_amp: false,
			satellite: ["BW3"],
			environment_tags: [],
			depreciated: {
				depreciated_status: false,
				notes: null
			},
		}


		return await addTelemetryPacket(newTlmPacket)
			.then(response => {
				toast.success(`Telemetry Packet '${newTlmPacket.tlm_packet_name}' created`);
				return response;
			})
			.catch(error => {
				toast.error(`Telemetry Packet creation failed: ${error.message}`);
				return Promise.reject(error)
			});
	};

	return (
		<Wizard width='90rem'>
			<WizardForm title="Create Telemetry Packet" getObjectbyIdRoutePrefix='/telemetry-packet'
				setWizardVisible={setCreateVisible} useFormObj={useFormObj} copyPacketType={copyPacket? (copyPacket.cmd_name? 'cmd_type': 'tlm_type'):  null} 
				createSubmitPromise={createSubmitPromise}
			>
				<TelemetryPacketFormContent 
					controlProps={controlProps} 
					metadata={metadata}
					allCmdPacketsQuery={allCmdPacketsQuery}
					useFormObj={useFormObj} 
					isWizard={true}
				/>
			</WizardForm>
		</Wizard>
	);
};
export default CreateTelemetryPacketWizard;
